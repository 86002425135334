import React, {useEffect} from 'react';
import './App.scss';
import {Redirect, Route, Switch, useLocation, useHistory} from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {StylesProvider} from '@material-ui/core/styles';
import OfferList from "components/offers/OfferList/OfferList.js";
import FullApplication from "components/fullApplication/FullApplicationForm";
import Submitted from "components/submitted/Submitted.js";
import MeritInformation from "components/meritInformation/MeritInformation";
import MeritDetails from "components/meritDetails/MeritDetails";
import Coapplicant from "components/coapplicant/Coapplicant.js";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Disclosures from "components/disclosures/Disclosures";
import Refer from "components/refer/Refer";
import PrequalificationForm from "components/prequalification/PrequalificationForm";
import ExistingApp from "components/ExistingApp/ExistingApp";
import ErrorPage from "components/errorPage/ErrorPage";
import ServerRequests from "service/ServerRequests";
import {logSentryError} from "service/errors";
import MaintenancePage from "components/maintenancePage/MaintenancePage";
import Environment from "util/Environment";
import Footer from "components/Footer/Footer";
import ErrorPageAddressOutsideUs from 'components/errorPageAddressOutsideUs/ErrorPageAddressOutsideUs';
import SendTranscript from "components/SendTranscript/sendTranscript";
import DynamicErrorPage from "components/errorPage/DynamicErrorPage";
import Declined from "components/declined/Declined";
import {GlobalContext} from "modules/GlobalContext";

export const theme = createMuiTheme({
  props: {
    MuiSwitch: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
      size: 'small',
    },
    MuiFormLabel: {
      fontFamily: 'Work Sans',
    }

  }, palette: {
    primary: {
      main: '#3c89e8', // ocean-blue
    },
    secondary: {
      main: '#FFFFFF',
    },
  }
});

// Begin Inspectlet Asynchronous Code
(function () {
  window.__insp = window.__insp || [];
  window.__insp.push(['wid', 22603232]);
  var ldinsp = function () {
    if (typeof window.__inspld != "undefined") return;
    window.__inspld = 1;
    var insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = "inspsync";
    insp.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=22603232&r=' + Math.floor(new Date().getTime() / 3600000);
    var x = document.getElementsByTagName('script')[0];
    if (!!x) {
      x.parentNode.insertBefore(insp, x);
    }
  };
  setTimeout(ldinsp, 0);
})();

// End Inspectlet Asynchronous Code

function App() {
  const location = useLocation();
  const history = useHistory()
  const isProd = process.env.NODE_ENV === "production"

  useEffect(() => {
    return history.listen((location) => {
      window.scrollTo(window.scrollX, 0) // move scrollbar back to the top
    })
  }, [history])

  useEffect(() => {
    function getParameterByName(name) {
      name = name.replace(/[[]/, "[").replace(/[\]]/, "]");
      let regex = new RegExp("[?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    let data = {
      utmSource: getParameterByName('utm_source'),
      utmMedium: getParameterByName('utm_medium'),
      utmCampaign: getParameterByName('utm_campaign'),
      utmContent: getParameterByName('utm_content'),
      utmTerm: getParameterByName('utm_term'),
    };

    if (data.utmSource || data.utmMedium || data.utmCampaign || data.utmContent || data.utmContent) {
      ServerRequests.submitTrackingData({data})
        .catch(error => {
          logSentryError(error);
        });
    }
  }, [location])

  const globalState = {
    forceMobileOnJest: false,
  }

  const getAppClassNamesForCurrentRoute = () => {
    let baseCls = "App";

    console.error("pathname")
    console.warn(location.pathname)
    if (location.pathname === '/sendTranscript')
      baseCls += ' light-blue-bg'

    return baseCls
  }

  return (
    <GlobalContext.Provider value={globalState}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={theme}>
            {
              Environment.redirectToMaintenance() ?
                <MaintenancePage/> :
                <div className={getAppClassNamesForCurrentRoute()}>
                  <Switch className="app-content">
                    <Route exact path="/"
                           render={(props) => <PrequalificationForm theme={theme} {...props} currentPage=""/>}/>


                    <Route path="/sendTranscript" render={(props) => <SendTranscript theme={theme} {...props}/>}/>

                    {location.state &&
                      <>
                        <Route path="/offers" render={(props) => <OfferList theme={theme} {...props}/>}/>
                        <Route path="/full-app" render={(props) => <FullApplication theme={theme} {...props}/>}/>
                        <Route path="/disclosures" render={(props) => <Disclosures theme={theme} {...props}/>}/>
                        <Route path="/submitted" render={(props) => <Submitted theme={theme} {...props}/>}/>
                        <Route path="/merit" render={(props) => <MeritInformation theme={theme} {...props}/>}/>
                        <Route path="/merit-details" render={(props) => <MeritDetails theme={theme} {...props}/>}/>
                        <Route path="/coapplicant" render={(props) => <Coapplicant theme={theme} {...props}/>}/>
                        <Route path="/refer" render={(props) => <Refer theme={theme} {...props}/>}/>
                        <Route path="/existing-app" render={(props) => <ExistingApp theme={theme} {...props} />} />
                        <Route path="/errorPage" render={(props) => <ErrorPage theme={theme} {...props}/>}/>
                        <Route path="/declined" render={(props) => <Declined theme={theme} {...props}/>}/>
                        <Route path="/dynamicErrorPage"
                               render={(props) => <DynamicErrorPage theme={theme} {...props}/>}/>
                        <Route path="/errorPageAddressOutsideUs"
                               render={(props) => <ErrorPageAddressOutsideUs theme={theme} {...props}/>}/>
                      </>
                    }
                    {
                      isProd || <Route exact path="/fake-forms/"
                        render={(props) => <PrequalificationForm theme={theme} {...props} currentPage="" useFakeForm={true} />} />
                    }



                    <Route render={() => <Redirect to="/"/>}/>
                  </Switch>
                  <Footer className="app-footer"/>
                </div>
            }
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </GlobalContext.Provider>
  );
}

export default App;

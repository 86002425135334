import React from "react";
import "components/errorMessage/ErrorMessage.scss";
import ReportIcon from '@material-ui/icons/Report';

const ErrorMessage = (props) => {
  const {
    type,
    messages
  } = props;

  return (
    <div className={`error-message error-message--${type}`} data-testid="error-msg-container">
      {type === 'server' ?
        <div>
          <ReportIcon style={{width: "0.75rem", height: "0.75rem", verticalAlign: "-10%"}}/>
          &nbsp;Internal error processing your request. Try again later.
        </div>
        :
        messages.map((obj, index) => {
          return <div data-testid="error-msg" key={index}>{obj}</div>
        })
      }
    </div>
  );
};

export default ErrorMessage;

import React, {useState} from 'react';
import {array} from "prop-types";
import Offer from "../Offer/Offer";
import CplOffer from "../Offer/CplOffer";
import Button from "@material-ui/core/Button";
import ServerRequests from "../../../service/ServerRequests";
import './OfferList.scss'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import {logSentryError} from "service/errors";
import PageTipIcon from '../../../assets/images/pageTipIcon.png';
import withHeader from "components/header/WithHeader";
import AddCoApplicant from '../../../assets/images/addCoApplicant.svg';
import FixedOffersWindow from '../../../assets/images/fixed-offers-window2.svg';
import VariableOffersWindow from '../../../assets/images/variable-offers-window2.svg';
import Icon from "@material-ui/core/Icon";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import Infographic from "components/header/infographic/Infographic";

const addCoapplicantIcon = (
  <Icon>
    <img style={{display: 'block'}} alt="addCoapplicantIcon" src={AddCoApplicant}/>
  </Icon>
);

function OfferList(props) {

  let offers = props.location.state.offers;
  let applicationId = props.location.state.applicationId;
  let applicationCreatedDate = props.location.state.applicationCreatedDate;
  let has18MonthNoInterest = props.location.state.has18MonthNoInterest;
  let hasMultipleDisbursements = props.location.state.hasMultipleDisbursements;
  let hasCoapplicant = props.location.state.hasCoapplicant;
  let isCpl = props.location.state.isCpl;
  let numVariableOffers = (!offers) ? 0 : offers.filter(offer => offer['interest_rate_type'] === 'variable').length;
  let numFixedOffers = (!offers) ? 0 : offers.filter(offer => offer['interest_rate_type'] === 'fixed').length;
  let tabs = [
    <Tab key={"variable"}
         className={numFixedOffers > 0 ? "offers__tab-label left-tab" : "offers__tab-label center-tab"}
         name="VARIABLE_TAB"
         label="VARIABLE"
         value="variable"
         data-testid="variable-rate-tab"/>,

    <Tab key={"fixed"}
         className={numVariableOffers > 0 ? "offers__tab-label right-tab" : "offers__tab-label center-tab"}
         name="FIXED_TAB"
         label="FIXED"
         value="fixed"
         data-testid="fixed-rate-tab"/>
  ];

  const [formInputs, handleFormInputs] = useState({
    selectedOfferRepaymentOption: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, handleError] = useState(null);

  function navigateToFullApplication(loanInfo, applicationId) {
    props.history.push('/full-app', {loanInfo, applicationId});
  }

  const radioButtonChangeHandler = () => {
    return (value) => handleFormInputs({
      ...formInputs,
      selectedOfferRepaymentOption: value,
    })
  };

  function submitForm() {
    setLoading(true);
    ServerRequests.submit('/submit-selected-offer',
      {
        data: {
          applicationId: applicationId,
          offerNumber: (!formInputs.selectedOfferRepaymentOption) ? null : formInputs.selectedOfferRepaymentOption.split('-')[1],
          repaymentOption: (!formInputs.selectedOfferRepaymentOption) ? null : formInputs.selectedOfferRepaymentOption.split('-')[0],
        }
      }
    )
      .then((response) => {
        navigateToFullApplication(response.data, applicationId);
      })
      .catch(error => {
        setLoading(false);
        logSentryError(error);
        handleError({type: "server"});
      })
  }

  function handleAddCoapplicant() {
    props.history.push('/coapplicant', {applicationId: applicationId, conditionalCoapplicant: false, hasOffers: true});
  }

  const [selectedTab, setSelectedTab] = React.useState(numVariableOffers === 0 ? 'fixed' : 'variable');

  const handleChange = (event, newTab) => {
    let variableMessage = document.getElementsByName("VARIABLE_TAB")
    let fixedMessage = document.getElementsByName("FIXED_TAB")

    handleFormInputs({
      ...formInputs,
      selectedOfferRepaymentOption: null,
    });

    setSelectedTab(newTab);

    if (selectedTab === "variable") {
      variableMessage.forEach(element => element.classList.replace("hidden", "visible"))
      fixedMessage.forEach(element => element.classList.replace("visible", "hidden"))
    } else if (selectedTab === "fixed") {
      fixedMessage.forEach(element => element.classList.replace("hidden", "visible"))
      variableMessage.forEach(element => element.classList.replace("visible", "hidden"))
    }
  }

  if (typeof offers == "undefined" || offers == null || offers.length == null || offers.length === 0 ) {
    logSentryError(new Error("Offer set for application " + applicationId + " is empty or null"))
    props.history.push('/errorPage', {applicationId: applicationId});
    return null
  }
  else {
    return (
      <>
        {loading && <LoadingIndicator/>}

        <Infographic stage="3" title="Offers"/>

        <div>
          <Grid container className="offers__loan-amount-ofee-container" data-cy="offer-toplevel-info">
            <Grid item xs={6}>
              <div className="offers__loan-amount-label offers__label">Loan Amount</div>
            </Grid>
            <Grid item xs={6}>
              <div className="offers__loan-amount-label offers__value">${offers && offers[0].loan_amount}</div>
            </Grid>
            <Grid item xs={6}>
              <div className="offers__ofee-label offers__label">Origination fee</div>
            </Grid>
            <Grid item xs={6}>
              <div className="offers__ofee-label offers__value">${offers && offers[0].origination_fee}</div>
            </Grid>
          </Grid>
          <div className="offers__tabs-grid-container">
            <div className="offers__tabs-title-outter-div">
              <div className="offers__tabs-title-inner-div">
                <div className="offers__tabs-title">
                  Select your offer
                </div>
              </div>
            </div>

            <Paper className="offers__tabs-paper-container">
              {
                selectedTab === "variable" ?
                  <img src={VariableOffersWindow}
                       alt={"A variable interest rate may fluctuate over the life of loan, dependent on market conditions."}
                       className="offers__tabs-image"/> :
                  <img src={FixedOffersWindow}
                       alt={"A fixed rate loan will have the same interest rate for the life of the loan."}
                       className="offers__tabs-image"/>
              }
              <div className="offers__tabs-figure-container">
                <div className="offers__tabs-figure">
                  <Grid container className="error__contact-info">
                    {
                      numVariableOffers > 0 &&
                      <Grid item xs={6}>
                        {
                          selectedTab === "variable" &&
                          <div className="offers__tabs-figure-image"></div>
                        }
                      </Grid>
                    }
                    <Grid item xs={numVariableOffers > 0 ? 6 : 12}>
                      {
                        selectedTab === "fixed" &&
                        <div
                          className={numVariableOffers > 0 ? "offers__tabs-figure-image" : "offers__tabs-figure-image still-image"}/>
                      }
                    </Grid>
                  </Grid>
                </div>
                <Tabs
                  className="offers__tabs-container"
                  value={selectedTab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  {
                    numVariableOffers === 0 ? tabs[1] : tabs
                  }
                </Tabs>
              </div>
            </Paper>
          </div>
          <div className='offers__container'>
            <div className="offers__repayment-option-definition__container">
              <div className="offers__repayment-option-definition">
                <div>
                  <img className="offers__repayment-option-definition-img" src={PageTipIcon} alt=""/>
                </div>
                <div className="offers__repayment-option-definition-text">Repayment Term is the period when regularly
                  scheduled Principal & Interest Payments will be due.
                </div>
              </div>
            </div>
            {
              offers && !isCpl &&
              offers.filter(offer => offer['interest_rate_type'] === selectedTab && (offer['has_full'] || offer['has_deferment'] || offer['has_interest_only'])).map((offer, index) => {
                return <Offer key={index} offerInfo={offer}
                              onNewValueSelected={radioButtonChangeHandler('selectedRepaymentOption')}
                              value={formInputs.selectedOfferRepaymentOption}
                              has18MonthNoInterest={has18MonthNoInterest}
                              hasMultipleDisbursements={hasMultipleDisbursements}
                />
              })
            }
            {
              offers && !!isCpl &&
              offers.filter(offer => offer['interest_rate_type'] === selectedTab && offer['has_cpl']).map((offer, index) => {
                return <CplOffer key={index} offerInfo={offer}
                                 onNewValueSelected={radioButtonChangeHandler('selectedRepaymentOption')}
                                 value={formInputs.selectedOfferRepaymentOption}
                                 has18MonthNoInterest={has18MonthNoInterest}
                                 hasMultipleDisbursements={hasMultipleDisbursements}
                />
              })
            }
          </div>
          {(error) &&
            <ErrorMessage type={error.type} messages={error.messages}/>
          }
          <div className="offers__no-changes-warning" data-testid="no-changes-warning">
            Once you select an offer, you cannot return to this page.<br/>
            If you wish to make changes to your offer selection,<br/>
            please contact Customer Support
          </div>
          <div className='offers__apply-button-container'>
            <Button
              className='offers__apply-button'
              color="primary"
              variant="contained"
              onClick={submitForm}
              data-testid={"continue-button"}
              disabled={!formInputs.selectedOfferRepaymentOption || loading}
            >
              APPLY
            </Button>
          </div>

          {!hasCoapplicant &&
            <div className='offers__add-coapplicant-container'>
              <Button
                className='offers__add-coapplicant-button'
                variant="contained"
                data-testid={"add-coapplicant-button"}
                onClick={handleAddCoapplicant}
                size="large"
                startIcon={addCoapplicantIcon}
              >
                ADD CO-APPLICANT
              </Button>
              <div className="offers__add-coapplicant-footer">
                Adding a co-applicant is optional; however, in certain circumstances, adding a co-applicant can
                potentially
                improve your loan options.
              </div>
            </div>
          }

          <div className="offers__change-information-footer-container">
            {!!isCpl &&
              <div className="offers__change-information-footer">
                †The in-school period of flight training (“In-school flight training”) covers approximately twelve (12)
                to
                eighteen (18) months, if taken concurrently. The flight time-building period ("Time-Building period")
                following the In-school flight training, during which a student is securing the required flight hours
                needed to gain the Airline Transport Pilot ("ATP") rating, typically spans approximately twelve (12) to
                eighteen (18) months, if taken concurrently. You will be responsible for three (3) additional minimum
                payments following completion of the Time-Building period. The In-school flight training period, the
                Time-Building period and number of minimum payments may vary depending on a number of factors,
                including,
                but not limited to, the aviation school you attend and how quickly you complete the required flight
                hours.
              </div>
            }
            <div className="offers__change-information-footer">
              Variable rates are subject to change
            </div>
            <div className="offers__change-information-footer">
              Offers generated on {applicationCreatedDate} and are valid for 30 days
            </div>
            <div className="offers__change-information-footer">
              The offer(s) presented to you on the Meritize platform are estimates based in part on program details
              provided by your school. Once your school confirms the details of your enrollment, including, but not
              limited to, the estimated
              start date, length of program, final loan amount, estimated payment amount, payment dates, information
              reflected in your Promissory Note may be subject to change. All offer(s) are conditional and subject to
              verification of the information provided by you and your school
            </div>
          </div>
        </div>
      </>
    );
  }
}

OfferList.propTypes = {
  offers: array,
};

export default logCurrentPage(withHeader(backButtonRedirect(OfferList)), 'offers');

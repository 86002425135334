export const middleNameNoSpecialCharacters = (text) => {
  return !(new RegExp('\\W+').test(text));
}

export const middleNameIsNotSuffix = (text) => {
  let suffixes = ['Jr'];
  let isValidMiddleName = true;
  for(let i = 0; i < suffixes.length; i++)
    isValidMiddleName = isValidMiddleName && text.localeCompare(suffixes[i], undefined, { sensitivity: 'accent' }) !== 0;
  return isValidMiddleName;
}

export function formatPhone(phoneNumberString) {
  // Remove any non-digit characters from the input string
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  // Capture the first three digits, second three digits, and last four digits using regex
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  // If the match is found, format the phone number
  if (match) {
    let formattedNumber =  `${match[1]}`;

    if (match[0].length > 3) {
      formattedNumber = `(${match[1]})`
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  }

  // If the match is not found, return the original string
  return phoneNumberString;
}

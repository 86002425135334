import * as React from "react";
import './TranscriptStandaloneUploader.scss';
import Icon from "@material-ui/core/Icon";
import boxOneSvg from "assets/images/sendTranscript/transcript-box.svg";
import boxTwoSvg from "assets/images/sendTranscript/transcript-box-2.svg";
import TranscriptRules from "assets/images/sendTranscript/transcript-rules.svg";


const TranscriptStandaloneUploaderInstructions = props => {
  return <Icon>
    <img data-testid={"dropAreaIconTestId"} src={
      boxOneSvg
    } alt="Drop transcript here"/>
    <img src={
      boxTwoSvg
    } alt="Drop other files here"/>
    <img alt='Transcript Rules' src={TranscriptRules}/>

  </Icon>
}

export default TranscriptStandaloneUploaderInstructions;

import React from "react";
import Popup from "./Popup";
import UnderageIcon from '../../assets/images/errors/underage.svg';
import IneligibleStateIcon from '../../assets/images/errors/sorry.svg';

const ErrorPopupIneligible = (props) => {
  const {
    ineligibleReason,
    setIneligibleReason,
    setLoading,
  } = props;

  const underage = () => ineligibleReason === "ineligible-age"

  const icon = () => underage()
    ? UnderageIcon
    : IneligibleStateIcon

  const reasonDescription = () => {
    if (underage())
      return "You do not meet the age requirements for your state of residence."

    if (ineligibleReason === 'ineligible-state')
      return "Unfortunately, Meritize platform does not service your state at this time."

    return ""
  }

  const headers = () => [
    "No loan offer available"
  ]

  const descriptions = () => [
    `There are no loan offers available for you at this time. ${reasonDescription()}`,
    "",
    "If you believe you have received this message in error, please contact us at:",
  ]

  const buttonLabel = () => "Reapply Later"


  const handleConfirm = () => {
    setIneligibleReason(null)
    setLoading(false)
  }


  return <>
    {
      ineligibleReason && <Popup
        icon={icon}
        headers={headers}
        descriptions={descriptions}
        buttonLabel={buttonLabel}
        handleConfirm={handleConfirm}
      />
    }
  </>

}

export default ErrorPopupIneligible;

import * as Sentry from "@sentry/browser";

class AppError {
  constructor(message, scope) {
    this.message = message;
    this.scope = scope || 'global';
  }
}

function hasErrorCode(errorObjects, errorCode) {
  return !!errorObjects && errorObjects.some(errorObject => errorCode === errorObject.code);
}

function parseErrors(errorObjects, errorStringMapping) {
  const defaultErrorMessage = "Bad input, please check form values";
  if (!errorObjects) {
    return [defaultErrorMessage];
  } else {
    return errorObjects.map(errorObject => errorStringMapping[errorObject.code] || defaultErrorMessage);
  }
}

function parseFancyErrors(errorObjects, errorMapping) {
  const defaultError = new AppError("Bad input, please check form values");
  if (!errorObjects) {
    return [defaultError.message];
  } else {
    const result = {};
    errorObjects.forEach(e => {
      const appError = errorMapping[e.code] || defaultError;
      if (!result[appError.scope]) {
        result[appError.scope] = [];
      }
      result[appError.scope].push(appError.message);
    })
    return result;
  }
}

function logSentryError(error) {
  console.log(error);
  Sentry.captureException(error);
}

export {parseErrors, logSentryError, parseFancyErrors, hasErrorCode, AppError};

import React, {useEffect, useState} from 'react';
import './hse.scss';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ServerRequests from "../../../service/ServerRequests";
import {logSentryError, parseErrors} from "service/errors";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import {number, string} from "prop-types";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import CalSelect from "components/CalSelect/CalSelect";
import CalRadioSelection from "components/CalRadioGroup/CalRadioSelection";
import backgroundStar from "assets/images/star_sprite.svg";
import {withStyles} from "@material-ui/core";

const DisclosuresCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#3C89E8',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

let HseForm = props => {
  let {handleCompletion, setLoading, loading, applicationId, graduationYear} = props;
  let defaultTestTypeForGraduationYear = graduationYear < 2014 ? 'GED' : null;

  const errorStrings = {
    'invalid-mathematics-score': 'Invalid Mathematical Reasoning score',
    'invalid-mathematics-score-pre2014': 'Invalid Math score',
    'invalid-social-studies-score': 'Invalid Social Studies score',
    'invalid-science-score': 'Invalid Science score',
    'invalid-reading-score': 'Invalid Reading score',
    'invalid-writing-score': 'Invalid Writing score',
    'invalid-language-arts-score': 'Invalid Reasoning through Language Arts score',
    'invalid-state': 'Select a Valid State',
    'invalid-test-type': 'Select a Test Type'
  };

  const [states, handleStates] = useState([]);
  const [error, handleError] = useState(null);
  const [disclosuresIsChecked, handleDisclosuresChanged] = useState(false);
  const [formInputs, handleFormInputs] = useState({
    applicationId: applicationId,
    mathematics: '',
    socialStudies: '',
    science: '',
    languageArts: '',
    reading: '',
    writing: '',
    state: '',
    testType: defaultTestTypeForGraduationYear,
  });

  useEffect(() => {
    ServerRequests.fetchStates().then((response) => {
      handleStates(response.data);
    }).catch(error => {
      logSentryError(error);
    })
  }, []);

  const handleSubmitGed = (event) => {
    setLoading(true);
    ServerRequests.submitHseScores(formInputs)
      .then((response) => {
        handleCompletion(event);
      })
      .catch(error => {
        setLoading(false);
        if (error.response === undefined || error.response.status !== 422) {
          logSentryError(error);
          handleError({type: "server"});
        } else {
          handleError({type: "user", messages: parseErrors(error.response.data.errors, errorStrings)})
        }
      });
    event.preventDefault();
  };

  let testTypeDefinition = null;
  if (formInputs.testType === 'GED') {
    if (graduationYear <= 2001) {
      testTypeDefinition = {
        minScore: 20, maxScore: 80,
        scoreRegex: /^\d{0,2}$/,
        subjects: [
          {label: 'Mathematics', name: 'mathematics'},
          {label: 'Social Studies', name: 'socialStudies'},
          {label: 'Science', name: 'science'},
          {label: 'Reading', name: 'reading'},
          {label: 'Writing', name: 'writing'},
        ]
      };
    } else if (graduationYear < 2014) {
      testTypeDefinition = {
        minScore: 300, maxScore: 800,
        scoreRegex: /^\d{0,3}$/,
        subjects: [
          {label: 'Mathematics', name: 'mathematics'},
          {label: 'Social Studies', name: 'socialStudies'},
          {label: 'Science', name: 'science'},
          {label: 'Reading', name: 'reading'},
          {label: 'Writing', name: 'writing'},
        ]
      };
    } else {
      testTypeDefinition = {
        minScore: 100, maxScore: 200,
        scoreRegex: /^\d{0,3}$/,
        subjects: [
          {label: 'Mathematical Reasoning', name: 'mathematics'},
          {label: 'Social Studies', name: 'socialStudies'},
          {label: 'Science', name: 'science'},
          {label: 'Reasoning through Language Arts', name: 'languageArts'},
        ]
      };
    }
  } else if (formInputs.testType === 'TASC') {
    testTypeDefinition = {
      minScore: 300, maxScore: 800,
      scoreRegex: /^\d{0,3}$/,
      subjects: [
        {label: 'Mathematics', name: 'mathematics'},
        {label: 'Social Studies', name: 'socialStudies'},
        {label: 'Science', name: 'science'},
        {label: 'Reading', name: 'reading'},
        {label: 'Writing', name: 'writing'},
      ]
    };
  } else if (formInputs.testType === 'HiSet') {
    testTypeDefinition = {
      minScore: 0, maxScore: 20,
      scoreRegex: /^\d{0,2}$/,
      subjects: [
        {label: 'Mathematics', name: 'mathematics'},
        {label: 'Social Studies', name: 'socialStudies'},
        {label: 'Science', name: 'science'},
        {label: 'Reading', name: 'reading'},
        {label: 'Writing', name: 'writing'},
      ]
    };
  }

  const testTypeChangeHandler = (fieldName) => {
    return (e) => handleFormInputs({
      ...formInputs,
      mathematics: '',
      socialStudies: '',
      science: '',
      languageArts: '',
      reading: '',
      writing: '',
      testType: sanitizeValue(e, fieldName),
    })
  };

  const formStateChangeHandler = (fieldName) => {
    return (e) => handleFormInputs({
      ...formInputs,
      [fieldName]: sanitizeValue(e.target.value, fieldName),
    })
  };

  const sanitizeValue = (value, fieldName) => {
    const scoreFields = ['mathematics', 'languageArts', 'science', 'socialStudies', 'writing', 'reading'];
    if (scoreFields.includes(fieldName)) {
      value = value.replace(/[,$.]/g, '');
      let isnum = testTypeDefinition.scoreRegex.test(value);
      return isnum ? value : formInputs[fieldName];
    }
    return value;
  };

  const blurHandler = event => {
    let isFormFilled = testTypeDefinition.subjects.reduce(
      (previousBool, currentSubject) => previousBool && !!(formInputs[currentSubject.name]),
      true)

    if(isFormFilled){
      document.getElementById("scoresStarId").classList.add('play')
    } else {
      document.getElementById("scoresStarId").classList.remove('play')
    }
  };

  return (
    <>
      <div className="academic__form-container">
          <form onSubmit={handleSubmitGed}>


            <div className="merit-details__form-input-section">
              <CalSelect
                labelClassName="merit-details__form-input-label"
                textboxClassName="merit-details__form-input-textbox"
                helpertextClassName="merit-details__form-input-helpertext"
                id="state-selector"
                bigLabel="Where did you complete your HSE?"
                label="State"
                inputProps={{'data-testid': 'number-of-decorations', className: 'academic__input-text'}}
                FormHelperTextProps={{className: 'academic__input-text'}}
                // shortInput={true}
                onChange={formStateChangeHandler('state')}
                value={formInputs.state}
                selectOptions={states}
              />
            </div>

            {graduationYear > 2013 &&
              <div className="merit-details__form-input-section">
                <CalRadioSelection
                  labelClassName="merit-details__form-input-label"
                  textboxClassName="merit-details__form-radio-button"
                  data-testid="lowest-grade-field"
                  id='test-type'
                  bigLabel="What test did you take?"
                  onNewValueSelected={testTypeChangeHandler('testType')}
                  value={formInputs.testType}
                  values={['GED', 'TASC', 'HiSet']}
                />
              </div>
            }

            {!!formInputs.testType &&
              <div className="merit-details__form-input-section">
                <FormControl
                  className="c-text-field"
                  style={{display: 'block'}}
                >
                  <label className="merit-details__form-input-label"
                         style={{display: 'inline-flex'}} >
                    Enter your {formInputs.testType} Scores
                  </label>
                  <div className="shapeshifter" id="scoresStarId" style={{backgroundImage: 'url('+backgroundStar+')'}}></div>

              {testTypeDefinition.subjects.map(subject => (
               <Grid key={subject.name} item xs={12} style={{marginTop: '1rem'}}>
                <TextField
                className='hse__score-fields'
                id={subject.name + '-field'}
                onChange={formStateChangeHandler(subject.name)}
                value={formInputs[subject.name]}
                InputLabelProps={{shrink: true}}
                InputProps={{className: "merit-details__form-input-textbox",
                endAdornment: <InputAdornment position="end"
                data-testid='end-adornment'>/{testTypeDefinition.maxScore}</InputAdornment>
              }}
                inputProps={{style: {textAlign: 'right'}}}
                onBlur={blurHandler}
                />
                <label htmlFor={subject.name + '-field'} id={subject.name + '-helper'}
                       style={{display: 'block'}}
                       className="merit-details__form-input-helpertext">
              {subject.label}
                </label>
                </Grid>
                ))
              }
                </FormControl>
               </div>
            }

            <div className="merit-details__transcript-msg">
              {
                props.children
              }
            </div>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <DisclosuresCheckbox
                    color="primary"
                    name="checkedB"
                    onChange={(event) => handleDisclosuresChanged(event.target.checked)}
                    style={{
                      transform: "scale(1.5) translateY(-7px)",
                      paddingInline: "1rem 1.5rem",
                    }}
                  />
                }
                label={
                  <div className='hse__disclosures'>
                    *By checking this box, I authorize Meritize to use my academic records in my loan
                    decision.
                  </div>
                }
                style={{alignItems: "start"}}
              />
            </Grid>

            <Grid item xs={12}>
              {(error) &&
              <ErrorMessage type={error.type} messages={error.messages}/>
              }
              <div className="merit-details__submit-button-container">
                <Button
                  className="merit-details__submit-button"
                  color="primary"
                  data-testid={"continue-button"}
                  disabled={!disclosuresIsChecked || loading}
                  type="submit"
                  variant="contained">
                  Continue
                </Button>
              </div>
            </Grid>
        </form>
      </div>
    </>
  );
}

HseForm.propTypes = {
  graduationYear: number.isRequired,
  applicationId: string.isRequired,
};

export default logCurrentPage(HseForm, 'merit_details_hse');

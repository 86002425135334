import React from "react";
import './Refer.scss';
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";

function Refer(props) {
  return (
    <div>
      <div className="refer__header">
        We are looking into it…
      </div>
      <div className="refer__text--primary">
        We may need additional information to finish your application
      </div>
      <div className="refer__text--secondary">
        A customer service agent will reach out to you soon.
      </div>
    </div>
  );
}

export default logCurrentPage(withHeader(backButtonRedirect(Refer)), 'refer');

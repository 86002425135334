import React from "react";
import "components/errorMessage/ErrorMessage.scss";
import ReportIcon from '@material-ui/icons/Report';

const CriticalError = (props) => {
  const {message} = props;

  return (
    <div className={`error-message error-message--server`} data-testid="error-msg-container">
      <div>
        <ReportIcon style={{width: "0.75rem", height: "0.75rem", verticalAlign: "-10%"}}/>&nbsp;{message}
      </div>
    </div>
  );
};

export default CriticalError;

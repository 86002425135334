import React, {useState} from "react";
import "components/CalRadioGroup/CalRadioSelection.scss";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {FormControl} from "@material-ui/core";
import backgroundStar from "assets/images/star_sprite.svg";

const CalRadioSelection = (props) => {
  const {
    id,
    labelClassName,
    textboxClassName,
    bigLabel,
    bigLabelProps,
    values,
    labels,
    onNewValueSelected,
    value,
    excludeStarIcon,
    ...otherProps
  } = props;

  let textboxClass = (textboxClassName ? textboxClassName : '') + ' c-radio-group-container--radio-item';

  let [selectedValue, setSelectedValue] = useState('');
  const showStarIcon = !props.excludeStarIcon

  let valueChanged = (event) => {
    event.target.parentNode.parentNode.parentNode.parentNode.parentNode.previousSibling.classList.add('play')
    if (event.target.value === selectedValue) {
      return;
    }
    setSelectedValue(event.target.value);
    onNewValueSelected(event.target.value);
  }

  let labelClass = labelClassName ? labelClassName : 'c-text-field__label';

  return (
    <FormControl
      className="c-text-field"
      style={{display: 'block'}}
      {...otherProps}
    >
      {bigLabel &&
        <>
          <label htmlFor={id}
             className={labelClass}
                 style={{display: 'inline-flex'}}
             {...bigLabelProps}
          >{bigLabel}</label>
        {showStarIcon && <div className="shapeshifter" style={{ backgroundImage: 'url(' + backgroundStar + ')' }}></div>}
        </>
      }

      <div className="c-radio-group-container">
        <RadioGroup
          onChange={valueChanged}
          row
          style={{ width: "100%" }}
          value={value}>
          {values && values.map((val,index) =>
            <FormControlLabel
              key={val}
              value={val}
              className={textboxClass}
              control={<Radio data-testid={id + '-' + val}/>} label={labels ? labels[index] : val}/>
          )}
        </RadioGroup>
      </div>
    </FormControl>
  );
};

export default CalRadioSelection;

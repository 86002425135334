import Button from "@material-ui/core/Button";
import React, { useEffect, useReducer, useState } from 'react';
import ServerRequests from "../../service/ServerRequests";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {hasErrorCode, logSentryError, parseErrors} from "service/errors";
import './Coapplicant.scss';
import Grid from "@material-ui/core/Grid";
import {format, isValid, subDays} from "date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import worldMap from "assets/images/world-map.svg";
import worldMapMobile from "assets/images/world-map-mobile.svg";
import Infographic from "components/header/infographic/Infographic";
import {formatPhone, middleNameIsNotSuffix, middleNameNoSpecialCharacters} from "util/FieldValidation";
import {useForm} from "react-hook-form";
import ErrorPopupReapply from "components/errorMessage/ErrorPopupReapply";
import {IconButton, withStyles} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import MarriedInfo from 'components/MarriedInfo/MarriedInfo';
import Environment from "util/Environment";


const errorStrings = {
  'first-name-required': 'Enter a valid first name',
  'last-name-required': 'Enter a valid last name',
  'first-name-special-chars': 'First name cannot contain special characters',
  'last-name-special-chars': 'Last name cannot contain special characters',
  'ssn-required': 'Enter a valid Social Security Number or ITIN',
  'invalid-ssn': 'Invalid Social Security Number or ITIN',
  'phone-number-required': 'Enter a valid phone number',
  'invalid-phone-number': 'Invalid phone number',
  'annual-income-required': 'Enter a valid annual household income',
  'invalid-annual-income': 'Invalid annual household income',
  'state-required': 'Select a valid State',
  'invalid-zipcode': 'Invalid ZIP code',
  'zipcode-required': 'Enter a valid ZIP code',
  'address-line-required': 'Enter a valid address',
  'address1-too-long': 'Address Line 1 is too long',
  'address1-special-chars': 'Address Line 1 cannot contain special characters',
  'address1-not-digits-only': 'Address Line 1 must be a full street address, e.g. 123 Example St',
  'address2-too-long': 'Address Line 2 is too long',
  'address2-special-chars': 'Address Line 2 cannot contain special characters',
  'city-required': 'Enter a valid city',
  'city-special-chars': 'City cannot contain special characters',
  'city-too-long': 'City is too long',
  'email-required': 'Enter a valid email',
  'invalid-email': 'Invalid email',
  'invalid-date-of-birth': 'Invalid date of birth',
  'date-of-birth-required': 'Enter a valid date of birth',
  'same-email-as-applicant': 'The applicant and cosigner cannot have the same email!',
  'same-ssn-as-applicant': 'The applicant and cosigner cannot be the same person!',
  'end-of-road-status-withdrawn': 'Your application has been withdrawn.',
  'end-of-road-status-error': 'Your application has encountered an error.',
  'end-of-road-status-expired': 'Your application has expired.',
  'first-name-too-long': 'First name is too long',
  'middle-name-too-long': 'Middle name is too long',
  'last-name-too-long': 'Last name is too long',
  'missing-marital-status': 'Enter their marital status',
  'missing-spouse-name': 'Enter a valid spouse name',
  'missing-spouse-address': 'Enter a valid spousal mailing address',
  'invalid-spouse-address': 'Enter a valid spousal mailing address',
  'spouse-name-duplicated': 'Spouse name cannot be the same as their name',
  'same-spouse-as-applicant': 'The applicant and cosigner cannot have the same spouse',
};

const DisclosuresCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#3C89E8',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Coapplicant = (props) => {

  const [formInputs, updateFormInputs] = useReducer((state, action) => {
    return {...state, ...action};
  },{
    ssn: '',
    dateOfBirth: null,
    annualIncome: '',
    spouseInformation: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [popupError, setPopupError] = useState(null);
  const [disclosuresIsChecked, handleDisclosuresChanged] = useState(false);
  const [states, handleStates] = useState([]);
  const [dateOfBirthError, setDateOfBirthError] = useState(null);
  const [showSsn, setShowSsn] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  const {register, handleSubmit, errors: formErrors} = useForm({mode: "onBlur"});

  let {applicationId, conditionalCoapplicant, hasOffers} = props.location.state;
  let inspectletInputClass = {'classes': {'input': "inspectlet-sensitive"}};

  const stateChangeHandler =
    (e) => {
      setSelectedState(e.target.value);
      formChangeHandler('state')(e)
    }

  useEffect(() => {
    ServerRequests.fetchDetailedStates().then((response) => {
      handleStates(response.data);
    }).catch(error => {
      logSentryError(error);
    })
  }, []);

  const formChangeHandler = (fieldName) => {
    return (e) => updateFormInputs({
      ...formInputs,
      [fieldName]: sanitizeValue(e.target.value, fieldName),
    })
  };

  const sanitizeValue = (value, fieldName) => {
    if (!!value && (['annualIncome', 'ssn', 'phoneNumber'].includes(fieldName))) {
      return value.replace(/[^0-9]/g, '');
    }
    return value;
  };

  const formDateChangeHandler = (date) => {
    updateFormInputs({
      ...formInputs,
      dateOfBirth: date
    });
  };


  function formatSSN(value) {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;
    if (ssnLength < 4) return ssn;
    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
  }

  useEffect(() => {
    if (!showSsn) {
      updateFormInputs({
        ssn: sanitizeValue(formInputs.ssn, "ssn")
      });
    } else {

      updateFormInputs({
        ssn: formatSSN(formInputs.ssn)
      })
    }
  }, [showSsn, formInputs.ssn])

  const formSsnChangeHandler = (e) => {
    const fieldName = e.target.name;
    let sanitizedValue = sanitizeValue(e.target.value, fieldName);
    if (showSsn) {
      sanitizedValue = formatSSN(sanitizedValue);
    }
    updateFormInputs({
      ...formInputs,
      [fieldName]: sanitizedValue
    });
  }

  const handleDateError = (e) => {
    setDateOfBirthError(e);
  };

  function formatDate(date) {
    if (date !== null && isValid(date)) {
      return format(date, 'yyyy-MM-dd').toString()
    }
    return null;
  }

  let submitCoapplicant = (data, event) => {
    submitData(event);
  };

  let submitData = (event) => {
    setLoading(true);
    let data = {
      applicationId: applicationId,
      conditionalCoapplicant: conditionalCoapplicant !== undefined ? conditionalCoapplicant : false
    };

    data = {
      ...data,
      firstName: formInputs.firstName,
      middleName: formInputs.middleName,
      lastName: formInputs.lastName,
      email: formInputs.email,
      phoneNumber: sanitizeValue(formInputs.phoneNumber, "phoneNumber"),
      address: {
        line1: formInputs.addressLine1,
        line2: formInputs.addressLine2,
        city: formInputs.city,
        state: formInputs.state,
        zip: formInputs.zip,
      },
      dateOfBirth: formatDate(formInputs.dateOfBirth),
      ssn: sanitizeValue(formInputs.ssn, 'ssn'),
      annualIncome: formInputs.annualIncome,
      spouseInformation: !formInputs.maritalStatus
        ? null
        : {
          maritalStatus: formInputs.maritalStatus,
          spouseName: formInputs.spouseName,
          spouseAddress: formInputs.spouseAddress,
        }
    }

    ServerRequests.submit('/submit-coapplicant-information', {data})
      .then(response => {
        let {status, needsManualEvaluation, applicationId, skipToFullapp} = response.data;
        if (status === 'Declined') {
          props.history.push('/declined', {applicationId: applicationId, isTempDeclined: true})
        } else if (status === 'Refer') {
          props.history.push('/refer', {applicationId});
        } else if (status === 'Offered') {
          if (needsManualEvaluation || skipToFullapp) {
            props.history.push('/full-app', {applicationId: applicationId});
          } else {
            props.history.push('/offers', {
              offers: response.data.offers,
              applicationId: response.data.applicationId,
              applicationCreatedDate: response.data.applicationCreatedDate,
              has18MonthNoInterest: response.data.has18MonthNoInterest,
              hasMultipleDisbursements: response.data.hasMultipleDisbursements,
              hasCoapplicant: true,
              isCpl: response.data.isCpl,
            });
          }
        } else {
          if(!!status) {
            logSentryError(new Error(`Unknown status: ${status}`));
          } else {
            logSentryError(new Error(`Unknown status: undefined`));
          }
        }
      })
      .catch(error => {
        if (error.response === undefined) {
          logSentryError(error);
          setError({type: "server"});
          setLoading(false);
        } else if (error.response.status === 403) {
          logSentryError(error);
          setLoading(false);
          if (hasErrorCode(error.response.data.errors, 'end-of-road-status-declined')) {
            props.history.push('/declined', {applicationId: error.response.data.applicationId, isTempDeclined: true})
          } else if (hasErrorCode(error.response.data.errors, 'end-of-road-status-ineligible')) {
            props.history.push('/ineligible', {applicationId: error.response.data.applicationId});
          } else {
            setPopupError({type: "server", messages: parseErrors(error.response.data.errors, errorStrings)});
          }
        } else if (error.response.status === 422) {
          setError({type: "user", messages: parseErrors(error.response.data.errors, errorStrings)})
          setLoading(false);
        } else if (error.response.status === 424) {
          logSentryError(error);
          props.history.push('/errorPage', {applicationId: applicationId})
        } else {
          logSentryError(error);
          setError({type: "server"});
          setLoading(false);
        }
      });

    event.preventDefault();
  };

  const formPhoneChangeHandler= (e) => {
    const fieldName = e.target.name;
    let sanitizedValue = sanitizeValue(e.target.value, fieldName);

    updateFormInputs({
      [fieldName]: formatPhone(sanitizedValue)
    });
  }

  return (
    <>
      {loading && <LoadingIndicator/>}

      <Infographic stage="3" title="Co-applicant"/>

      <form className='coapp__form'>

        <div className="prequal__form--section-header--primary">
          Co-applicant
        </div>

        {conditionalCoapplicant && <p>You need to add a co-applicant</p>}

        <Grid
          container
          spacing={3}>

          <Grid item xs={12}>

            <Grid
              container
              spacing={1}>

              {
                !conditionalCoapplicant && !hasOffers &&
                <Grid item xs={12}>
                  <div className='coapp__small-text'>
                    Adding a co-applicant is optional; however, in certain circumstances, having a co-applicant could
                    potentially improve your chances of being approved for a loan.
                  </div>
                </Grid>
              }
              {
                !conditionalCoapplicant && hasOffers &&
                <Grid item xs={12}>
                  <div className='coapp__small-text'>
                    In certain circumstances, having a co-applicant could
                    potentially improve your chances of being approved for a loan.
                  </div>
                </Grid>
              }

              <Grid
                item
                container
                spacing={2}>

                <Grid item xs={8}>
                  <TextField
                    InputProps={{...inspectletInputClass, className: 'coapp__first-name-input'}}
                    id='first-name-field'
                    label="First Name"
                    onChange={formChangeHandler('firstName')}
                    style={{width: '100%'}}
                  />
                </Grid>


                <Grid item xs={4}>
                  <TextField
                    InputProps={{...inspectletInputClass, className: 'coapp__middle-name-input'}}
                    id='middle-name-field'
                    label="Middle Name"
                    onChange={formChangeHandler('middleName')}
                    style={{width: '100%'}}
                    error={!!formErrors.middleName}
                    inputRef={register({
                      validate: {
                        value: (value) => {
                          if (!middleNameIsNotSuffix(value)) {
                            return 'Cannot be a suffix (Jr, Sr, etc)';
                          } else if (!middleNameNoSpecialCharacters(value)) {
                            return 'Cannot contain special characters';
                          } else {
                            return true;
                          }
                        }
                      }
                    })}
                    helperText={formErrors.middleName ? formErrors.middleName.message : ' '}
                    name="middleName"
                  />
                </Grid>

              </Grid>

              <Grid item xs={6}>
                <TextField
                  InputProps={{...inspectletInputClass, className: 'coapp__last-name-input'}}
                  id='last-name-field'
                  label="Last Name"
                  onChange={formChangeHandler('lastName')}
                  style={{width: '100%'}}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  InputProps={{...inspectletInputClass, className: 'coapp__email-input'}}
                  id='email-address-field'
                  label="Email"
                  onChange={formChangeHandler('email')}
                  style={{width: '100%'}}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="phone-number-field"
                  InputProps={{...inspectletInputClass, className: 'coapp__phone-number-input'}}
                  inputProps={{maxLength: 14, type: 'tel' }}
                  label="Phone Number"
                  name="phoneNumber"
                  onChange={formPhoneChangeHandler}
                  style={{width: '100%'}}
                  value={formInputs.phoneNumber}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className='coapp__section-header'>
              Address
            </div>

            <Grid
              container
              spacing={2}>

              <Grid item xs={12} sm={8}>
                <TextField
                  InputProps={{...inspectletInputClass, className: 'coapp__address1-input'}}
                  id='address1-field'
                  label="Enter a US street address*"
                  onChange={formChangeHandler('addressLine1')}
                  style={{width: '100%'}}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <TextField
                  InputProps={{...inspectletInputClass, className: 'coapp__address2-input'}}
                  id='address2-field'
                  label="Apt/Unit/PO Box"
                  onChange={formChangeHandler('addressLine2')}
                  style={{width: '100%'}}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  InputProps={{...inspectletInputClass, className: 'coapp__city-input'}}
                  id='city-field'
                  label="City"
                  onChange={formChangeHandler('city')}
                  style={{width: '100%'}}
                />
              </Grid>

              <Grid
                className="form_coapp__dropdown-control"
                item xs={5} sm={2}>
                <FormControl
                  className="state-selector__control"
                  style={{width: '100%'}}>
                  <InputLabel htmlFor="state-selector">State</InputLabel>
                  <Select
                    native
                    value={formInputs.state}
                    onChange={stateChangeHandler}
                    inputProps={
                      {
                        'id': 'state-selector',
                        'data-testid': 'state-selector',
                      }
                    }>
                    <option hidden value=""/>
                    {
                      states != null && states.length > 0 &&
                      states.map((state) =>
                        <option key={state.stateCode} value={state.stateCode}
                                data-testid={`state-option-${state.stateCode}`}>
                          {state.stateName}
                        </option>
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={7} sm={4}>
                <TextField
                  InputProps={{...inspectletInputClass, className: 'coapp__zip-input'}}
                  id='zip-field'
                  inputProps={{maxLength: 5}}
                  label="ZIP"
                  onChange={formChangeHandler('zip')}
                  style={{width: '100%'}}
                />
              </Grid>
            </Grid>

            <div className="form-worldMap__container--desktop">
              <img src={worldMap} alt="World Map"
                   className="form-worldMap__image--desktop"
              />
              <img src={worldMapMobile} alt="World Map"
                   className="form-worldMap__image--mobile"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className='coapp__section-header'>
              Additional Info
            </div>

            <Grid
              container
              spacing={1}>

              <Grid
                item xs={12}
                className='coapp__date-of-birth-input'>
                <KeyboardDatePicker
                  data-testid="coapplicant-dob-field"
                  disableToolbar
                  format="MM/dd/yyyy"
                  id="date-of-birth-field"
                  initialFocusedDate={null}
                  InputProps={{...inspectletInputClass}}
                  invalidDateMessage="Invalid date. Enter valid mm/dd/yyyy"
                  keyboardIcon={false}
                  KeyboardButtonProps={{'disabled': true}}
                  label="Date of Birth"
                  onChange={formDateChangeHandler}
                  maxDate={subDays(new Date(), 1)}
                  onError={handleDateError}
                  placeholder="mm/dd/yyyy"
                  style={{width: '100%'}}
                  value={formInputs.dateOfBirth}
                  variant="inline"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="ssn-field"
                  type={showSsn ? "text" : "password"}
                  inputProps={showSsn ? {maxLength: 11, "data-testid": "ssn-field-testid"} : {maxLength: 9, "data-testid": "ssn-field-testid"}}
                  helperText=" "
                  InputProps={{
                    ...inspectletInputClass,
                    className: 'coapp__ssn-input',
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          data-testid="prequal-form-ssn-icon-btn"
                          aria-label="toggle ssn visibility"
                          onClick={() => {
                            setShowSsn(!showSsn);
                          }}
                          edge="end"
                          size="small"
                        >
                          {showSsn ? <VisibilityOff data-testid="prequal-form-visibility-off"/> : <Visibility data-testid="prequal-form-visibility-on"/>}
                        </IconButton>
                      </InputAdornment>
                  }}
                  label="9 digit SSN or ITIN"
                  name="ssn"
                  onChange={formSsnChangeHandler}
                  style={{width: '100%'}}
                  value={formInputs.ssn}
                />
              </Grid>
            </Grid>
          </Grid>

          {
            selectedState === 'WI'
            && !!!Environment.featureFlagEnabled('disable-wi-spouse')
            && <MarriedInfo
              formInputs={formInputs}
              updateFormInputs={updateFormInputs}
            />
          }



          <Grid item xs={12}>
            <Grid item xs={12}>
              <div className='coapp__section-header'>
                Annual Household Income
              </div>
            </Grid>

            <Grid
              item
              container
              spacing={1}>
              <Grid item xs={12}>
                <div className='coapp__xsmall-text'>
                  Alimony, child support or separate maintenance income is optional to include.
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='annual-income-field'
                  InputProps={{
                    ...inspectletInputClass,
                    className: 'coapp__annual-income-input',
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  label="Enter in full dollar amounts"
                  onChange={formChangeHandler('annualIncome')}
                  style={{width: '100%'}}
                  value={formInputs.annualIncome}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <DisclosuresCheckbox
                  color="primary"
                  data-testid="disclosures-checkbox"
                  name="checkedB"
                  onChange={(event) => handleDisclosuresChanged(event.target.checked)}
                  style={{
                    transform: "scale(1.5) translateY(7px)",
                    paddingInline: "1rem 1.5rem",
                  }}
                />
              }
              label={
                <div className='prequal__disclosures'>
                  *I have read, understood and consent to the language and authorizations in the Meritize&nbsp;
                  <a href="/legal/esign_consent.html" target="_blank"
                     rel="noopener noreferrer">ESIGN Consent</a>,&nbsp;
                  <a href="/legal/call_sms_consent.html" target="_blank"
                     rel="noopener noreferrer">Consent to Receive Calls, SMS Text Messages, and Emails</a>,&nbsp;
                  <a href="/legal/meritize_privacy_notice.html" target="_blank"
                     rel="noopener noreferrer">Meritize Privacy Notice</a>,&nbsp;
                  <a href="/legal/credit_report_authorization.html" target="_blank"
                     rel="noopener noreferrer">Credit Pull Authorization</a>, and&nbsp;
                  <a href="/legal/terms_and_conditions.html" target="_blank"
                     rel="noopener noreferrer">Terms of Service</a>.
                </div>
              }
              style={{alignItems: "start"}}/>

            {(error) && <ErrorMessage type={error.type} messages={error.messages}/>}
            {(popupError) && <ErrorPopupReapply error={popupError} setError={setPopupError} {...props} />}
            <div className="merit-details__submit-button-container">
              <Button
                className="coapp__submit-button"
                data-testid={"coapplicant-continue-button"}
                color="primary"
                variant="contained"
                disabled={!disclosuresIsChecked || !!dateOfBirthError || loading}
                onClick={(e) => {handleSubmit(submitCoapplicant)(e)}}>
                Continue
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default logCurrentPage(withHeader(backButtonRedirect(Coapplicant)), 'coapplicant');

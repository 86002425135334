
const strToBool = (s) => {
  return /^(true|yes|on|1)$/.test((s || '').toLowerCase());
}

export class EnvironmentConfig {
  constructor(window, process_env_func) {
    this.window = window;
    // We use a function here because there's some magic behind process.env that prohibits just saving
    // the value of `process`. We need to access `env` lazily so we can mock it in some tests.
    this.process_env_func = process_env_func;
  }

  envConf(name, reactAppName) {
    return this.window.environmentConfig ?
      this.window.environmentConfig[name] :
      this.process_env_func()[reactAppName];
  }

  redirectToMaintenance() { return strToBool(this.envConf('redirectToMaintenance', 'REACT_APP_REDIRECT_TO_MAINTENANCE')); }
  upcomingMaintenanceDatetime() { return this.envConf('UPCOMING_MAINTENANCE_DATETIME', 'REACT_APP_UPCOMING_MAINTENANCE_DATETIME'); }
  upcomingMaintenanceMessage() { return this.envConf('UPCOMING_MAINTENANCE_MESSAGE', 'REACT_APP_UPCOMING_MAINTENANCE_MESSAGE'); }
  apiUrl() {
    const apiUrl = this.envConf('API_URL', 'REACT_APP_API_URL');
    if (!apiUrl) {
      throw new Error("API URL must be configured, either through window.environmentConfig or REACT_APP_API_URL.");
    }
    return apiUrl;
  }

  sentryDsn()         { return this.envConf('SENTRY_DSN',         'REACT_APP_SENTRY_DSN');         }
  sentryEnvironment() { return this.envConf('SENTRY_ENVIRONMENT', 'REACT_APP_SENTRY_ENVIRONMENT'); }

  featureFlagEnabled(flagName) {
    const enabledEnvFlags = this.envConf('FEATURE_FLAGS', 'REACT_APP_FEATURE_FLAGS');
    const localStorageFlag = localStorage.getItem('EXTRA_FEATURE_FLAGS');
    const disabledFlags = localStorage.getItem('DISABLED_FEATURE_FLAGS');
    const enabledFlags = (enabledEnvFlags || '') + ',' + (localStorageFlag || '');
    return !!disabledFlags && disabledFlags.split(/,/).indexOf(flagName) >= 0 ? false : enabledFlags.split(/,/).indexOf(flagName) >= 0;
  }
}

let Environment = new EnvironmentConfig(window, () => process.env);

export default Environment;

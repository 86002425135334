import Grid from '@material-ui/core/Grid';
import React from 'react';
import PageTipIcon from 'assets/images/pageTipIcon.png';
import "./LoanInfoCard.scss"
import OfferCard from "components/LoanInfoCard/OfferCard";
import CplOfferCard from "components/LoanInfoCard/CplOfferCard";


export default function LoanInfoCard(props) {
  return (
    <div className="loaninfo_card_container">
      <div className="loaninfo_card__title">Your Application</div>
      <div className="loaninfo_card__subtitle_description">If proof of financing is required for admission, please print or save this page.</div>

      <div className="loaninfo_card__school-name">{props.loanInfo.schoolName}</div>
      <div className="loaninfo_card__program-name">{props.loanInfo.programName}</div>

      <Grid container spacing={1} className="loaninfo_card__loan-amount-ofee-container">
        <Grid item xs={6}>
          <div className="loaninfo_card__loan-amount-label loaninfo_card__label">Loan Amount</div>
        </Grid>
        <Grid item xs={6}>
          <div className="loaninfo_card__loan-amount-label loaninfo_card__value">${props.loanInfo.loanAmount}</div>
        </Grid>
        <Grid item xs={6}>
          <div className="loaninfo_card__ofee-label loaninfo_card__label">Origination fee</div>
        </Grid>
        <Grid item xs={6}>
          <div className="loaninfo_card__ofee-label loaninfo_card__value">${props.loanInfo.originationFee}</div>
        </Grid>
      </Grid>

      <div className="loaninfo_card__repayment-option-definition">
        <div>
          <img className="loaninfo_card__repayment-option-definition-img" src={PageTipIcon} alt=""/>
        </div>
        <div className="loaninfo_card__repayment-option-definition-text">Repayment Term is the period when regularly
          scheduled Principal & Interest Payments will be due.
        </div>
      </div>

      {props.loanInfo.repaymentOption !== 'CPL' &&
        <OfferCard loanInfo={props.loanInfo}/>
      }
      {props.loanInfo.repaymentOption === 'CPL' &&
        <CplOfferCard loanInfo={props.loanInfo}/>
      }

    </div>
  );
}

import React, { useCallback, useEffect, useReducer, useState } from 'react';
import TextFieldOriginal from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './PrequalificationForm.scss';
import ServerRequests from '../../service/ServerRequests';
import Select from '@material-ui/core/Select';
import AutocompleteOriginal from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import { useForm } from 'react-hook-form';
import validate from 'validate.js';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, isValid, subDays } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AppError, logSentryError, parseFancyErrors } from 'service/errors';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import _ from 'lodash';
import { backButtonRedirect } from 'components/hoc/BackButtonRedirect/BackButtonRedirect';
import withHeader from 'components/header/WithHeader';
import CriticalError from 'components/errorMessage/CriticalError';
import FormError from 'components/errorMessage/FormError';
import Infographic from 'components/header/infographic/Infographic';
import worldMap from '../../assets/images/world-map.svg';
import worldMapMobile from '../../assets/images/world-map-mobile.svg';
import { formatPhone, middleNameNoSpecialCharacters } from "util/FieldValidation";
import { middleNameIsNotSuffix } from "util/FieldValidation";
import { IconButton, withStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ErrorPopupIneligible from "components/errorMessage/ErrorPopupIneligible";
import MarriedInfo from 'components/MarriedInfo/MarriedInfo';
import FakePrequalForm from 'components/FakeForm/FakePrequalForm';
import Environment from "util/Environment";


const TextField = React.memo((props) => {
  return <TextFieldOriginal {...props} />;
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});

const Autocomplete = React.memo((props) => {
  return <AutocompleteOriginal {...props} />;
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});

const getSchoolName = (school) => school.schoolName;
const getCampusName = (campus) => campus.campusName;
const getProgramName = (program) => program.programName;

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:768px)': {
      width: '100%',
      maxWidth: '1000px',
      margin: 'auto',
    },
  },
  dateOfBirth: {
    marginBottom: '1rem'
  },
}));

const DisclosuresCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#3C89E8',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);


const sanitizeValue = (value, fieldName) => {
  if (!!value && (['loanAmount', 'ssn', 'phoneNumber'].includes(fieldName))) {
    return value.replace(/[^0-9]/g, '');
  }
  return value;
};

const inspectletInputClass = { 'classes': { 'input': "inspectlet-sensitive" } };

const SchoolAutocompleteInput = (params) =>
  <TextField
    {...params}
    label="School Name"
  />;

const CampusAutocompleteInput = (params) =>
  <TextField
    {...params}
    label="Campus"
  />;

const ProgramAutocompleteInput = (params) =>
  <TextField
    {...params}
    label="Program"
  />;

const NO_PREFILLED_VALUE = 'NO_PREFILLED_VALUE';

const errorMapping = {
  'first-name-required': new AppError('Enter a valid first name'),
  'last-name-required': new AppError('Enter a valid last name'),
  'first-name-special-chars': new AppError('First name cannot contain special characters'),
  'middle-name-special-chars': new AppError('Middle name cannot contain special characters'),
  'middle-name-is-suffix': new AppError('JR is not accepted by the credit bureau as a middle name'),
  'last-name-special-chars': new AppError('Last name cannot contain special characters'),
  'ssn-required': new AppError('Enter a valid Social Security Number or ITIN'),
  'invalid-ssn': new AppError('Invalid Social Security Number or ITIN'),
  'phone-number-required': new AppError('Enter a valid phone number'),
  'invalid-phone-number': new AppError('Invalid phone number'),
  'program-required': new AppError('Enter a valid program'),
  'invalid-zipcode': new AppError('Invalid ZIP code'),
  'zipcode-required': new AppError('Enter a valid ZIP code'),
  'address-line-required': new AppError('Enter a valid address'),
  'address1-too-long': new AppError('Address Line 1 is too long'),
  'address1-special-chars': new AppError('Address Line 1 cannot contain special characters'),
  'address1-not-digits-only': new AppError('Address Line 1 must be a full street address, e.g. 123 Example St'),
  'address2-too-long': new AppError('Address Line 2 is too long'),
  'address2-special-chars': new AppError('Address Line 2 cannot contain special characters'),
  'city-required': new AppError('Enter a valid city'),
  'city-special-chars': new AppError('City cannot contain special characters'),
  'city-too-long': new AppError('City is too long'),
  'state-required': new AppError('Select a state'),
  'email-required': new AppError('Enter a valid email'),
  'invalid-email': new AppError('Invalid email'),
  'invalid-date-of-birth': new AppError('Invalid date of birth'),
  'date-of-birth-required': new AppError('Enter a valid date of birth'),
  'loan-amount-required': new AppError('The required minimum for the loan is $1,000'),
  'loan-amount-too-low': new AppError('The required minimum for the loan is $1,000'),
  'loan-amount-too-low-for-state': new AppError('Cannot apply for less than the minimum loan amount'),
  'loan-amount-too-high': new AppError('You can only borrow up to the max loan amount'),
  'state-min-greater-than-program-max': new AppError('Unable to lend to this program in your state of residence', 'program'),
  'first-name-too-long': new AppError('First name is too long'),
  'middle-name-too-long': new AppError('Middle name is too long'),
  'last-name-too-long': new AppError('Last name is too long'),
  'missing-marital-status': new AppError('Enter your marital status'),
  'missing-spouse-name': new AppError('Enter a valid spouse name'),
  'missing-spouse-address': new AppError('Enter a valid spousal mailing address'),
  'invalid-spouse-address': new AppError('Enter a valid spousal mailing address'),
  'spouse-name-duplicated': new AppError('Spouse name cannot be the same as your name')
};



const PrequalificationForm = (props) => {
  const classes = useStyles();

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [maximumLoanAmount, setMaximumLoanAmount] = useState(null);
  const [minimumLoanAmount, setMinimumLoanAmount] = useState(null);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [campuses, setCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [problemWithProgram, setProblemWithProgram] = useState(false);
  const [disclosuresIsChecked, setDisclosuresChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [programCode, setProgramCode] = useState(null);
  const [schoolSlug, setSchoolSlug] = useState(null);
  const [dateOfBirthError, setDateOfBirthError] = useState(null);
  const [showSsn, setShowSsn] = useState(false);
  const [ineligibleReasonForPopup, setIneligibleReasonForPopup] = useState(null);
  const openIneligiblePopup = setIneligibleReasonForPopup;

  const { register, handleSubmit, errors: formErrors } = useForm({ mode: "onBlur" });
  const [formInputs, updateFormInputs] = useReducer((state, action) => {
    return { ...state, ...action };
  },
    { dateOfBirth: null, loanAmount: '', ssn: '', phoneNumber: '', spouseInformation: null },
  );

  const DEFAULT_MIN_LOAN_AMOUNT = 1000;


  const fetchSchools = () => {
    ServerRequests.fetchSchools().then((response) => {
      setSchools(response.data);
    }).catch(error => {
      logSentryError(error);
    });
  };

  const urlSearch = props?.location?.search;


  useEffect(() => {
    let query = new URLSearchParams(urlSearch);
    let pc = query.get("pc");
    let slug = query.get("sc");

    setProgramCode((pc != null) ? pc : NO_PREFILLED_VALUE);
    setSchoolSlug((slug != null) ? slug : NO_PREFILLED_VALUE);
  }, [urlSearch]);

  useEffect(() => {
    ServerRequests.fetchDetailedStates().then((response) => {
      setStates(response.data);
    }).catch(error => {
      logSentryError(error);
    });
  }, []);

  useEffect(() => {
    if (programCode === NO_PREFILLED_VALUE && schoolSlug === NO_PREFILLED_VALUE) {
      fetchSchools();
    } else if (programCode !== NO_PREFILLED_VALUE && programCode) {
      // call api and fetch school & program values
      ServerRequests.fetchPrefilledProgramInfo(programCode)
        .then((res) => {
          let { campus, school, program } = res.data;
          if (school && campus && program) {
            // prefill form with values
            setSelectedProgram(program);
            setSelectedCampus(campus);
            setSelectedSchool(school);
            setSchools([school]);
          } else {
            setProgramCode(NO_PREFILLED_VALUE);
          }
        }).catch(error => {
          logSentryError(error);
        });
    } else if (schoolSlug) {
      // call api and fetch school
      ServerRequests.fetchPrefilledSchoolInfo(schoolSlug)
        .then((res) => {
          let { school, campuses } = res.data;
          if (school) {
            // prefill form with values
            setSelectedSchool(school);
            setSchools([school]);
            setCampuses(campuses);
          } else {
            setSchoolSlug(NO_PREFILLED_VALUE);
          }
        }).catch(error => {
          logSentryError(error);
        });
    }

  }, [programCode, schoolSlug]);

  useEffect(() => {
    if ((!programCode || programCode === NO_PREFILLED_VALUE) && (!schoolSlug || schoolSlug === NO_PREFILLED_VALUE)) {
      if (!!selectedSchool) {
        ServerRequests.fetchCampusForSchoolId(selectedSchool.schoolId).then((response) => {
          setCampuses(response.data);
        }).catch(error => {
          logSentryError(error);
        });
      } else {
        // no value for selected school.
        setCampuses([]);
        setSelectedCampus(null);
      }
    }
  }, [selectedSchool, programCode, schoolSlug]);

  useEffect(() => {
    if (!programCode || programCode === NO_PREFILLED_VALUE) {
      if (!!selectedCampus) {
        ServerRequests.fetchProgramsForCampusId(selectedCampus.campusId).then((response) => {
          setPrograms(response.data);
        }).catch(error => {
          logSentryError(error);
        });
      } else {
        setPrograms([]);
        setSelectedProgram(null);
      }
    }
  }, [selectedCampus, programCode]);

  useEffect(() => {
    if (selectedState && selectedProgram) {
      ServerRequests.checkProgramAndStateLoanAmountInfo(selectedState, selectedProgram.programId).then((response) => {
        setErrors({});
        setProblemWithProgram(false);
      }).catch(error => {
        if (error.response.status === 422) {
          setErrors({ user: parseFancyErrors(error.response.data.errors, errorMapping) });
          setProblemWithProgram(true);
        } else {
          logSentryError(error);
          setErrors({ server: 'Internal error processing your request. Try again later.' });
          setLoading(false);
        }

      });
    }

  }, [selectedProgram, selectedState]);

  useEffect(() => {
    updateFormInputs({
      'state': sanitizeValue(selectedState, 'state'),
    });
  }, [selectedState]);

  useEffect(() => {
    if (!selectedProgram) {
      setMaximumLoanAmount(null);
      setMinimumLoanAmount(null);
    } else {
      if (states != null && states.length > 0 && selectedState != null && selectedState.length > 0) {
        var statesMap = new Map(states.map(st => [st.stateCode, st]));
        let stMaximumLoanAmount = statesMap.get(selectedState).maximumLoanAmount;
        let stMinimumLoanAmount = statesMap.get(selectedState).minimumLoanAmount;
        setMaximumLoanAmount(!stMaximumLoanAmount ? selectedProgram.maximumLoanAmount : Math.min(stMaximumLoanAmount, selectedProgram.maximumLoanAmount));
        setMinimumLoanAmount(!stMinimumLoanAmount ? DEFAULT_MIN_LOAN_AMOUNT : Math.max(stMinimumLoanAmount, DEFAULT_MIN_LOAN_AMOUNT));
      } else {
        setMaximumLoanAmount(selectedProgram.maximumLoanAmount);
        setMinimumLoanAmount(DEFAULT_MIN_LOAN_AMOUNT);
      }
    }
  }, [selectedState, selectedProgram, states]);


  const stateChangeHandler = useCallback(
    (e) => {
      setSelectedState(e.target.value);
    }, []);

  function submitForm() {
    setLoading(true);
    handleFormResponse(sendRequest);
  }

  function sendRequest() {
    return ServerRequests.submit('/submit-pre-qualification',
      {
        data: {
          email: formInputs.email,
          firstName: formInputs.firstName,
          middleName: formInputs.middleName,
          lastName: formInputs.lastName,
          address: {
            line1: formInputs.addressLine1,
            line2: formInputs.addressLine2,
            city: formInputs.city,
            state: formInputs.state,
            zip: formInputs.zip,
          },
          dateOfBirth: formatDate(formInputs.dateOfBirth),
          ssn: sanitizeValue(formInputs.ssn, 'ssn'),
          programId: selectedProgram ? selectedProgram.programId : null,
          phoneNumber: sanitizeValue(formInputs.phoneNumber),
          loanAmount: formInputs.loanAmount,
          spouseInformation: !formInputs.maritalStatus
            ? null
            : {
              maritalStatus: formInputs.maritalStatus,
              spouseName: formInputs.spouseName,
              spouseAddress: formInputs.spouseAddress,
            }
        },
      }
    )
  }


  function handleFormResponse(sendRequest) {
    sendRequest().then((response) => {
      let { applicationId } = response.data;
      if (window.__insp) {
        window.__insp.push(['identify', applicationId]); //set inspectlet identity
      }
      console.log("BEFORE NAVEGATING TO NEXT PAGE") // TODEL
      navigateToNextPage({
        responseData: response.data,
        forceCosigner: selectedProgram ? selectedProgram.forceCosigner : null,
        programName: selectedProgram.programName,
        schoolName: selectedSchool.schoolName
      });

    }).catch(error => {
      if (error.response === undefined) {
        logSentryError(error);
        setErrors({ server: 'Internal error processing your request. Try again later.' });
        setLoading(false);
      } else if (error.response.status === 422) {
        setErrors({ user: parseFancyErrors(error.response.data.errors, errorMapping) });
        setLoading(false);
      } else if (error.response.status === 424) {
        logSentryError(error);
        props.history.push('/errorPage', {});
      } else {
        logSentryError(error);
        setErrors({ server: 'Internal error processing your request. Try again later.' });
        setLoading(false);
      }
    })
  }




  const navigateToNextPage = ({ responseData, forceCosigner, programName, schoolName }) => {
    let {
      status,
      offers,
      applicationId,
      isRecentDuplicate,
      applicationCreatedDate,
      ineligibleReason,
      needsManualEvaluation,
      has18MonthNoInterest,
      hasMultipleDisbursements,
      isOfferSelected,
      selectedOffer,
      applicationPath,
      addCoapplicant,
      meritDetailsComplete,
      graduationYear,
      isHighSchool,
      hasCoapplicant,
      isCpl,
      skipToFullapp,
      errors,
    } = responseData;

    switch (status) {
      case 'Pending':
        if (skipToFullapp) {
          props.history.push('/full-app', {
            applicationId: applicationId
          });
        } else if (meritDetailsComplete) {
          props.history.push('/coapplicant', {
            applicationId: applicationId,
            conditionalCoapplicant: !addCoapplicant,
            hasOffers: false
          });
        } else if (applicationPath) {
          props.history.push('/merit-details', {
            applicationId: applicationId, applicationPath: applicationPath,
            addCoapplicant: forceCosigner || addCoapplicant, graduationYear: graduationYear, isHighSchool: isHighSchool
          });
        } else {
          props.history.push('/merit', { applicationId: applicationId, forceCosigner: forceCosigner });
        }
        break;

      case 'Offered':
        if (needsManualEvaluation) {
          props.history.push('/full-app', {
            applicationId: applicationId
          });
        } else if (isOfferSelected) {
          let offerSelected = {
            ...selectedOffer,
            programName,
            schoolName,
          };
          props.history.push('/full-app', {
            applicationId: applicationId,
            loanInfo: offerSelected
          });
        } else if (isRecentDuplicate && addCoapplicant && !hasCoapplicant) {
          props.history.push('/coapplicant', {
            applicationId: applicationId,
            conditionalCoapplicant: false,
            hasOffers: true,
          });
        } else if (!isRecentDuplicate && addCoapplicant) {
          logSentryError(new Error(`Application got Offers from prequal for a forced-cosigner program.  This should never happen.`));
          props.history.push('/errorPage', { applicationId: applicationId });
        } else {
          props.history.push('/offers', {
            applicationId: applicationId,
            applicationCreatedDate: applicationCreatedDate,
            has18MonthNoInterest: has18MonthNoInterest,
            hasMultipleDisbursements: hasMultipleDisbursements,
            offers: offers,
            hasCoapplicant: hasCoapplicant,
            isCpl: isCpl,
          });
        }
        break;

      case 'Declined':
        if (isRecentDuplicate) {
          props.history.push('/existing-app', { status, applicationId });
        } else {
          props.history.push('/declined', { applicationId: applicationId, isTempDeclined: false })
        }
        break;

      case 'Error':
        if (errors[0].code === "address-outside-us-equifax-error") {
          props.history.push("/errorPageAddressOutsideUs", { applicationId });
        } else {
          logSentryError(errors)
          props.history.push('/errorPage', { applicationId });
        }
        break;

      case 'Ineligible':
        const nullSafeIneligibleReason = ineligibleReason ? ineligibleReason : "ineligible"
        openIneligiblePopup(nullSafeIneligibleReason)
        break;

      case 'Refer':
        props.history.push('/refer', { applicationId });
        break;

      default:
        if (isRecentDuplicate) {
          props.history.push('/existing-app', { status, applicationId, path: applicationPath });
        } else {
          logSentryError(new Error(`Unknown status: ${status}`));
        }
    }
  };

  const formChangeHandler = useCallback(
    (e) => {
      const fieldName = e.target.name;
      updateFormInputs({
        [fieldName]: sanitizeValue(e.target.value, fieldName),
      });
    }, [updateFormInputs]);

  useEffect(() => {
    if (!showSsn) {
      updateFormInputs({
        ssn: sanitizeValue(formInputs.ssn, "ssn")
      });
    } else {
      updateFormInputs({
        ssn: formatSSN(formInputs.ssn)
      });
    }
  }, [showSsn, formInputs.ssn])

  const formSsnChangeHandler = (e) => {
    const fieldName = e.target.name;
    let sanitizedValue = sanitizeValue(e.target.value, fieldName);
    if (showSsn) {
      sanitizedValue = formatSSN(sanitizedValue);
    }
    updateFormInputs({
      [fieldName]: sanitizedValue
    });
  }

  const formDateChangeHandler = (date) => {
    updateFormInputs({
      dateOfBirth: date
    });
  };


  const formPhoneChangeHandler = (e) => {
    const fieldName = e.target.name;
    let sanitizedValue = sanitizeValue(e.target.value, fieldName);

    updateFormInputs({
      [fieldName]: formatPhone(sanitizedValue)
    });
  }

  const handleDateError = (e) => {
    setDateOfBirthError(e);
  };


  function formatDate(date) {
    if (date !== null && isValid(date)) {
      return format(date, 'yyyy-MM-dd').toString();
    }
    return null;
  }

  function formatSSN(value) {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;
    if (ssnLength < 4) return ssn;
    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
  }

  const looksLikeEmail = (text) => {
    const emailConstraints = {
      searchVal: {
        email: true,
      },
    };
    return !validate({ searchVal: text }, emailConstraints);
  };

  function clearLoanAmount() {
    updateFormInputs({ loanAmount: '' });
  }

  const onSchoolChanged = useCallback((e, updatedSchool) => {
    setSelectedSchool(updatedSchool);
    setCampuses([]);
    setSelectedCampus(null);
    clearLoanAmount();
  }, [setSelectedSchool, setCampuses, setSelectedCampus]);

  const onCampusChanged = useCallback((e, updatedCampus) => {
    setSelectedCampus(updatedCampus);
    setPrograms([]);
    setSelectedProgram(null);
    clearLoanAmount();
  }, [setSelectedCampus, setPrograms, setSelectedProgram]);

  const onProgramChanged = useCallback((e, updatedProgram) => {
    setSelectedProgram(updatedProgram);
    clearLoanAmount();
  }, [setSelectedProgram]);

  const loanAmountEnabled = selectedProgram && !problemWithProgram;

  // remove the False bellow, if you want to use the FakePrequalForm on an E2E
  // while developing a new feature
  // after the feature is done, but the false back, and make sure the E2E don't use the FakeForm anymore
  return false && props.useFakeForm 
    ? <FakePrequalForm handleFormResponse={handleFormResponse} setSelectedProgram={setSelectedProgram} setSelectedSchool={setSelectedSchool} />
    : (
      <>{loading && <LoadingIndicator />}

        <ErrorPopupIneligible ineligibleReason={ineligibleReasonForPopup}
          setIneligibleReason={setIneligibleReasonForPopup} setLoading={setLoading} />

        <form
          className="prequal__form"
          onSubmit={handleSubmit(submitForm)}>

          <div className="prequal__form--main-header-container">
            <div className="prequal__form--main-header">
              Get credit for your merit
            </div>

            <div className="prequal__form--main-sub-header">
              QUICK. EASY. GET STARTED NOW.
            </div>
          </div>

          <Infographic stage="1" title="" />

          <Grid
            className={classes.root}
            container
            spacing={0}>

            <Grid item xs={12}>
              <div className="prequal__form--section-header--primary">
                Tell us about yourself
              </div>

              <Grid
                container
                spacing={1}>

                <Grid
                  item
                  container
                  spacing={2}>

                  <Grid item xs={8}>
                    <TextField
                      id="first-name-field"
                      InputProps={{
                        ...inspectletInputClass,
                        className: 'prequal__first-name-input',
                      }}
                      helperText=" "
                      name="firstName"
                      label="First Name"
                      onChange={formChangeHandler}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      error={!!formErrors.middleName}
                      id="middle-name-field"
                      InputProps={{
                        ...inspectletInputClass,
                        className: 'prequal__middle-name-input',
                      }}
                      inputRef={register({
                        validate: {
                          value: (value) => {
                            if (!middleNameIsNotSuffix(value)) {
                              return 'Cannot be a suffix (Jr, Sr, etc)';
                            } else if (!middleNameNoSpecialCharacters(value)) {
                              return 'Cannot contain special characters';
                            } else {
                              return true;
                            }
                          }
                        }
                      })}
                      helperText={formErrors.middleName ? formErrors.middleName.message : ' '}
                      name="middleName"
                      label="Middle Name"
                      onChange={formChangeHandler}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="last-name-field"
                    InputProps={{ ...inspectletInputClass }}
                    helperText=" "
                    name="lastName"
                    label="Last Name"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={!!formErrors.email}
                    helperText={formErrors.email ? formErrors.email.message : ' '}
                    id="email-address-field"
                    InputProps={{ ...inspectletInputClass }}
                    inputRef={register({
                      validate: {
                        value: (value) => looksLikeEmail(value) || 'Invalid email address',
                      }
                    })}
                    label="Email"
                    name="email"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="phone-number-field"
                    InputProps={{ ...inspectletInputClass }}
                    inputProps={{ maxLength: 14, type: 'tel' }}
                    label="Phone Number"
                    name="phoneNumber"
                    onChange={formPhoneChangeHandler}
                    style={{ width: '100%' }}
                    value={formInputs.phoneNumber}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className="prequal__form--section-header">
                Address
              </div>

              <Grid
                container
                spacing={2}>

                <Grid item xs={12} sm={8}>
                  <TextField
                    error={!!formErrors.addressLine1}
                    helperText={formErrors.addressLine1 ? formErrors.addressLine1.message : ' '}
                    id="address1-field"
                    InputProps={{ ...inspectletInputClass }}
                    inputRef={register({
                      maxLength: { value: 35, message: "Address should be under 35 characters" }
                    })}
                    label="Enter a US street address*"
                    name="addressLine1"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <TextField
                    error={!!formErrors.addressLine2}
                    helperText={formErrors.addressLine2 ? formErrors.addressLine2.message : ' '}
                    id="address2-field"
                    InputProps={{ ...inspectletInputClass }}
                    inputRef={register({
                      maxLength: { value: 35, message: "Address should be under 35 characters" }
                    })}
                    label="Apt/Unit/PO Box"
                    name="addressLine2"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <TextField
                    error={!!formErrors.city}
                    helperText={formErrors.city ? formErrors.city.message : ' '}
                    id="city-field"
                    InputProps={{ ...inspectletInputClass }}
                    inputRef={register({
                      maxLength: { value: 25, message: "City should be under 25 characters" }
                    })}
                    label="City"
                    name="city"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid
                  className="form_prequal__dropdown-control"
                  item xs={5} sm={2}>
                  <FormControl
                    className="state-selector__control"
                    style={{ width: '100%' }}>
                    <InputLabel htmlFor="state-selector">State</InputLabel>
                    <Select
                      native
                      value={selectedState}
                      onChange={stateChangeHandler}
                      name="state"
                      inputProps={
                        {
                          'id': 'state-selector',
                          'data-testid': 'state-selector',
                        }
                      }>
                      <option hidden value="" />
                      {
                        states != null && states.length > 0 &&
                        states.map((state) =>
                          <option key={state.stateCode} value={state.stateCode}
                            data-testid={`state-option-${state.stateCode}`}>
                            {state.stateName}
                          </option>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={7} sm={4}>
                  <TextField
                    error={!!formErrors.zip}
                    helperText={formErrors.zip ? formErrors.zip.message : ' '}
                    id="zip-field"
                    InputProps={{ ...inspectletInputClass }}
                    inputProps={{ maxLength: 5 }}
                    inputRef={register({
                      minLength: { value: 5, message: "Zipcode should be 5 digits" },
                      pattern: {
                        value: /^[0-9+]*$/i, message: "Zipcode should only be numbers"
                      }
                    })}
                    label="Zip"
                    name="zip"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>


              <div className="form-worldMap__container--desktop">
                <img src={worldMap} alt="World Map"
                  className="form-worldMap__image--desktop"
                />
                <img src={worldMapMobile} alt="World Map"
                  className="form-worldMap__image--mobile"
                />
              </div>

            </Grid>

            <Grid item xs={12}>
              <div className="prequal__form--section-header">
                Additional info
              </div>

              <Grid
                container
                spacing={1}>

                <Grid
                  item xs={12}
                  className={classes.dateOfBirth}>
                  <KeyboardDatePicker
                    data-testid="prequal-dob-field"
                    disableToolbar
                    format="MM/dd/yyyy"
                    id="date-of-birth-field"
                    InputProps={{ ...inspectletInputClass }}
                    initialFocusedDate={null}
                    invalidDateMessage="Invalid date. Enter valid mm/dd/yyyy"
                    keyboardIcon={false}
                    KeyboardButtonProps={{ 'disabled': true }}
                    label="Date of Birth"
                    onChange={formDateChangeHandler}
                    onError={handleDateError}
                    maxDate={subDays(new Date(), 1)}
                    placeholder="mm/dd/yyyy"
                    style={{ width: '100%' }}
                    value={formInputs.dateOfBirth}
                    variant="inline"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="ssn-field"
                    type={showSsn ? "text" : "password"}
                    inputProps={showSsn ? { maxLength: 11, "data-testid": "ssn-field-testid" } : {
                      maxLength: 9,
                      "data-testid": "ssn-field-testid"
                    }}
                    helperText=" "
                    InputProps={{
                      ...inspectletInputClass,
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            data-testid="prequal-form-ssn-icon-btn"
                            aria-label="toggle ssn visibility"
                            onClick={() => {
                              setShowSsn(!showSsn);
                            }}
                            edge="end"
                            size="small"
                          >
                            {showSsn ? <VisibilityOff data-testid="prequal-form-visibility-off" /> :
                              <Visibility data-testid="prequal-form-visibility-on" />}
                          </IconButton>
                        </InputAdornment>
                    }}
                    label="9 digit SSN or ITIN"
                    name="ssn"
                    onChange={formSsnChangeHandler}
                    style={{ width: '100%' }}
                    value={formInputs.ssn}
                  />
                </Grid>
              </Grid>
            </Grid>

            {
              selectedState === 'WI'
              && !!!Environment.featureFlagEnabled('disable-wi-spouse')
              && <MarriedInfo
                formInputs={formInputs}
                updateFormInputs={updateFormInputs}
              />
            }

            <Grid item xs={12}>
              <div className="prequal__form--section-header--primary">
                Tell us about your school
              </div>

              <Grid
                container
                spacing={1}>

                <Grid
                  className="form_prequal__dropdown-control"
                  item xs={12}>
                  <Autocomplete
                    id="schools-combo-box"
                    data-testid="schools-combo-box"
                    getOptionLabel={getSchoolName}
                    value={selectedSchool}
                    disabled={schools.length === 1}
                    onChange={onSchoolChanged}
                    options={schools}
                    renderInput={SchoolAutocompleteInput}
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid
                  className="form_prequal__dropdown-control"
                  item xs={12}>
                  <Autocomplete
                    id="campus-combo-box"
                    disabled={campuses.length === 0}
                    options={campuses}
                    getOptionLabel={getCampusName}
                    value={selectedCampus}
                    style={{ width: '100%' }}
                    onChange={onCampusChanged}
                    renderInput={CampusAutocompleteInput}
                  />
                </Grid>

                <Grid
                  className="form_prequal__dropdown-control"
                  item xs={12}>
                  <Autocomplete
                    id="program-combo-box"
                    disabled={programs.length === 0}
                    options={programs}
                    getOptionLabel={getProgramName}
                    value={selectedProgram}
                    onChange={onProgramChanged}
                    style={{ width: '100%' }}
                    renderInput={ProgramAutocompleteInput}
                  />
                </Grid>

                {errors.user && errors.user.program &&
                  <CriticalError message={errors.user.program[0]} />
                }


                <Grid item xs={12}>
                  <TextField
                    id="loan-amount-field"
                    disabled={!loanAmountEnabled}
                    helperText={(!!selectedProgram && !!maximumLoanAmount && loanAmountEnabled) && `You may request between $${minimumLoanAmount} and $${maximumLoanAmount}`}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    inputProps={{ maxLength: 7 }}
                    label="Loan Amount"
                    name="loanAmount"
                    onChange={formChangeHandler}
                    style={{ width: '100%' }}
                    value={formInputs.loanAmount}
                  />
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <DisclosuresCheckbox
                    color="primary"
                    data-testid="disclosures-checkbox"
                    name="checkedB"
                    onChange={(event) => setDisclosuresChanged(event.target.checked)}
                    style={{
                      transform: "scale(1.5) translateY(7px)",
                      paddingInline: "1rem 1.5rem",
                    }}
                  />
                }
                label={
                  <div className="prequal__disclosures">
                    *I have read, understood and consent to the language and authorizations in the Meritize&nbsp;
                    <a href="/legal/esign_consent.html" target="_blank"
                      rel="noopener noreferrer">ESIGN Consent</a>,&nbsp;
                    <a href="/legal/call_sms_consent.html" target="_blank"
                      rel="noopener noreferrer">Consent to Receive Calls, SMS Text Messages, and Emails</a>,&nbsp;
                    <a href="/legal/meritize_privacy_notice.html" target="_blank"
                      rel="noopener noreferrer">Meritize Privacy Notice</a>,&nbsp;
                    <a href="/legal/credit_report_authorization.html" target="_blank"
                      rel="noopener noreferrer">Credit Pull Authorization</a>, and&nbsp;
                    <a href="/legal/terms_and_conditions.html" target="_blank"
                      rel="noopener noreferrer">Terms of Service</a>.
                  </div>
                }
                style={{ alignItems: "start" }} />

              {errors.user && errors.user.global &&
                <FormError messages={errors.user.global} />
              }

              {errors.server &&
                <CriticalError message={errors.server} />
              }

              <div className="prequal__check-rates-button-container">
                <Button
                  className="prequal__check-rates-button"
                  color="primary"
                  data-testid={"check-rates-button"}
                  disabled={!disclosuresIsChecked || !!dateOfBirthError || loading}
                  type="submit"
                  variant="contained">
                  Start application
                </Button>
              </div>

              <div className="prequal__credit-pull-warning">
                Checking your rates won’t affect your credit score
              </div>

              <div className="prequal__financial-account-info-header">
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
              </div>
              <div className="prequal__financial-account-info">
                To help the government fight the funding of terrorism and money laundering activities, Federal law
                requires
                all financial institutions to obtain, verify, and record information that identifies each person who
                opens
                an account. What this means for you: When you open an account, we will ask for your name, address, date
                of
                birth and other information that will allow us to identify you. We may also ask to see your driver’s
                license
                or other identifying documents.
              </div>

            </Grid>
          </Grid>
        </form>
      </>
    );
};

export default withHeader(backButtonRedirect(PrequalificationForm), { goHomeEnabled: true });

import React from 'react';
import 'components/header/infographic/Infographic.scss';
import infographicImg_01_active from "assets/images/infographic/flow-basicinfo-2.svg";
import infographicImg_02 from "assets/images/infographic/flow-merit-1.svg";
import infographicImg_02_active from "assets/images/infographic/flow-merit-2.svg";
import infographicImg_03 from "assets/images/infographic/flow-review-1.svg";
import infographicImg_03_active from "assets/images/infographic/flow-review-2.svg";
import infographicImg_04 from "assets/images/infographic/flow-submit-1.svg";
import infographicImg_04_selected from "assets/images/infographic/flow-submit-1b.svg";
import infographicImg_04_active from "assets/images/infographic/flow-submit-2.svg";

import infographicImg_01_mobile_active from "assets/images/infographic/flow-mobile-basicinfo-2.svg";
import infographicImg_02_mobile from "assets/images/infographic/flow-mobile-merit-1.svg";
import infographicImg_02_mobile_active from "assets/images/infographic/flow-mobile-merit-2.svg";
import infographicImg_03_mobile from "assets/images/infographic/flow-mobile-review-1.svg";
import infographicImg_03_mobile_active from "assets/images/infographic/flow-mobile-review-2.svg";
import infographicImg_04_mobile from "assets/images/infographic/flow-mobile-submit-1.svg";
import infographicImg_04_mobile_selected from "assets/images/infographic/flow-mobile-submit-1b.svg";
import infographicImg_04_mobile_active from "assets/images/infographic/flow-mobile-submit-2.svg";

const Infographic = (props) => {
  const {stage, title} = props;

  function selectedOrActive(x, selectedImage, activeImage) {
    switch (x) {
      case "4":
        return selectedImage
      case "5":
        return activeImage
      default:
        console.error(`Infographic.js -> selectedOrActive: Unexpected image index ${x}'`)
    }
  }

  const images = [
    infographicImg_01_active,
    (stage < 2) ? infographicImg_02 : infographicImg_02_active,
    (stage < 3) ? infographicImg_03 : infographicImg_03_active,
    (stage < 4) ? infographicImg_04 : selectedOrActive(stage, infographicImg_04_selected, infographicImg_04_active)
  ]



  const images_mobile = [
    infographicImg_01_mobile_active,
    (stage < 2) ? infographicImg_02_mobile : infographicImg_02_mobile_active,
    (stage < 3) ? infographicImg_03_mobile : infographicImg_03_mobile_active,
    (stage < 4) ? infographicImg_04_mobile : selectedOrActive(stage, infographicImg_04_mobile_selected, infographicImg_04_mobile_active)
  ]

  const testId = "infographic-" + stage

  return (
    <div className="form-infographic__container">
      <div className="form-infographic__title">{title.toUpperCase()}</div>
      <div className="form-infographic" data-testid={testId}>
        <img src={images[0]} alt="Infographic"
             className="form-infographic__image--desktop form-infographic__image--first"/>
        <img src={images[1]} alt="Infographic"
             className="form-infographic__image--desktop"/>
        <img src={images[2]} alt="Infographic"
             className="form-infographic__image--desktop"/>
        <img src={images[3]} alt="Infographic"
             className="form-infographic__image--desktop form-infographic__image--last"/>

        <img src={images_mobile[0]} alt="Infographic"
             className="form-infographic__image--mobile form-infographic__image--first"/>
        <img src={images_mobile[1]} alt="Infographic"
             className="form-infographic__image--mobile"/>
        <img src={images_mobile[2]} alt="Infographic"
             className="form-infographic__image--mobile"/>
        <img src={images_mobile[3]} alt="Infographic"
             className="form-infographic__image--mobile form-infographic__image--last"/>
      </div>
    </div>
  );
};

export default Infographic;

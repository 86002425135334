import React from "react";
import './ErrorPageAddressOutsideUs.scss';
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import addressErrorImg from "assets/images/address-error.svg"

function ErrorPageAddressOutsideUs(props) {
  return (
    <div className="address-error__outer-container">
      <div className="address-error__content-container">
        <img alt="US Addresses Only" src={addressErrorImg} className="address-error__main-img" />
        <a href="/" className="address-error__try-again-link">TRY AGAIN</a>
      </div>
    </div>
  );
}

export default logCurrentPage(withHeader(backButtonRedirect(ErrorPageAddressOutsideUs)), 'error');

import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import './FullApplicationForm.scss';
import ServerRequests from '../../service/ServerRequests';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {format, isValid} from 'date-fns';
import {KeyboardDatePicker} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import {logSentryError, parseErrors} from 'service/errors';
import CallIcon from '@material-ui/icons/Call';
import CommentIcon from '@material-ui/icons/Comment';
import {backButtonRedirect} from 'components/hoc/BackButtonRedirect/BackButtonRedirect';
import ErrorMessage from 'components/errorMessage/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import withHeader from 'components/header/WithHeader';
import LoanInfoCard from 'components/LoanInfoCard/LoanInfoCard';
import Infographic from "components/header/infographic/Infographic";

function FullApplication(props) {

  const errorStrings = {
    'invalid-start-date': 'Enter a valid Start Date',
    'end-of-road-status': 'Stale data detected, please refresh the page'
  };

  const [error, handleError] = useState(null);
  const [formInputs, handleFormInputs] = useState({
    startDate: null,
  });
  const [loading, setLoading] = useState(false);
  const {history} = props;
  const applicationId = props.location.state.applicationId;
  const loanInfo = props.location.state.loanInfo;

  function submitForm() {
    setLoading(true);
    ServerRequests.submit('/submit-full-application',
      {
        data: {
          startDate: formatDate(formInputs.startDate),
          applicationId: applicationId,
        },
      }
    )
      .then(() => {
        history.push('/disclosures', {applicationId: applicationId,});
      })
      .catch(error => {
        setLoading(false);
        if (error.response === undefined || error.response.status !== 422) {
          logSentryError(error);
          handleError({type: 'server'});
        } else {
          handleError({type: 'user', messages: parseErrors(error.response.data.errors, errorStrings)});
        }
      });
  }

  const formDateChangeHandler = (date) => {
    handleFormInputs({
      ...formInputs,
      startDate: date
    });
  };

  function formatDate(date) {
    if (date !== null && isValid(date)) {
      return format(date, 'yyyy-MM-dd').toString();
    }
    return null;
  }

  useEffect(() => {
    if (!!loanInfo) {
      ServerRequests.submitCurrentPage(applicationId, 'apply')
        .catch(error => {
          logSentryError(error);
        });
    } else {
      ServerRequests.submitCurrentPage(applicationId, 'apply_manual_eval')
        .catch(error => {
          logSentryError(error);
        });
    }

  }, [applicationId, loanInfo]);

  return (
    <>
      {loading && <LoadingIndicator/>}

      <Infographic stage="3" title="Verify Loan Details" />

      <form className="fullapp__form">
        <div>
          {loanInfo &&
          <Box>

            <LoanInfoCard loanInfo={loanInfo}></LoanInfoCard>

            <div className="fullapp__contact-text">
              To make changes to any of this information, contact Customer Support:
            </div>
            <Grid container className="fullapp__contact-info">
              <Grid item xs={12} className="fullapp__left-align">
                <CallIcon className="fullapp__contact-icon"/>833-637-4848
              </Grid>
              <Grid item xs={12} className="fullapp__left-align">
                <CommentIcon className="fullapp__contact-icon"/>apply@meritize.com
              </Grid>
            </Grid>
          </Box>
          }
        </div>

        <Box>
          <p className={!!loanInfo ? 'fullapp__main-header fullapp__main-header__padding-top' : 'fullapp__main-header'}>
            Verify your information
          </p>

          <Grid
            container
            spacing={3}
          >

            <Grid item xs={12}>
              <div className="fullapp__start-date-header">
                Estimated start date of the program
              </div>
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                id="start-date-field"
                inputProps={{'aria-describedby': 'start-date-description'}}
                initialFocusedDate={null}
                invalidDateMessage="Invalid start date"
                label="Starting On†"
                onChange={formDateChangeHandler}
                placeholder="mm/dd/yyyy"
                style={{width: '100%'}}
                value={formInputs.startDate}
                variant="inline"
                autoOk={true}
              />
              <FormHelperText id="start-date-description">†Best guess of when the classes would start</FormHelperText>
            </Grid>

            <Grid item xs={12}>
              {(error) &&
              <ErrorMessage type={error.type} messages={error.messages}/>
              }

              <div className="fullapp__submit-button-container">
                <Button
                  className="fullapp__submit-button"
                  data-testid={'fullApp-continue-button'}
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={submitForm}>
                  CONTINUE
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}

export default withHeader(backButtonRedirect(FullApplication));

import * as React from "react";
import './TranscriptUploader.scss';
import Icon from "@material-ui/core/Icon";
import instructionsSvg from "assets/images/sendTranscript/transcript-uploader-instructions.svg";


const TranscriptUploaderInstructions = props => {
  return <Icon >
      <img data-testid={"dropAreaIconTestId"} src={
        instructionsSvg
      } alt="Drop transcript here"/>
    </Icon>
}

export default TranscriptUploaderInstructions;

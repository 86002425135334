import React from "react";
import "components/CalTextField/CalTextField.scss";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import backgroundStar from "assets/images/star_sprite.svg";

// Let's try to stay consistent with the builtin MUI TextField API, when we can.
// Refer to https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TextField/TextField.js

const CalTextField = (props) => {
  const {
    id,
    bigLabel,
    inputProps = {},
    bigLabelProps,
    className,
    labelClassName,
    textboxClassName,
    onChange,
    label,
    inputLabelId,
    InputLabelProps,
    value,
    errorText,
    errorTextId,
    errortextClassName,
    helperText,
    helperTextId,
    helpertextClassName,
    FormHelperTextProps,
    placeholder,
    shortInput = false,
    ...otherProps
  } = props;

  inputProps.className = (inputProps.className || '') + " " + ( textboxClassName ? textboxClassName : 'c-text-field__input' ) ;
  let fullClassName = (className || '') + " c-text-field";
  let labelClass = labelClassName ? labelClassName : 'c-text-field__label';
  let helpertextClass = helpertextClassName ? helpertextClassName : '';
  let errortextClass = errortextClassName ? errortextClassName : '';

  const blurHandler = event => {
    if(event.target.value && !errorText){
      event.target.parentNode.parentNode.previousSibling.classList.add('play')
    } else {
      event.target.parentNode.parentNode.previousSibling.classList.remove('play')
    }
  };

  return (
    <FormControl
      className={fullClassName}
      style={{display: 'block'}}
      {...otherProps}
    >
      {bigLabel &&
        <>
          <label htmlFor={id}
                 className={labelClass}
                 style={{display: 'inline-flex'}}
                 {...bigLabelProps}
          >{bigLabel}</label>
          <div className="shapeshifter" style={{backgroundImage: 'url('+backgroundStar+')'}}></div>
        </>
      }
      <div className={`c-text-field__input-container ${shortInput ? 'c-text-field__input-container--medium' : ''}`}>
        {label && (
          <InputLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
            {label}
          </InputLabel>
        )}
        <Input
          aria-describedby={helperTextId}
          id={id}
          inputProps={inputProps}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          style={{width: '100rem', maxWidth: '100%'}}
          onBlur={blurHandler}
        />
        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps} className={helpertextClass}>
            {helperText}
          </FormHelperText>
        )}
        {errorText && (
          <FormHelperText id={errorTextId} {...FormHelperTextProps} className={errortextClass}>
            {errorText}
          </FormHelperText>
        )}
      </div>
    </FormControl>
  );
};

export default CalTextField;

import React, {useEffect, useState} from "react";
import './ExistingApp.scss';
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import {string} from "prop-types";
import Grid from "@material-ui/core/Grid";
import CallIcon from "@material-ui/icons/Call";
import CommentIcon from "@material-ui/icons/Comment";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import ServerRequests from "service/ServerRequests";
import {logSentryError} from "service/errors";

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: "#e9f5fd"
    }
  },
}));

const ExistingApp = props => {

  let applicationId = props.location.state.applicationId;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showTranscriptUpload, handleShowTranscriptUpload] = useState(false);

  useEffect(() => {
    ServerRequests.showTranscriptUploader(props.location.state.applicationId).then((response) => {
      handleShowTranscriptUpload(response.data);
    }).catch(error => {
      logSentryError(error);
    });
  }, [props.location.state.applicationId]);

  return (
    <>
      {
        loading && <LoadingIndicator/>
      }
      <div className={classes.root + " existing_app__background"}>
        <div className="existing_app__body">
          <div className="existing_app__text--primary">
            It looks like you have a previously submitted application.
          </div>
          <div className="existing_app__text--app_id">
            App ID: {applicationId}
          </div>
          <div className="existing_app__text--secondary">
            {props.location.state.status !== "Declined"
              ? "It is currently pending and under review."
              : "We welcome you to re-apply for any of our other products, but please wait at least 30 days from the date you received your decline letter before submitting a new application."
            }
          </div>
          <div className="existing_app__text--secondary">
            If you have any questions about your application in progress, please contact customer support.
          </div>
          <Grid container className="existing_app__contact-info">
            <Grid item xs={6} className="existing_app__right-align">
              <CallIcon className="existing_app__contact-icon"/>833-637-4848
            </Grid>
            <Grid item xs={6} className="existing_app__left-align">
              <CommentIcon className="existing_app__contact-icon"/>apply@meritize.com
            </Grid>
          </Grid>
          {/* TODO: https://meritize.atlassian.net/browse/APP-1505 */}
          {/*{*/}
          {/*  (!!showTranscriptUpload && status === "Pending Verification") &&*/}
          {/*  <>*/}
          {/*    <div className="existing_app__what-to-do-section">*/}
          {/*      <div>*/}
          {/*        <div className="existing_app__section-header">*/}
          {/*          Here’s what we need:*/}
          {/*        </div>*/}
          {/*        <div className="existing_app__sub-text">In order to prevent delays in processing your application,*/}
          {/*          upload your transcript or military record (DD214) here.*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <img className="existing_app__upload-img" alt="Upload Transcripts" src={UploadTranscripts}/>*/}
          {/*    </div>*/}
          {/*    <div className="existing_app__upload-text">*/}
          {/*      {getUploadMsgFromPath()}*/}
          {/*    </div>*/}
          {/*    <TranscriptUploader applicationId={applicationId} setLoading={setLoading}/>*/}
          {/*  </>*/}
          {/*}*/}
        </div>
      </div>
    </>
  );
}

ExistingApp.propTypes = {
  status: string,
};

export default logCurrentPage(withHeader(backButtonRedirect(ExistingApp), { marginlessHeader: "all", marginlessPageWrapper: "mobile" }), 'existing_application');

import React from "react";
import ServerRequests from "service/ServerRequests";
import {logSentryError} from "service/errors";

const logCurrentPage = (WrappedComponent, currentPage) => {

  return class extends React.Component {
    componentDidMount() {
      // if (!!this.props?.state) {
        const applicationId = this.props.location?.state.applicationId || this.props.applicationId
        if (!!applicationId) {
          ServerRequests.submitCurrentPage(applicationId, currentPage)
            .catch(error => {
              logSentryError(error);
            });
        }
      // }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
};

export default logCurrentPage;



import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import "components/errorMessage/Popup.scss";
import { useWindowSize } from 'react-use';


const Popup = (props) => {
  const {
    icon,
    headers,
    descriptions,
    buttonLabel,
    handleConfirm
  } = props;

  const handleClose = () => {
    // We do not want the user to be able to manually close this popup
    // Thus we do absolutely nothing like good devs
  };

  const display = !!icon() && !!headers() && !!descriptions() && !!buttonLabel() && !!handleConfirm

  const desktopMinWidth = 768
  const isMobile = useWindowSize().width < desktopMinWidth || !!props?.forceMobileOnJest

  const paperPropsStyleMobile = {
    margin: "1rem",
    padding: "0rem 0.7rem 0rem 0.7rem",
    maxWidth: '100%',
    width: '100%',
    borderRadius: 12,
    boxShadow: '0px 0px 25px 3px rgb(0 0 0 / 12%)',
  }

  const paperPropsStyleDesktop = {
    margin: 'auto',
    padding: '0rem 3rem 0rem 3rem',
    maxWidth: '44rem',
    width: 'auto',
    borderRadius: 12,
    boxShadow: '0px 0px 25px 3px rgb(0 0 0 / 12%)',
  }

  return (display && 
    <Dialog open={display}
      onClose={handleClose}
      data-testid="error-popup-dialog"
      fullWidth
      maxWidth={false}
      BackdropProps={{
        style: {
          opacity: 1,
        }
      }}
      PaperProps={{
        className: 'error-message-popup-paper-prop',
        style: isMobile ? paperPropsStyleMobile : paperPropsStyleDesktop
      }}
    >
      <DialogContent className='error-message-popup--dialog-content'>
        <div className={`error-message-popup`}>
          <div className={`error-message-popup__image-container`}>
            <img className={`error-message-popup__image`} src={icon()} alt="Error Icon" />
          </div>
          <div className={`error-message-popup__inner-div`}>
            <h3 className={`error-message-popup__header`}>
              {
                headers().map((header, index) => {
                  return (
                    <div data-testid="error-msg" key={index}>
                      {header}
                    </div>
                  )
                })
              }
            </h3>
            {
              descriptions().map((description, index) => (
                <div className={`error-message-popup__error-description`} key={index}>
                  {description}<br />
                </div>
              ))
            }
            <div className={`error-message-popup__error-description`}>
              833-637-4848
              <br />
              help@meritize.com
            </div>
            <Button
              className="error-message-popup__button"
              color="primary"
              variant="contained"
              onClick={handleConfirm}
            >{buttonLabel()}</Button>
          </div>
        </div>

      </DialogContent>
    </Dialog>
  );
}

export default Popup;

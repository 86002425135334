import React, {useState} from 'react';
import 'components/header/css/Header.scss';
import MaintenanceBanner from "components/header/MaintenanceBanner";
import Environment from "util/Environment";
import UpcomingMaintenanceDialog from "components/UpcomingMaintenanceMessage/UpcomingMaintenanceMessage";
import {getCurrentDate} from "util/DateHelper";

const Header = (props) => {
  const {goHomeEnabled} = props;
  const [showUpcomingMaintenanceDialog, handleOpenUpcomingMaintenanceDialog] = useState(goHomeEnabled);

  let openUpcomingMaintenanceDialog = () => {
    handleOpenUpcomingMaintenanceDialog(true)
  }

  let handleGoHome = () => {
    if (goHomeEnabled) {
      window.location.replace(window.location.pathname);
    }
  }

  const isWinter = () => {
    const dt = getCurrentDate()
    const month = dt.getMonth() + 1;
    const day = dt.getDate()

    return month === 12
      ? day >= 15
      : month === 3
        ? day < 21
        : month < 3;
  }

  return (
    <>
        { !!Environment.upcomingMaintenanceDatetime() ?
        <div className={props.headerClass} data-testid="meritize-logo-header">
              <div className="header-content">
                  <div>
                    <MaintenanceBanner handleGoHome={handleGoHome} handleShowUpcomingMaintenanceDialog={openUpcomingMaintenanceDialog} />
                    <UpcomingMaintenanceDialog open={showUpcomingMaintenanceDialog} setOpen={handleOpenUpcomingMaintenanceDialog} />
                  </div>
              </div>
            </div>
          : isWinter() ?
          <div className={props.headerClass} data-testid="meritize-logo-header">
              <div className="header-content">
                <img onClick={handleGoHome} className="header-logo" alt="Meritize Winter Logo" data-testid="meritize-logo-img"
                  src={require('assets/images/seasonalBanners/meritize-wordmark-winter.svg')}/>
              </div>
            </div>
            :
          <div className={props.headerClass} data-testid="meritize-logo-header">
                <div className="header-content">
                  <img onClick={handleGoHome} className="header-logo" alt="Meritize Logo" data-testid="meritize-logo-img"
                    src={require('assets/images/meritize-logo.svg')}/>
                </div>
              </div>
        }
    </>
  );
};

export default Header;

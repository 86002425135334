import React, {useState} from 'react';
import './academic.scss';
import {bool, number} from 'prop-types';
import logCurrentPage from 'components/hoc/LogCurrentPage/LogCurrentPage';
import ServerRequests from "service/ServerRequests";
import {logSentryError, parseErrors} from "service/errors";
import Button from "@material-ui/core/Button";
import CalRadioSelection from "components/CalRadioGroup/CalRadioSelection";
import CalTextField from "components/CalTextField/CalTextField";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withStyles} from "@material-ui/core";

const DisclosuresCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#3C89E8',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

let AcademicForm = props => {
  let {handleCompletion, setLoading, loading, applicationId, isHighSchool} = props;
  const errorStrings = {
    "cumulative-gpa-required": 'Enter a valid Cumulative GPA',
    "lowest-grade-required": 'Select a lowest grade',
    "number-of-years-required": 'Enter Years after high school',
    "total-credits-required": 'Enter total credits earned',
  };

  const [error, handleError] = useState(null);
  const [disclosuresIsChecked, handleDisclosuresChanged] = useState(false);
  const [formInputs, handleFormInputs] = useState({
    applicationId: applicationId,
    cumulativeGpa: '',
    lowestGrade: null,
    totalCreditsEarned: '',
    numberOfYears: ''
  });

  const handleSubmit = (event) => {
    setLoading(true);
    ServerRequests.submitAcademicDetails(formInputs)
      .then((response) => {
        handleCompletion(event);
      }).catch(error => {
        setLoading(false);
        if (error.response === undefined || error.response.status !== 422) {
          logSentryError(error);
          handleError({type: "server"});
        } else {
          handleError({type: "user", messages: parseErrors(error.response.data.errors, errorStrings)})
        }
      }
    );
    event.preventDefault();
  };

  const formStateChangeHandler = (fieldName) => {
    return (e) => handleFormInputs({
      ...formInputs,
      [fieldName]: sanitizeValue(e.target.value, fieldName),
    })
  };

  const lowestGradeChangeHandler = (fieldName) => {
    return (value) => handleFormInputs({
      ...formInputs,
      [fieldName]: sanitizeValue(value, fieldName),
    })
  };

  const sanitizeValueWidthRegexp = (value, fieldName, regexp) => {
    value = value.replace(/[,$]/g, '');
    let isnum = regexp.test(value);
    return isnum ? value : formInputs[fieldName];
  };

  const sanitizeValue = (value, fieldName) => {
    if ('cumulativeGpa' === fieldName) {
      return sanitizeValueWidthRegexp(value, fieldName, /^(\d(\.\d{0,2})?)?$/);
    }
    if ('numberOfYears' === fieldName) {
      return sanitizeValueWidthRegexp(value, fieldName, /^(\d\d?(\.\d?)?)?$/);
    }
    if ('totalCreditsEarned' === fieldName) {
      return sanitizeValueWidthRegexp(value, fieldName, /^(\d{0,3})?$/);
    }
    return value;
  };

  return (
    <>
      <div className="academic__form-container">
        <form onSubmit={handleSubmit}>

          <div className="merit-details__form-input-section">
            <CalTextField
              labelClassName="merit-details__form-input-label"
              textboxClassName="merit-details__form-input-textbox"
              helpertextClassName="merit-details__form-input-helpertext"
              id="cumulative-gpa-field"
              bigLabel="Cumulative GPA"
              placeholder="0.00"
              inputProps={{className: 'academic__input-text'}}
              FormHelperTextProps={{className: 'academic__input-text'}}
              shortInput={true}
              onChange={formStateChangeHandler("cumulativeGpa")}
              value={formInputs.cumulativeGpa}
              helperText="Round CGPA to 2 decimals"
            />
          </div>

          <div className="merit-details__form-input-section">
            <CalRadioSelection
              labelClassName="merit-details__form-input-label"
              textboxClassName="merit-details__form-input-textbox horizontally-spread-radio-button"
              data-testid="lowest-grade-field"
              id='lowest-grade-field'
              bigLabel="Lowest grade (regardless of retake)"
              onNewValueSelected={lowestGradeChangeHandler('lowestGrade')}
              value={formInputs.lowestGrade}
              values={['A', 'B', 'C', 'D', 'F']}
            />
          </div>

          {(!isHighSchool) &&
            <>
              <div className="merit-details__form-input-section">
                <CalTextField
                  labelClassName="merit-details__form-input-label"
                  textboxClassName="merit-details__form-input-textbox"
                  helpertextClassName="merit-details__form-input-helpertext"
                  id="total-credits-earned"
                  bigLabel="Total credits earned"
                  label="Credits"
                  shortInput={true}
                  inputProps={{className: 'academic__input-text'}}
                  InputLabelProps={{className: 'academic__input-text'}}
                  FormHelperTextProps={{className: 'academic__input-text'}}
                  onChange={formStateChangeHandler("totalCreditsEarned")}
                  value={formInputs.totalCreditsEarned}
                  helperText="Including transfer credits"
                />
              </div>
              <div className="merit-details__form-input-section">
                <CalTextField
                  labelClassName="merit-details__form-input-label"
                  textboxClassName="merit-details__form-input-textbox"
                  helpertextClassName="merit-details__form-input-helpertext"
                  id="number-of-years"
                  bigLabel="Years of education after highschool"
                  label="# of Years"
                  shortInput={true}
                  inputProps={{className: 'academic__input-text'}}
                  InputLabelProps={{className: 'academic__input-text'}}
                  FormHelperTextProps={{className: 'academic__input-text'}}
                  onChange={formStateChangeHandler("numberOfYears")}
                  value={formInputs.numberOfYears}
                  helperText="You can enter decimal values. If less than 1, use preceding 0"
                />
              </div>
            </>
          }



          <div className="merit-details__form-footer">
            <div className="merit-details__transcript-msg">
              {
                props.children
              }
            </div>

            {(!!error) &&
              <ErrorMessage type={error.type} messages={error.messages}/>
            }

            <FormControlLabel
              className='academic__disclosures-container'
              control={
                <DisclosuresCheckbox
                  className='academic__disclosures-checkbox'
                  color="primary"
                  name="checkedB"
                  onChange={(event) => handleDisclosuresChanged(event.target.checked)}
                  checked={disclosuresIsChecked}
                  style={{
                    transform: "scale(1.5) translateY(7px)",
                    paddingInline: "1rem 1.5rem",
                  }}
                />
              }
              label={
                <div className='academic__disclosures-label'>
                  *By checking this box, I authorize Meritize to use my academic records in my loan
                  decision.
                </div>
              }
            />
            <div className="merit-details__submit-button-container">
              <Button
                className="merit-details__submit-button"
                data-testid={"continue-button"}
                color="primary"
                type="submit"
                disabled={!disclosuresIsChecked || loading}
                variant="contained">
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

AcademicForm.propTypes = {
  isHighSchool: bool.isRequired,
  applicationId: number.isRequired,
};

export default logCurrentPage(AcademicForm, 'merit_details_academic');

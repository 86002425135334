import React from "react";
import Header from "components/header/Header";
import './css/WithHeader.scss'

const withHeader = (WrappedComponent, headerProps) => {

  return ((props) => {

    const getClassNames = (marginlessProp, baseCls, mobileCls, desktopCls) => {
      if (!marginlessProp)
        return baseCls

      let classNames = baseCls

      if (['mobile', 'all', true].includes(marginlessProp))
        classNames += ` ${mobileCls}`

      if (['desktop', 'all', true].includes(marginlessProp))
        classNames += ` ${desktopCls}`


      return classNames
    }

    const pageWrapperClass = getClassNames(
      headerProps?.marginlessPageWrapper,
      'page-wrapper',
      'page-wrapper-marginless-mobile',
      'page-wrapper-marginless-desktop'
    )

    const headerClass = getClassNames(
      headerProps?.marginlessHeader,
      'header',
      'meritize-marginless-header-mobile',
      'meritize-marginless-header-desktop',
    )



    return (
      <>
        <Header headerClass={headerClass} {...headerProps} />
        <div className={pageWrapperClass}>
          <WrappedComponent {...props} />
        </div>
      </>
    )
  });
}

export default withHeader;

import * as React from 'react';
import {useEffect, useState} from 'react';
import './Submitted.scss';
import CustomerSupport from '../../assets/images/submitted_customer-support.png';
import UploadTranscripts from '../../assets/images/submitted_upload-transcripts.png';
import TooFarOut from '../../assets/images/tooFarOut.svg';
import CallIcon from '@material-ui/icons/Call';
import CommentIcon from '@material-ui/icons/Comment';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import TranscriptUploader from "components/transcriptUploader/TranscriptUploader";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import ServerRequests from "service/ServerRequests";
import {logSentryError} from "service/errors";
import {format, parseISO} from "date-fns";
import LoanInfoCard from 'components/LoanInfoCard/LoanInfoCard';
import Grid from "@material-ui/core/Grid";
import Infographic from "components/header/infographic/Infographic";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:768px)': {
      maxWidth: '1024px'
    },
  },
}));

const Submitted = (props) => {
  const applicationId = props.location.state.applicationId;
  const loanInfo = props.location.state.loanInfo;
  const status = props.location.state.status;
  const path = props.location.state.path;
  const estimatedHardpullDate = props.location.state.estimatedHardpullDate;
  const estimatedStartDate = props.location.state.estimatedStartDate;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showTranscriptUpload, handleShowTranscriptUpload] = useState(false);

  const theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        }
      }
    }
  )

  const getUploadMsgFromPath = () => {
    switch (path) {
      case 'Military':
        return "Upload a military record (DD214) now";
      default:
        return "Upload a transcript now";
    }
  };

  useEffect(() => {
    ServerRequests.showTranscriptUploader(props.location.state.applicationId).then((response) => {
      handleShowTranscriptUpload(response.data);
    }).catch(error => {
      logSentryError(error);
    });
  }, [props.location.state.applicationId]);

  return (
    <>{
      loading && <LoadingIndicator/>
    }
      <Infographic stage="5" title="Complete" />

        <MuiThemeProvider theme={theme}>
          <div className="submitted__dynamic-header-container">

            <Grid container spacing={0} >
              <Grid item xs={12} sm={6} className="submitted__dynamic-img__container">
                <div className="submitted__dynamic-img animatedSubmittedStar animatedStar_desktop play_desktop" data-testid={"thankyou-img"}></div>
                <div className="submitted__dynamic-img animatedSubmittedStar animatedStar_mobile play_mobile" data-testid={"thankyou-img"}></div>
           </Grid>
              <Grid item xs={12} sm={6} className="submitted__dynamic-text" >

                <div className="submitted__dynamic__header">
                  Complete
                </div>
                <div className="submitted__dynamic__text--primary">
                  Your application was successfully submitted! Details have been sent to your email.
                </div>


              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>

      <div className={classes.root + " submitted__main-container"}>
        {!estimatedHardpullDate &&
          <>
          {
            (showTranscriptUpload && status === "Pending Verification")
            &&
            <>
              <div className="submitted__instruction-text">
                We'll need you to upload a transcript so we can verify your merit details.
              </div>
              <div className="submitted__instruction-note-text">
                NOTE: If we don't get your transcripts in 7 days we'll reevaluate your application, which may result in
                different offers or a change in decision.
              </div>
              <div data-testid="submitted-uploader">
                <div className="submitted__upload-text">
                  {getUploadMsgFromPath()}
                </div>
                <TranscriptUploader applicationId={applicationId} setLoading={setLoading}/>
              </div>
            </>
          }
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} className="">
            </Grid>

            <Grid item container xs={12} sm={12} md={5}>
              <Grid item xs={12}>
                <div className="submitted__section-header--right">
                  If you have any questions…
                  <br/>
                  Feel free to reach out!
                </div>
              </Grid>
              <Grid item xs={7} className="submitted__section-support-img">
                <img className="submitted__support-img" alt="Customer Support" src={CustomerSupport}/>
              </Grid>
              <Grid item xs={5} className="submitted__section-support-text">
                <div className="submitted__section-support-text-content">
                  Save our information to ensure you don't miss a call or email from us.
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="submitted__contact-info">
                  <CallIcon className="submitted__contact-icon"/>833-637-4848
                  <br/>
                  <CommentIcon className="submitted__contact-icon"/>apply@meritize.com
                  <br/>
                  <CommentIcon className="submitted__contact-icon"/>help@meritize.com
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
        }
        {estimatedHardpullDate &&
        <>
          <div className="submitted__section-too-far-header">
            We are working on it...
          </div>
          <div className="submitted__section-too-far-img">
            <img src={TooFarOut} alt="Too Far Out" style={{"width": "100%"}}/>
          </div>
          <div className="submitted__section-too-far-msg">
            We have saved your application but the estimated program start date you have provided is too far in the
            future.
          </div>
          <div className="submitted__section-div-too-far">
            <p className="submitted__section-too-far-text-bold">Estimated program start
              date: {format(parseISO(estimatedStartDate), 'MM-dd-yyyy')}</p>
            <p className="submitted__section-too-far-text">
              We will process your loan application, which includes a hard credit inquiry,
              on {format(parseISO(estimatedHardpullDate), 'MM-dd-yyyy')}
            </p>
          </div>

          {loanInfo && <LoanInfoCard loanInfo={loanInfo}/>}

          <div className="submitted__section-too-far-rates-disclaimer">
            <div className="submitted__section-too-far-rates-disclaimer-paragraph">
              Variable rates subject to change.
            </div>
            <div className="submitted__section-too-far-rates-disclaimer-paragraph">
              The offer(s) presented to you on the Meritize platform are estimates based in part on program details
              provided by your school. Once your school confirms the details of your enrollment, including, but not limited to, the estimated
              start date, length of program, final loan amount, estimated payment amount, payment dates, information
              reflected in your Promissory Note may be subject to change. All offer(s) are conditional and subject to
              verification of the information provided by you and your school.
            </div>
          </div>
          {
            (showTranscriptUpload && status === "Pending Verification")
            &&
            <div data-testid="submitted-uploader">
              <div className="submitted__what-to-do-section">
                <div>
                  <div className="submitted__section-header">
                    Here's what you can do:
                  </div>
                  <ol className="submitted__user-next-steps">
                    <li>Have your verification documents ready and digitize them.</li>
                    <li>In order to prevent delays in processing your application, upload your transcript or military
                      record
                      (DD214) here.
                    </li>
                  </ol>
                </div>
                <img className="submitted__upload-img" alt="Upload Transcripts" src={UploadTranscripts}/>
              </div>
              <div className="submitted__upload-text">
                {getUploadMsgFromPath()}
              </div>
              <TranscriptUploader applicationId={applicationId} setLoading={setLoading}/>
            </div>
          }
        </>
        }
      </div>
    </>
  );
};

export default logCurrentPage(withHeader(backButtonRedirect(Submitted)), 'confirmation');

import Grid from '@material-ui/core/Grid';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ForwardIcon from '@material-ui/icons/Forward';
import "./LoanInfoCard.scss"


export default function OfferCard(props) {
  return (

      <Card variant="outlined" className="loaninfo_card__offer-card" >
        <CardContent style={{'padding': '0'}}>
          <div>

            <Grid container style={{'height': '70px'}}>
              <Grid item xs={12} className="loaninfo_card__offer-title-container">
                <span className="loaninfo_card__card-title">{props.loanInfo.loanTermMonths}-month</span>
                <div className="loaninfo_card__titles-option-helper">
                  REPAYMENT TERM
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{'height': '56px'}} className="loaninfo_card__payment-title">
              <Grid item xs={5} className="loaninfo_card__left-align">
                <div className="loaninfo_card__column-headings">
                  Payments in school
                </div>
              </Grid>

              <Grid item xs={7} className="loaninfo_card__right-align">
                <div className="loaninfo_card__column-headings">
                  Payments after graduating
                </div>

                <div className="loaninfo_card__titles-option-helper">
                  FULL PRINCIPAL & INTEREST
                </div>
              </Grid>
            </Grid>

            <div className="loaninfo_card__repayment-option-container">
              <Grid container alignItems="center" className="loaninfo_card__repayment-option-row1">
                <Grid item xs={6}>
                  <div className="loaninfo_card__repayment-option-helper loaninfo_card__repayment-option-helper--left"
                    style={{"lineHeight": "2rem"}}>
                    {props.loanInfo.repaymentOption === 'FD' && 'IN-SCHOOL DEFERMENT'}
                    {props.loanInfo.repaymentOption === 'IOP' && 'INTEREST ONLY PAYMENTS'}
                    {props.loanInfo.repaymentOption === 'FP' && 'FULL PRINCIPAL AND INTEREST'}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="loaninfo_card__repayment-option-helper loaninfo_card__repayment-option-helper--right">
                    {props.loanInfo.interestRateType === 'fixed' ? 'FIXED APR' : 'VARIABLE APR'} {!!props.loanInfo.apr && props.loanInfo.apr.toFixed(2)}%
                  </div>
                </Grid>
              </Grid>

              <Grid container className="loaninfo_card__payment-option-container">
                <Grid item xs={5}>
                  <div className="loaninfo_card__payment-text loaninfo_card__left-align">
                    ${props.loanInfo.paymentsInSchool.toFixed(2)}{props.loanInfo.paymentsInSchool > 0 && '/month'}
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <ForwardIcon style={{ fill: '#63B4E4' }}/>
                </Grid>

                <Grid item xs={5}>
                  <div className="loaninfo_card__payment-text loaninfo_card__right-align">
                    ${props.loanInfo.paymentsAfterGrad.toFixed(2)}/month
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </CardContent>
      </Card>
  );
}

import React from 'react';
import './LoadingIndicator.scss';
import LoadingIcon from '../../assets/images/meritize-loading-indicator.gif';
import Box from "@material-ui/core/Box";
import {Modal} from "@material-ui/core";

let LoadingIndicator = () => {
  return (
      <Modal open={true}
             BackdropProps={{style: {backgroundColor: 'rgba(255, 255, 255, 0.75)'}}}
      >
        <Box className="loading-indicator__container" data-testid="main__loading-indicator" color="text.primary">
          <img className="loading-indicator__icon" alt="Meritize-Loading" src={LoadingIcon}/>
        </Box>
      </Modal>
  );
}

export default LoadingIndicator;

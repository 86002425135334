import React, { useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CalRadioSelection from "components/CalRadioGroup/CalRadioSelection";
import "components/CalRadioGroup/CalRadioSelection.scss";

const MarriedInfo = (props) => {

  const {
    updateFormInputs,
    formInputs,
  } = props;


  const fieldMaritalStatus = "maritalStatus"
  const fieldSpouseName = "spouseName"
  const fieldSpouseAddress = "spouseAddress"



  const inspectletInputClass = { 'classes': { 'input': "inspectlet-sensitive" } };

  const onMaritalStatusRadioChange = useCallback(
    (newStatus) => {
      updateFormInputs({ [fieldMaritalStatus]: newStatus })

      if (newStatus === "Unmarried")
        updateFormInputs({
          [fieldSpouseName]: null,
          [fieldSpouseAddress]: null
        })
    }
    , [updateFormInputs]);

  const onTextInputChange = useCallback(
    (e) => {
      const { name, value } = e.target
      updateFormInputs({ [name]: value });
    }, [updateFormInputs]);


  const clearFields = () => {
    updateFormInputs({
      [fieldMaritalStatus]: null,
      [fieldSpouseName]: null,
      [fieldSpouseAddress]: null,
    })
  }

  // when component unmount
  useEffect(() => clearFields, [])

  return (
    <>
      <Grid item xs={12}>
        <div className="prequal__form--section-header--primary">
          Notice to Wisconsin Residents
        </div>

        <CalRadioSelection
          excludeStarIcon={true}
          bigLabel="Please Indicate:"
          name={fieldMaritalStatus}
          onNewValueSelected={onMaritalStatusRadioChange}
          value={formInputs.maritalStatus}
          labels={['Married', 'Unmarried (includes single, divorced, widowed)', 'Separated']}
          values={['Married', 'Unmarried', 'Separated']} />

        {(!!formInputs.maritalStatus &&
          formInputs.maritalStatus !== 'Unmarried') &&
          <div>
            <TextField
              id="spouse-name-field"
              InputProps={{
                ...inspectletInputClass,
                className: 'prequal__spouse-name-input',
              }}
              helperText=" "
              name={fieldSpouseName}
              label="Spouse's Name"
              onChange={onTextInputChange}
              style={{ width: '100%' }} />
            <TextField
              id="spouse-address-field"
              InputProps={{
                ...inspectletInputClass,
                className: 'prequal__spouse-address-input',
              }}
              helperText=" "
              name={fieldSpouseAddress}
              label="Spouse's Mailing Address"
              onChange={onTextInputChange}
              style={{ width: '100%' }} />

            <div>
              NOTICE TO MARRIED APPLICANTS: No provision of a marital property agreement, a unilateral statement
              under s. 766.59 or a court decree under s. 766.70 adversely affects the interest of the creditor
              unless the creditor, prior to the time credit is granted, is furnished a copy of the agreement,
              statement or decree or has actual knowledge of the adverse provision when the obligation to the
              creditor is incurred.
            </div>
          </div>
        }
      </Grid>
    </>)
}


export default MarriedInfo;

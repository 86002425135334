import JSZip from 'jszip';
import {DropzoneArea} from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import * as React from "react";
import {useState} from "react";
import ServerRequests from "service/ServerRequests";
import axios from "axios";
import './TranscriptStandaloneUploader.scss';
import folderSvg from "assets/images/sendTranscript/folder-icon.svg";
import {logSentryError} from "service/errors";
import TranscriptStandaloneUploaderInstructions
  from "components/SendTranscript/TranscriptStandaloneUploaderInstructions";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import TranscriptStandAloneUploaderSubmitted
  from "components/SendTranscript/TranscriptStandaloneUploaderSubmitted";

const TranscriptUploader = props => {
  const theme = createMuiTheme({
    overrides: {
      MuiGrid:{
        spacing:{

        },
        item: {
          width: "calc(100% - 8px)",
          borderBottomColor: '#d3d3d3',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          backgroundColor: '#f1f1f1',
          marginLeft: '4px',
        }
      },
      MuiChip: {
        label: {
          textAlign: 'left',
          color: "#000",
          paddingLeft: '2.5rem',
          paddingRight: '26px',
          width: "100%",
        },
        outlined: {
          borderStyle: 'none',
          backgroundImage: 'url(' + folderSvg + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '10px',
          backgroundPositionX: '16px',
          backgroundPositionY: '8px',
        },
        root: {
          textAlign: 'left',
          width: "100%",
        }
      },
    }
  });

  let setLoading = props.setLoading;
  let applicationId = props.applicationId;
  let loading = props.loading;
  const [files, setFiles] = useState([]);
  const [transcriptUploadedStatus, setTranscriptUploadedStatus] = useState("pending");

  function zipFiles(files) {
    const zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i])
    }
    return zip.generateAsync({type: 'blob'})
  }

  async function uploadFile(event) {
    event.preventDefault();
    setLoading(true);

    let fileExtension = '';
    let oneFile = files.length === 1;
    const fileProm = oneFile
      ? Promise.resolve(files[0])
      : zipFiles(files);

    if (oneFile) {
      let splitFileName = files[0].name.split(".");
      fileExtension = splitFileName[splitFileName.length - 1]
    } else {
      fileExtension = 'zip';
    }

    const presignedUrlProm = ServerRequests.fetchPresignedUrl(fileExtension, applicationId)

    const presignedUrlRes = await presignedUrlProm
    const file = await fileProm

    let preSignedUrl = presignedUrlRes.data.url;
    return axios.put(preSignedUrl,
      file,
      {
        headers: {
          'Content-Type': file.type,
        }
      }
    )
      .then((response) => {
        setTranscriptUploadedStatus("success");
        ServerRequests.sendInternalTranscriptNotifyEmail(applicationId);
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setTranscriptUploadedStatus("error");
        logSentryError(error);
      });
  }

  const onFilesDropped = (selectedFiles) => {
    setFiles([...files, ...selectedFiles]);
  }

  const onFileDeleted = (deletedFile) => {
    setFiles(
      files.filter(function (file, index, arr) {
        return file.name !== deletedFile.name;
      })
    );
  }

  const onDropRejectMessage = (rejectedFiles, acceptedFiles, maxFileSize) => {
    let message = '';
    if (!acceptedFiles.includes(rejectedFiles.type)) {
      message = 'File type not supported. Supported files types: PNG, PDF, JPEG.\n';
    }
    if (maxFileSize < rejectedFiles.size) {
      message += 'File too large. File size cannot exceed 40 MB.';
    }
    return message;
  }

  return (
    transcriptUploadedStatus !== "pending"
      ? <TranscriptStandAloneUploaderSubmitted status={transcriptUploadedStatus} files={files} />
      : 
    <div className="transcript-standalone-uploader__upload-section">

        <div className="send-transcript__main-container">
          <div className="send-transcript__main-title">
            Document Upload
          </div>
          <div className="send-transcript__text">
            {
            <div className="transcript-standalone-uploader__dropzone">
              <MuiThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['application/pdf', 'image/png', 'image/jpeg']}
                  inputProps={{
                    'data-testid': "transcript-standalone-uploader__dropzone-input"
                  }}
                  Icon={TranscriptStandaloneUploaderInstructions}
                  classes={{
                    root: 'transcript-standalone-uploader__root',
                    textContainer: 'transcript-standalone-uploader__textContainer',
                    previewGrid: 'transcript-standalone-uploader__previewWindow'
                  }}
                  showPreviews={true}
                  showAlerts={['error']}
                  showPreviewsInDropzone={false}
                  getDropRejectMessage={onDropRejectMessage}
                  useChipsForPreview
                  previewGridProps={{container: {spacing: 1, direction: 'row'}}}
                  previewText=""
                  filesLimit={77}
                  maxFileSize={40 * 1024 * 1024}
                  onDrop={onFilesDropped}
                  onDelete={onFileDeleted}
                  dropzoneText=""
                >
                </DropzoneArea>
              </MuiThemeProvider>
              </div>
      }
      {
        (files && files.length > 0 && transcriptUploadedStatus === "pending") &&
        <div className='transcript-standalone-uploader__upload-button-container'>
          <Button
            className='transcript-standalone-uploader__upload-button'
            color="primary"
            style={{
              width: '100%',
              height: '2.25rem',
              fontSize: '14pt',
              fontFamily: 'Work Sans',
              fontWeight: 'bold',
              textTransform: 'none',
              boxShadow: 'none'
            }}
            data-testid={"transcript-standalone-uploader-upload-button"}
            disabled={loading}
            type="submit"
            onClick={uploadFile}
            variant="contained">
            Upload
          </Button>
        </div>
      }
    </div>
        </div></div >
  );
}

export default TranscriptUploader;

import * as React from "react";
import './TranscriptStandaloneUploaderSubmitted.scss';
import successIcon from "assets/images/sendTranscript/uploaded-success.svg";
import documentUploadHeader from "assets/images/sendTranscript/uploaded-header-plane.svg"

export const TranscriptStandaloneUpaloderSubmitted = props => {

    const successMsg = () => {
        const msg = props.files.length === 1
            ? 'Document Uploaded Successfully!'
            : 'Documents Uploaded Successfully!'

        return (
            <>
                <img alt='Transcript upload successful' src={successIcon} className="transcript-standalone-uploader__success-icon" />
                <div className="transcript-standalone-uploader__submitted-success-msg">
                    {msg}
                </div>
            </>)
    }

    return (
        <div className="transcript-standalone-uploader__submitted-section">
            <div className="transcript-standalone-uploader__submitted-section-background">

                <img alt='Transcript upload header' src={documentUploadHeader} className="transcript-standalone-uploader__submitted-header"></img>

                <div className="transcript-standalone-uploader__submitted-content-container">
                    <div className="transcript-standalone-uploader__submitted-content">
                        {props.status === "success"
                            ? successMsg()
                            : "Error uploading transcript. Please try again later."
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TranscriptStandaloneUpaloderSubmitted;

import React from "react";
import './Declined.scss';
import Button from "@material-ui/core/Button";
import declineSailboat from '../../assets/images/decline-sailboat.svg';
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import withHeader from "components/header/WithHeader";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";

const Declined = props => {
  return (
    <div className='declined-message--dialog-content'>
      <div className={`declined-message`}>
        <div className={`declined-message__inner-div`}>
          <h1 className={`declined-message__header`}>Thank you for your loan request</h1>
          <div className={`declined-message__declined-description`}>
            {props.location.state.declineReasonCode !== 18 &&
              <>
                <div className="declined-message--primary" data-testid="decline_text_testid">
                  Unfortunately, we are unable to approve your loan request.&nbsp;
                  Please be on the lookout for an email explanation providing the reasons for this decision.
                </div>
                <br className="desktop-break"/>
                {/*/!*Temp Decline or Knockout Decline*!/*/}
                {props.location.state.isTempDeclined
                  ? <div></div>
                  : <div className="declined-message--tertiary">
                    We welcome you to reapply 30 days from
                    the date you  receive your decline letter.
                  </div>
                }
              </>
            }
            {/*Too Far Out Decline:*/}
            {props.location.state.declineReasonCode === 18 &&
              <>
                <div className="declined-message--primary" data-testid="decline_text_testid">
                  Unfortunately your estimated enrollment date is too far in the future
                  and outside of our lending requirements.&nbsp;
                </div>
                <br className="desktop-break"/>

                <div className="declined-message--tertiary">
                  Please feel free to reapply when you
                  are closer to the program start date
                  for the school you will be attending.&nbsp;
                </div>
              </>
            }
          </div>
          <Button
            className="declined-message__button"
            variant="contained"
            onClick={() => props.history.push('/', {})}
          >
            &larr; BACK TO HOME
          </Button>
          <div className={`declined-message__image-container`}>
            <img className={`declined-message__image`} src={declineSailboat} alt="Sailboat"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default logCurrentPage(withHeader(backButtonRedirect(Declined)), 'declined');

import * as React from 'react';
import {useEffect, useState} from 'react';
import './DisclosuresMobile.scss';
import './DisclosuresDesktop.scss';
import './DisclosuresError.scss';
import Button from "@material-ui/core/Button";
import ServerRequests from "service/ServerRequests";
import DisclosuresContent from "components/disclosures/DisclosuresContent";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {hasErrorCode, logSentryError, parseErrors} from "service/errors";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import {withStyles} from "@material-ui/core";
import disclosuresSvg from "assets/images/disclosures/disclosures-logo.svg";
import ErrorPopupReapply from "components/errorMessage/ErrorPopupReapply";
import {useWindowSize} from 'react-use';
import DisclosuresErrorImg from "assets/images/errors/error-disclosures.svg";


const DisclosuresCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#3C89E8',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Disclosures = (props) => {
  const {history} = props;
  const applicationId = props.location.state.applicationId;
  const desktopMinWidth = 768;
  const isMobile = useWindowSize().width < desktopMinWidth || !!props?.forceMobileOnJest

  const [error, setError] = useState(null);
  const [showFullDisclosuresError, setShowFullDisclosuresError] = useState(false)
  const [popupError, setPopupError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disclosuresIsChecked, handleDisclosuresChanged] = useState(false);
  const [showDisclosures, setShowDisclosures] = useState(!isMobile);
  const [disclosuresInfo, setDisclosuresInfo] = useState(null)


  const disclosureContentComponent = () => <DisclosuresContent showDisclosures={showDisclosures}
                                                               applicationId={applicationId}
                                                               showError={showFullDisclosuresError}
                                                               setShowError={setShowFullDisclosuresError}
                                                               loading={loading} setLoading={setLoading}
                                                               disclosuresInfo={disclosuresInfo}
                                                               setDisclosuresInfo={setDisclosuresInfo}
                                                               isMobile={isMobile}
  />


  const errorStrings = {
    'end-of-road-status-withdrawn': 'Your application has been withdrawn.',
    'end-of-road-status-error': 'Your application has encountered an error.',
    'end-of-road-status-expired': 'Your application has expired.'
  };

  useEffect(() => {
    setShowDisclosures(!isMobile)
  }, [isMobile])

  function showDisclosuresEvent() {
    setShowDisclosures(true);
  }


  function submit() {
    setLoading(true);
    ServerRequests.submitAcceptDisclosures(applicationId)
      .then(({data}) => {
        if (data.status === 'Declined') {
          props.history.push('/declined', {
            applicationId: applicationId,
            isTempDeclined: false,
            declineReasonCode: data.declineReasonCode
          })
        } else if (data.status === 'Refer') {
          props.history.push('/refer', {applicationId});
        } else {
          history.push('/submitted', {
            applicationId: applicationId,
            status: data.status,
            path: data.applicationPath,
            estimatedHardpullDate: data.estimatedHardpullDate,
            estimatedStartDate: data.estimatedStartDate,
            loanInfo: data.selectedOffer
          });
        }
      })
      .catch(error => {
        logSentryError(error);
        if (error.response === undefined) {
          setError({type: "server"});
          setLoading(false);
        } else if (error.response.status === 403) {
          setLoading(false);
          if (hasErrorCode(error.response.data.errors, 'end-of-road-status-declined')) {
            props.history.push('/declined', {applicationId: error.response.data.applicationId, isTempDeclined: true})
          } else if (hasErrorCode(error.response.data.errors, 'end-of-road-status-ineligible')) {
            props.history.push('/ineligible', {applicationId: error.response.data.applicationId});
          } else {
            setPopupError({type: 'user', messages: parseErrors(error.response.data.errors, errorStrings)});
          }
        } else if (error.response.status === 424) {
          props.history.push('/errorPage', {applicationId: applicationId})
        } else {
          setError({type: "server"});
          setLoading(false);
        }
      });
  }


  function reloadComponent() {
    setLoading(true);
    setShowDisclosures(true);
    setShowFullDisclosuresError(false);
  }

  if (showFullDisclosuresError) {
    return (
      <div className="disclosures__error-container">
        <div className="disclosures__error-content">
          <img src={DisclosuresErrorImg} alt="Unable to Generate Disclosures" className="disclosures__error-img"/>
          <h2 className="disclosures__error-title">UNABLE TO GENERATE DISCLOSURES</h2>
          <button className="disclosures__reload-button" onClick={reloadComponent}>RELOAD</button>
        </div>
      </div>)
  }

  return (
    <>
      {loading && <LoadingIndicator/>}


      {isMobile &&
        <div className="disclosures__main__mobile disclosures__main">
          <div className="disclosures__mobile_header">
            <div className="disclosures__desktop-header_svg_container">
              <img src={disclosuresSvg} alt="quill" className="disclosures__mobile_header_img"
                   data-testid="disclosures-quill"/>
            </div>

            <div className="disclosures__mobile_address">
              <span>MERITIZE LENDING LLC</span>
              <span>5805 MAIN STREET, SUITE 220</span>
              <span>FRISCO, TX 75034</span>
            </div>
          </div>
          <div>
            {showDisclosures &&
              <div className="disclosures__scrollable-window__wrapper">
                <div className="disclosures__scrollable-window">
                  {disclosureContentComponent()}
                </div>
              </div>
            }
          </div>

          {!showDisclosures &&
            <>
              <div className="disclosures__read-disclosures-container">
                <Button
                  className="disclosures__read-disclosures-button"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={showDisclosuresEvent}>
                  READ FULL DISCLOSURES
                </Button>
              </div>


              <div className="disclosures__hard-pull-text">
                When selecting “I AGREE” AND CLICKING SUBMIT, we will request your credit report
                and your co-applicant’s credit report, if applicable, from a credit reporting agency.
                Please note that this will create a hard credit inquiry on your credit report, and on
                the report of your co-applicant, if applicable. Disclosures contain rate, fee, and other
                cost information, as applicable.
              </div>
            </>
          }
        <div style={{margin: "0 auto"}}>
          <div className='disclosures__checkbox_agree_container'>
            <FormControlLabel
              control={
                <DisclosuresCheckbox
                  color="primary"
                  name="checkedB"
                  onChange={(event) => handleDisclosuresChanged(event.target.checked)}
                  size="small"
                />
              }
              label={
                <div className='disclosures__checkbox_agree-label'>
                  I AGREE
                </div>
              }
              style={{alignItems: "start"}}
            />
          </div>
          {(error) && <ErrorMessage type={error.type} messages={error.messages}/>}
            {(popupError) && <ErrorPopupReapply error={popupError} setError={setPopupError} {...props} />}
          <div className="disclosures__submit-button-container">
            <Button
              className="disclosures__submit-button"
              data-testid={"disclosures-submit-button"}
              color="primary"
              variant="contained"
              disabled={!disclosuresIsChecked || loading}
              onClick={submit}>
              SUBMIT
            </Button>
          </div>
        </div>
        </div>
      }

      {!isMobile &&
        <div className="disclosures__main__desktop disclosures__main">
          <div style={{width: "100%"}}>
            <div className="col">
              <div className="disclosures__desktop_header">
                <div className="disclosures__desktop-header_svg_container">
                  <img src={disclosuresSvg} alt="quill" className="disclosures__desktop_header_img"
                       data-testid="disclosures-quill"/>
                </div>

                <div className="disclosures__desktop_address">
                  <span>MERITIZE LENDING LLC</span>
                  <span>5805 MAIN STREET, SUITE 220</span>
                  <span>FRISCO, TX 75034</span>
                </div>
              </div>
              <div className="disclosures__desktop_scrollable-window__wrapper">
                <div className="disclosures__desktop_scrollable-window">
                  {showDisclosures && disclosureContentComponent()}
                </div>
              </div>
              <div className="disclosures__desktop_hard-pull-text">
                When selecting “I AGREE” AND CLICKING SUBMIT, we will request your credit report
                and your co-applicant’s credit report, if applicable, from a credit reporting agency.
                Please note that this will create a hard credit inquiry on your credit report, and on
                the report of your co-applicant, if applicable. Disclosures contain rate, fee, and other
                cost information, as applicable.
              </div>
              <div className='disclosures__desktop_checkbox_agree_container'>
                <FormControlLabel
                  control={
                    <DisclosuresCheckbox
                      color="primary"
                      name="checkedB"
                      onChange={(event) => handleDisclosuresChanged(event.target.checked)}
                      size="small"
                    />
                  }
                  label={
                    <div className='disclosures__desktop_checkbox_agree-label'>
                      I AGREE
                    </div>
                  }
                  style={{alignItems: "start"}}
                />
              </div>
              {(error) && <ErrorMessage type={error.type} messages={error.messages}/>}
              <div className="disclosures__desktop_submit-button-container">
                <Button
                  className="disclosures__desktop_submit-button"
                  data-testid={"disclosures-submit-button"}
                  color="primary"
                  variant="contained"
                  disabled={!disclosuresIsChecked || loading}
                  onClick={submit}>
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>

        </div>
      }

    </>

  );
};

export default logCurrentPage(withHeader(backButtonRedirect(Disclosures)), 'disclosures');

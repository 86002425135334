import * as Sentry from '@sentry/browser';

export const removePiiFromSentryConsoleBreadcrumbs = (breadcrumb, hint) => {

  if (breadcrumb?.category === "console") {
    breadcrumb.data.arguments.forEach(argument => {
      // Check if it is related to a HTTP Request
      if (argument?.config?.url) {
        argument.config = "<HIDDEN> may contain PII";
      }
    });
  }
  return breadcrumb;
};

export const initSentry = () => Sentry.init(
  {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    beforeBreadcrumb: removePiiFromSentryConsoleBreadcrumbs
  });

import React from "react";
import Popup from "./Popup";
import planeExclaim from '../../assets/images/plane-exclaim.svg';

const ErrorPopupReapply = (props) => {
  const {
    error
  } = props;


  const icon = () => planeExclaim

  const headers = () => error.messages

  const descriptions = () => [
    "To continue, please submit a new application.",
    "",
    "Questions?",
  ]

  const buttonLabel = () => "Reapply"

  const handleConfirm = () => props.history.push('/', {})

  return <>
    <Popup
      icon={icon}
      headers={headers}
      descriptions={descriptions}
      buttonLabel={buttonLabel}
      handleConfirm={handleConfirm}
    />
  </>

}

export default ErrorPopupReapply;

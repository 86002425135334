import React from "react";

export const backButtonRedirect = (Component) => {

  return class extends React.Component {

    preventBackButton = () => {
      if (this.props.history.action === "POP") {
        this.props.history.push("/");
      }
    }
    componentWillUnmount() {
      this.preventBackButton();
    }

    componentDidUpdate() {
      this.preventBackButton();
    }

  render() {
      return <Component {...this.props} />;
    }
  }
}

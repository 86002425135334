import Grid from '@material-ui/core/Grid';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './LoanInfoCard.scss'
import CplOfferBook from 'assets/images/cpl-offer-book.svg';
import CplOfferFlight from 'assets/images/cpl-offer-flight.svg';
import CplOfferFlightLand from 'assets/images/cpl-offer-flight-land.svg';
import background from 'assets/images/repayment-option-total_background.png';


export default function CplOfferCard(props) {
  return (
    <Card variant="outlined" className="loaninfo_card__offer-card">
      <CardContent>
        <Grid container>
          <Grid item xs={5} className="cpl-offers_card__title-container-left-part">
            <span className="loaninfo_card__card-title">{props.loanInfo.loanTermMonths}-month</span>
            <div className="loaninfo_card__titles-option-helper">
              REPAYMENT TERM
            </div>
          </Grid>
          <Grid item xs={2} className="offers_card__title-container-center-part">
            @
          </Grid>
          <Grid item xs={5} className="cpl-offers_card__title-container-right-part">
            <span
              className="offers__card-title__interest_rate offer-card__card-title">{(props.loanInfo.interestRate ? props.loanInfo.interestRate : 0).toFixed(2)}%</span>
            <div className="offers__titles-option-helper__interest_rate_type">
              {props.loanInfo.interestRateType === 'fixed' ? 'FIXED RATE' : 'VARIABLE RATE'}
            </div>
          </Grid>
        </Grid>
      </CardContent>

      <div className="offers__repayment-option-box cpl-offer-card__repayment-option-panel">
        <Grid container spacing={2} data-testid="repayment_option" className="cpl-offers__repayment-option-rows">
          <Grid item container xs={12}
                className="cpl-offers__repayment-option-row cpl-offers__repayment-option-payments-in-school">
            <Grid item xs={1} className="cpl-offers__repayment-option-icon">
              <img src={CplOfferBook} alt="School" style={{'width': '28px'}}/>
            </Grid>
            <Grid item xs={6} className="cpl-offers__repayment-option-text">
              Payments in school
            </Grid>
            <Grid item xs={5} className="cpl-offers__repayment-option-value" data-testid="school-payment-value">
              $0/month
            </Grid>
          </Grid>
          <Grid item container xs={12}
                className="cpl-offers__repayment-option-row cpl-offers__repayment-option-during-flight">
            <Grid item xs={1} className="cpl-offers__repayment-option-icon">
              <img src={CplOfferFlight} alt="Flight" style={{'width': '28px'}}/>
            </Grid>
            <Grid item xs={6} className="cpl-offers__repayment-option-text">
              During Flight Time-Building†
            </Grid>
            <Grid item xs={5} className="cpl-offers__repayment-option-value" data-testid="flight-payment-value">
              ${props.loanInfo.paymentsDuringFlightTime}/month
            </Grid>
          </Grid>
          <Grid item container xs={12}
                className="cpl-offers__repayment-option-row cpl-offers__repayment-option-post-flight">
            <Grid item xs={1} className="cpl-offers__repayment-option-icon">
              <img src={CplOfferFlightLand} alt="Flight" style={{'width': '28px'}}/>
            </Grid>
            <Grid item xs={6} className="cpl-offers__repayment-option-text">
              Post Flight Time-Building
            </Grid>
            <Grid item xs={5} className="cpl-offers__repayment-option-value" data-testid="post-flight-payment-value">
              ${props.loanInfo.paymentsAfterGrad}/month
            </Grid>
          </Grid>
          <Grid item xs={12} className="cpl_offers__repayment-option-total"
                style={{backgroundImage: 'url(' + background + ')'}}>
            <div className="offers__repayment-option-total__text">
              Total of payments
              @ {!!props.loanInfo.apr && props.loanInfo.apr.toFixed(2)}% {props.loanInfo.interestRateType === 'fixed' ? 'FIXED APR' : 'VARIABLE APR'} =
              ${!!props.loanInfo.loanAmountAfter && props.loanInfo.loanAmountAfter.toFixed(2)}
            </div>
          </Grid>
        </Grid>
      </div>

    </Card>

  );
}

import React from 'react';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import background from 'assets/images/repayment-option-total_background.png';
import CardContent from '@material-ui/core/CardContent';
import "./Offer.scss"
import CplOfferBook from "assets/images/cpl-offer-book.svg";
import CplOfferFlight from "assets/images/cpl-offer-flight.svg";
import CplOfferFlightLand from "assets/images/cpl-offer-flight-land.svg";

export default function CplOffer(props) {

  let {value, offerInfo, onNewValueSelected} = props;

  let valueChanged = (event) => {
    onNewValueSelected(event.target.value);
  };

  return (
    <Card variant="outlined" className="offers__offer-card offers-ie11fix offer-card__main-panel">
      <FormControl>
        <RadioGroup
          onChange={valueChanged}
          value={value}>

        <CardContent className="cpl-offers__repayment-card-content">
          <Grid container>
            <Grid item xs={5} className="cpl-offers_card__title-container-left-part">
              <FormControlLabel
                className="offers__repayment-option"
                key={'CPL-' + offerInfo.offerNumber}
                value={'CPL-' + offerInfo.offerNumber}
                control={
                  <Radio color="primary"
                         size="medium"
                         className="cpl_offers__radio"/>
                }
                label={<>
                    <span className="offers__card-title offer-card__card-title">{offerInfo.loan_term_months}-month</span>
                    <div className="cpl-offers__titles-option-helper">
                      REPAYMENT TERM
                    </div>
                </>
                }
              />
            </Grid>
            <Grid item xs={2} className="offers_card__title-container-center-part">
              @
            </Grid>
            <Grid item xs={5} className="cpl-offers_card__title-container-right-part">
              <span className="offers__card-title__interest_rate offer-card__card-title">{(offerInfo.interest_rate?offerInfo.interest_rate:0).toFixed(2)}%</span>
              <div className="offers__titles-option-helper__interest_rate_type">
                {offerInfo.interest_rate_type === 'fixed' ? 'FIXED RATE' : 'VARIABLE RATE'}
              </div>
            </Grid>
          </Grid>
        </CardContent>

          <div className="offers__repayment-option-box cpl-offer-card__repayment-option-panel">
            <Grid container spacing={2} data-testid="repayment_option" className="cpl-offers__repayment-option-rows">
              <Grid item container xs={12} className="cpl-offers__repayment-option-row cpl-offers__repayment-option-payments-in-school">
                <Grid item xs={1} className="cpl-offers__repayment-option-icon">
                  <img src={CplOfferBook} alt="School" style={{"width": "28px"}}/>
                </Grid>
                <Grid item xs={6} className="cpl-offers__repayment-option-text">
                  Payments in school
                </Grid>
                <Grid item xs={5} className="cpl-offers__repayment-option-value" data-testid="school-payment-value">
                  $0/month
                </Grid>
              </Grid>
              <Grid item container xs={12} className="cpl-offers__repayment-option-row cpl-offers__repayment-option-during-flight">
                <Grid item xs={1} className="cpl-offers__repayment-option-icon">
                  <img src={CplOfferFlight} alt="Flight" style={{"width": "28px"}}/>
                </Grid>
                  <Grid item xs={6} className="cpl-offers__repayment-option-text">
                  During Flight Time-Building†
                </Grid>
                <Grid item xs={5} className="cpl-offers__repayment-option-value" data-testid="flight-payment-value">
                  ${offerInfo.payment_cpl_atp}/month
                </Grid>
              </Grid>
              <Grid item container xs={12} className="cpl-offers__repayment-option-row cpl-offers__repayment-option-post-flight">
                <Grid item xs={1} className="cpl-offers__repayment-option-icon">
                  <img src={CplOfferFlightLand} alt="Flight" style={{"width": "28px"}}/>
                </Grid>
                <Grid item xs={6} className="cpl-offers__repayment-option-text">
                  Post Flight Time-Building
                </Grid>
                <Grid item xs={5} className="cpl-offers__repayment-option-value" data-testid="post-flight-payment-value">
                  ${offerInfo.payment_cpl_post_grad}/month
                </Grid>
              </Grid>
              <Grid item xs={12} className="cpl_offers__repayment-option-total" style={{backgroundImage: "url("+background+")"}}>
                <div className="offers__repayment-option-total__text">
                  Total of payments
                  @ {!!offerInfo.apr_cpl && offerInfo.apr_cpl.toFixed(2)}% {offerInfo.interest_rate_type === 'fixed' ? 'FIXED APR' : 'VARIABLE APR'} =
                  ${!!offerInfo.loan_amount_after_cpl && offerInfo.loan_amount_after_cpl.toFixed(2)}
                </div>
              </Grid>
            </Grid>
          </div>
        </RadioGroup>
      </FormControl>

    </Card>
  );
}

import React, {Component} from 'react';
import "./css/MaintenancePage.scss"

class MaintenancePage extends Component {

    render() {
        return (
            <div className="maintenance-page__container">
                <img className="maintenance-page__toolbox-logo" alt="Site Under Maintenance" src={ require('assets/images/toolbox.svg') } />
                <div className="maintenance-page__header">We’re upgrading our tools…</div>
                <div className="maintenance-page__message">We are currently undergoing scheduled maintenance. We will be back shortly. Thank you for your patience!</div>
            </div>
        );
    }
};


export default MaintenancePage;

import FormControl from "@material-ui/core/FormControl";
import backgroundStar from "assets/images/star_sprite.svg";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import React from "react";
import Select from "@material-ui/core/Select";

const CalSelect = (props) => {
  const {
    id,
    selectOptions,
    bigLabel,
    inputProps = {},
    bigLabelProps,
    className,
    labelClassName,
    textboxClassName,
    helpertextClassName,
    onChange,
    label,
    inputLabelId,
    InputLabelProps,
    value,
    helperText,
    helperTextId,
    FormHelperTextProps,
    placeholder,
    shortInput = false,
    ...otherProps
  } = props;

  inputProps.className = (inputProps.className || '') + " " + ( textboxClassName ? textboxClassName : 'c-text-field__input' ) ;
  let fullClassName = (className || '') + " c-text-field";
  let labelClass = labelClassName ? labelClassName : 'c-text-field__label';
  let helpertextClass = helpertextClassName ? helpertextClassName : '';

  const blurHandler = event => {
    if(event.target.value){
      event.target.parentNode.parentNode.previousSibling.classList.add('play')
    } else {
      event.target.parentNode.parentNode.previousSibling.classList.remove('play')
    }
  };

  return (
    <FormControl
      className={fullClassName}
      style={{display: 'block'}}
      {...otherProps}
    >
      {bigLabel &&
        <>
          <label htmlFor={id}
                 className={labelClass}
                 style={{display: 'inline-flex'}}
                 {...bigLabelProps}
          >{bigLabel}</label>
          <div className="shapeshifter" style={{backgroundImage: 'url('+backgroundStar+')'}}></div>
        </>
      }
      <div className={`c-text-field__input-container ${shortInput ? 'c-text-field__input-container--short' : ''}`}>
        {label && (
          <InputLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
            {label}
          </InputLabel>
        )}

        <Select
          id={id}
          native
          value={value}
          onChange={onChange}
          label={label}
          onBlur={blurHandler}
          style={{width: '50%'}}
          inputProps={
            {
              'id': id,
              'data-testid': id,
            }
          }>
          <option hidden value=""/>
          {
            selectOptions.map((opt) =>
              <option key={opt} value={opt}
                      data-testid={`pay-grade-option-${opt}`}>
                {opt}
              </option>
            )
          }
        </Select>

        {/*<Input*/}
        {/*  placeholder={placeholder}*/}
        {/*/>*/}
        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps} className={helpertextClass}>
            {helperText}
          </FormHelperText>
        )}
      </div>
    </FormControl>
  );
};

export default CalSelect;

import React from 'react';
import './Footer.scss';
import Grid from "@material-ui/core/Grid";
import CallIcon from "@material-ui/icons/Call";
import CommentIcon from "@material-ui/icons/Comment";

const Footer = (props) => {

  return (
    <Grid container data-testid="meritize-footer" className="footer">
      <Grid item xs={6} className="footer_grid-item footer__phone">
        <CallIcon className="footer__contact-icon"/><a href="tel:8336374848">(833) 637-4848</a>
      </Grid>
      <Grid item xs={6} className="footer_grid-item footer__email">
        <CommentIcon className="footer__contact-icon"/><a href="mailto:apply@meritize.com" target="_blank" rel="noopener noreferrer">apply@meritize.com</a>
      </Grid>
    </Grid>

  );
};

export default Footer;

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import Environment from "util/Environment";
import {parseISO} from "date-fns";
import Grid from "@material-ui/core/Grid";
import './UpcomingMaintenanceMessage.scss';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";

let UpcomingMaintenanceDialog = props => {
  let {setOpen,open} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        }
      }
    }
  )

  const maintenanceTime = parseISO(Environment.upcomingMaintenanceDatetime());
  const maintenanceMessage = Environment.upcomingMaintenanceMessage();
  const dateFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long', month: '2-digit', day: '2-digit',timeZone: 'America/Chicago' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: true, timeZone: 'America/Chicago' });
  const maintenanceTimeText = dateFormatter.format(maintenanceTime)+" @ "+timeFormatter.format(maintenanceTime).replace(/\s/g,'').toLowerCase()+" CT"

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="upcoming-maintenance-dialog"
      fullWidth
      maxWidth="md"
    >
      <DialogActions>
        <Button data-testid="upcoming-maintenance-close-button" onClick={handleClose}><CloseIcon className="upcoming-maintenance__close-icon"/></Button>
      </DialogActions>
      <DialogContent
        className="upcoming-maintenance__main-dialog"
      >

        <MuiThemeProvider theme={theme}>
          <Grid container >
            <Grid item xs={12} sm={6} >
              <div className="upcoming-maintenance__column left-column">
                <Box display={{ xs: 'block', sm: 'none'}}>
                  <img className='upcoming-maintenance__mobile-image' alt="" src={require('assets/images/maintenance/carousel-160x84.svg')}/>
                </Box>
                <Box display={{ xs: 'none', sm: 'block'}}>
                  <img className='upcoming-maintenance__desktop-image' alt="" src={require('assets/images/maintenance/carousel-300x300.svg')}/>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="upcoming-maintenance__column">
                <div className="upcoming-maintenance__title">
                  Scheduled Maintenance
                </div>
                <div className="upcoming-maintenance__date-title" data-testid="upcoming-maintenance-dialog-time">
                  {maintenanceTimeText}
                </div>
                <div className="upcoming-maintenance__text">
                  <div>
                    {maintenanceMessage}
                  </div>
                  <br/>
                  <div>
                    Questions? Please feel free to reach out to our friendly customer service agents!
                  </div>
                  <br/>
                  <div>
                    <a href="mailto:apply@meritize.com" target="_blank" rel="noopener noreferrer">apply@meritize.com</a>
                    <br/>833-637-4848
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </DialogContent>
    </Dialog>
  );

}

export default UpcomingMaintenanceDialog;

export function formatDateWith2DigitsForDay (date) {
  const year  = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day   = date.getDate().toString().padStart(2, "0");
  return month + '/' + day + '/' + year;
}

export function getCurrentDate(){
  return new Date()
}

export function toUsTimestamp(yyyymmddTimestamp) {
  const parts = yyyymmddTimestamp.split('-');

  if (parts.length !== 3 || parts[0].length !== 4 || parts[1].length !== 2 || parts[2].length !== 2) {
    throw new Error('Invalid date format. Expected format: yyyy-mm-dd');
  }

  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

export default {
  formatDateWith2DigitsForDay,
  getCurrentDate
};

import Grid from "@material-ui/core/Grid";
import * as React from "react";
import './DisclosuresContent.scss';

export const DisclosuresRatesAndFees = (props) => {

  const { disclosuresInfo } = props

  const toPercentage = (number) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 12
    };
    const fixedPrecision = (number * 100).toLocaleString("en-US", options)
    return `${fixedPrecision}%`
  }


    return (

      <div className="block variable-loan-interest">
        <h2 className="rate-type-title">Loan Variable Interest Rate &amp; Fees </h2>
        <div>
          <div className="interest-box ">
            <p>Your <strong>starting interest rate</strong> will be between</p>
            <Grid container spacing={0} id="variableInterestRates" data-testid="variable-interest-rate-range"
                  className="interest-mid">
              <Grid item xs={5}><span>{toPercentage(disclosuresInfo?.variableRateMin)}</span></Grid>
              <Grid item xs={2}><p>AND</p></Grid>
              <Grid item xs={5}><span>{toPercentage(disclosuresInfo?.variableRateMax)}</span></Grid>
            </Grid>
            <p>After the starting rate is set, your rate will then vary with the market.</p>
          </div>
        </div>
      </div>
  )
}

import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import './MeritInformation.scss';
import ServerRequests from "../../service/ServerRequests";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {string} from "prop-types";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {hasErrorCode, logSentryError, parseErrors} from "service/errors";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import Infographic from "components/header/infographic/Infographic";
import CalTextField from "components/CalTextField/CalTextField";
import backgroundStar from "assets/images/star_sprite.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:768px)': {
      width: '100%',
      maxWidth: '1000px',
      margin: 'auto',
    }
  }
}));

const clearedMilitaryInfo = {
  military_experience_years: '',
  active_duty_until_year: '',
};

const errorStrings = {
  'invalid-military-year-of-completion': 'Enter a valid last year of active duty',
  'military-year-of-completion-required': 'Enter last year of active duty',
  'military-experience-years-required': 'Enter total years of military experience',
  'invalid_military-experience-years': 'Enter valid total years of experience',
  'highest-level-of-education-required': 'Select an education level',
  'education-completed-required': 'Enter a year of completion',
  'invalid-education-completed': 'Enter a valid year of completion',
}

const MeritInformation = props => {
  useStyles();
  const [formInputs, handleFormInputs] = useState(getInitialStateOfForm());

  const [error, handleError] = useState(null);
  const [invalidYearError, handleInvalidYearError] = useState(false);
  const [loading, setLoading] = useState(false);

  function getInitialStateOfForm() {
    return {
      highest_level_of_education: '',
      year_of_completion: '',
      has_military_experience: false,
      military_experience_years: ''
    };
  }

  let handleSubmit = (event) => {
    setLoading(true);
    let data = {
      applicationId: props.location.state.applicationId,
      highestLevelOfEducation: formInputs.highest_level_of_education,
      academicYearOfCompletion: formInputs.year_of_completion,
      coapplicantRequested: formInputs.coapplicantRequested != null ?
        (formInputs.coapplicantRequested === 'yes') : false,
      hasMilitaryExperience: formInputs.has_military_experience,
      militaryExperienceYears: formInputs.military_experience_years,
      militaryYearOfCompletion: formInputs.active_duty_until_year,
    }

    ServerRequests.submit('/submit-merit-information', {data})
      .then((response) => {
        let applicationPath = response.data.applicationPath;
        const addCoapplicant = !!(formInputs.coapplicantRequested === 'yes' || props.location.state.forceCosigner);

        switch (applicationPath) {
          case 'Academic':
            props.history.push('/merit-details',
              {
                applicationId: props.location.state.applicationId,
                applicationPath: applicationPath,
                addCoapplicant: addCoapplicant,
                isHighSchool: formInputs.highest_level_of_education === "High School",
              });
            break;
          case 'Military':
            props.history.push('/merit-details',
              {
                applicationId: props.location.state.applicationId,
                applicationPath: applicationPath,
                addCoapplicant: addCoapplicant,
              });
            break;
          case 'HSE':
            props.history.push('/merit-details',
              {
                applicationId: props.location.state.applicationId,
                applicationPath: applicationPath,
                graduationYear: parseInt(formInputs.year_of_completion),
                addCoapplicant: addCoapplicant,
              });
            break;
          case 'None':
            if (addCoapplicant) {
              routeToCoapplicantPage();
            } else {
              let data = {
                applicationId: props.location.state.applicationId,
              };
              ServerRequests.submit('/send-merit-details', {data})
                .then(response => {
                  let {status, needsManualEvaluation, applicationId} = response.data;
                  if (status === 'Pending') {
                    routeToCoapplicantPage(true);
                  } else if (status === 'Declined') {
                    props.history.push('/declined', {applicationId: applicationId, isTempDeclined: true})
                  } else if (status === 'Offered') {
                    if (!needsManualEvaluation) {
                      props.history.push('/offers', {
                        offers: response.data.offers,
                        applicationId: response.data.applicationId,
                        applicationCreatedDate: response.data.applicationCreatedDate,
                        has18MonthNoInterest: response.data.has18MonthNoInterest,
                        hasMultipleDisbursements: response.data.hasMultipleDisbursements,
                        hasCoapplicant: false,
                        isCpl: response.data.isCpl,
                      });
                    } else {
                      props.history.push('/full-app', {applicationId: applicationId});
                    }
                  } else {
                    logSentryError(new Error(`Unknown status: ${status}`)); // todo - find out what the user should see here
                  }
                })
                .catch(error => {
                  if (error.response.status === 403) {
                    setLoading(false);
                    if (hasErrorCode(error.response.data.errors, 'end-of-road-status-declined')) {
                      props.history.push('/declined', {applicationId: error.response.data.applicationId, isTempDeclined: true})
                    } else if (hasErrorCode(error.response.data.errors, 'end-of-road-status-ineligible')) {
                      props.history.push('/ineligible', {applicationId: error.response.data.applicationId});
                    } else {
                      handleError({type: 'user', messages: parseErrors(error.response.data.errors, errorStrings)});
                    }
                  } else {
                    logSentryError(error);
                    setLoading(false);
                  }
                });
            }
            break;
          default:
            routeToCoapplicantPage();
            break;
        }
      })
      .catch(error => {
        setLoading(false);
        if (error.response === undefined || error.response.status !== 422) {
          logSentryError(error);
          handleError({type: "server"});
        } else if (error.response.status === 403) {
          setLoading(false);
          if (hasErrorCode(error.response.data.errors, 'end-of-road-status-declined')) {
            props.history.push('/declined', {applicationId: error.response.data.applicationId, isTempDeclined: true})
          } else if (hasErrorCode(error.response.data.errors, 'end-of-road-status-ineligible')) {
            props.history.push('/ineligible', {applicationId: error.response.data.applicationId});
          } else {
            handleError({type: 'user', messages: parseErrors(error.response.data.errors, errorStrings)});
          }
        } else {
          handleError({type: "user", messages: parseErrors(error.response.data.errors, errorStrings)})
        }
      });
    event.preventDefault();
  };

  const routeToCoapplicantPage = (useConditionalCoapplicant = false) => {
    props.history.push('/coapplicant', {
      applicationId: props.location.state.applicationId,
      conditionalCoapplicant: useConditionalCoapplicant,
      hasOffers: false,
    });
  }

  const formStateChangeHandler = (fieldName) => {
    return (e) => handleFormInputs({
      ...formInputs,
      [fieldName]: sanitizeValue(e.target.value, fieldName),
    })
  };

  function setYearOfCompletionErrorIfNecessary(sanitizedYear) {
    let currentYear = new Date().getFullYear();
    if (sanitizedYear > currentYear) {
      handleInvalidYearError(true);
    } else {
      handleInvalidYearError(false);
    }
  }

  const sanitizeValue = (value, fieldName) => {
    if (['year_of_completion', 'active_duty_until_year', 'military_experience_years'].includes(fieldName)) {
      let sanitizedYear = value.replace(/[^0-9]/g, '');
      if (fieldName === 'year_of_completion') {
        setYearOfCompletionErrorIfNecessary(Number(sanitizedYear));
      }
      return sanitizedYear;
    }
    return value;
  };

  const militaryToggleHandler = () => {
    return (e) => {
      handleFormInputs({
        ...formInputs,
        ...clearedMilitaryInfo,
        "has_military_experience": e.target.checked,
      });
    }
  };

  const clickRadioHandler = event => {
    if (event.target.value) {
      event.target.parentNode.parentNode.parentNode.parentNode.previousSibling.previousSibling.classList.add('play')
    }
  };

  return (
    <>
      {
        loading && <LoadingIndicator/>
      }

      <Infographic stage="2" title="Merit Details"/>

      <div className="merit-details-ie11fix">

        <div className="academic__form-container">

          <form
            className="merit__form"
            onSubmit={handleSubmit}>

            <div className="merit-details__form-input-section">
              <div className="shapeshifter" style={{backgroundImage: 'url(' + backgroundStar + ')'}}></div>
              <div id="highest_level_of_education">
                <div className="merit-details__form-input-label">Highest partial or completed degree</div>

                <div className="merit__form-section-header">For
                  example, if you're currently enrolled in a bachelor's program, you would select Bachelor’s Degree
                  below.
                </div>
              </div>

              <RadioGroup
                data-testid="select-level_of_education"
                name="highest_level_of_education"
                label="level of education"
                value={formInputs.highest_level_of_education}
                onChange={formStateChangeHandler('highest_level_of_education')}
                onClick={clickRadioHandler}
              >
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="Some High School or Less" control={<Radio/>}
                                  label="Less than High School"/>
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="High School" control={<Radio/>}
                                  label="High School (currently enrolled in or completed)"/>
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="High School Equivalency" control={<Radio/>}
                                  label="GED, HiSET, or TASC (High School Equivalency)"/>
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="Trade School/Certification" control={<Radio/>}
                                  label="Trade School"/>
                {/*<FormControlLabel classes={{label: 'radioLabel__form'}}*/}
                {/*                  value="Some College" control={<Radio/>}*/}
                {/*                  label="Some College"/>*/}
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="Associate's Degree" control={<Radio/>}
                                  label="Associate's Degree"/>
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="Bachelor's Degree" control={<Radio/>}
                                  label="Bachelor's Degree"/>
                <FormControlLabel classes={{label: 'radioLabel__form'}}
                                  value="Master's Degree or Higher" control={<Radio/>}
                                  label="Master's Degree or Higher"/>
              </RadioGroup>
            </div>

            <div className="merit-details__form-input-section">
              <CalTextField
                labelClassName="merit-details__form-input-label"
                textboxClassName="merit-details__form-input-textbox"
                helpertextClassName="merit-details__form-input-helpertext"
                id="year_of_completion"
                bigLabel="Year of Completion"
                placeholder="yyyy"
                inputProps={{className: 'academic__input-text', maxLength: 4}}
                FormHelperTextProps={{className: 'academic__input-text'}}
                shortInput={true}
                onChange={formStateChangeHandler("year_of_completion")}
                value={formInputs.year_of_completion}
                helperText="If in progress or incomplete, enter the latest year of attendance"
                errorText={invalidYearError ? "Invalid Year of Completion" : null}
                errortextClassName="merit-details__form-input-errortext"
              />
            </div>

            <div className="merit-details__form-input-section">

              <FormControlLabel
                className="merit__switch "
                classes={{label: 'merit__switch-label'}}
                control={
                  <Switch
                    checked={formInputs.has_military_experience}
                    onChange={militaryToggleHandler()}
                    name="has_military_experience"
                  />
                }
                label="Current or previous military experience?"
              />
            </div>

            {formInputs.has_military_experience &&
              <>

                <div className="merit-details__form-input-section">
                  <CalTextField
                    labelClassName="merit-details__form-input-label"
                    textboxClassName="merit-details__form-input-textbox"
                    helpertextClassName="merit-details__form-input-helpertext"
                    id="active_duty_until_year"
                    bigLabel="Active Duty Until"
                    placeholder="yyyy"
                    inputProps={{className: 'academic__input-text', maxLength: 4}}
                    FormHelperTextProps={{className: 'academic__input-text'}}
                    shortInput={true}
                    onChange={formStateChangeHandler("active_duty_until_year")}
                    value={formInputs.active_duty_until_year}
                    helperText="If in active duty, enter current year"
                  />
                </div>

                <div className="merit-details__form-input-section">
                  <CalTextField
                    labelClassName="merit-details__form-input-label"
                    textboxClassName="merit-details__form-input-textbox"
                    helpertextClassName="merit-details__form-input-helpertext"
                    id="military_experience_years"
                    bigLabel="Total Years of Experience"
                    placeholder="# of years"
                    inputProps={{className: 'academic__input-text', maxLength: 4}}
                    FormHelperTextProps={{className: 'academic__input-text'}}
                    shortInput={true}
                    onChange={formStateChangeHandler("military_experience_years")}
                    value={formInputs.military_experience_years}
                  />
                </div>
              </>
            }

            <>
              {!!props?.location?.state?.forceCosigner ||
                <div className="merit-details__form-input-section">
                  <div className="merit__form-section-coapplicant merit-details__form-input-label">
                    Applying with a Co-Applicant?
                  </div>
                  <RadioGroup
                    defaultValue="no" onChange={formStateChangeHandler('coapplicantRequested')}>
                    <FormControlLabel classes={{label: 'radioLabel__form'}}
                                      value="yes" control={<Radio/>}
                                      label="Yes"/>
                    <FormControlLabel classes={{label: 'radioLabel__form'}}
                                      value="no" control={<Radio/>}
                                      label="No"/>
                  </RadioGroup>
                </div>
              }
            </>

            {error &&
              <Grid item xs={12}>
                <ErrorMessage type={error.type} messages={error.messages}/>
              </Grid>
            }
            <div
              className={error ? 'merit__submit-button-container--no-margin' : 'merit__submit-button-container--margin'}>
              <Button
                className="merit__submit-button"
                data-testid={"meritInfo-continue-button"}
                color="primary"
                type="submit"
                disabled={loading || invalidYearError}
                variant="contained">
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MeritInformation.propTypes = {
  applicationId: string,
};

export default logCurrentPage(withHeader(backButtonRedirect(MeritInformation)), 'merit_information');

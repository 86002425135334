import React from "react";
import './ErrorPage.scss';
import './DynamicErrorPage.scss';
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import Grid from "@material-ui/core/Grid";
import CallIcon from "@material-ui/icons/Call";
import CommentIcon from "@material-ui/icons/Comment";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const DynamicErrorPage = (props) => {
  const mainMessage = (!!props.history && !!props.history.location && !!props.history.location.state) ? props.history.location.state.mainMessage : null
  const reasons = (!!props.history && !!props.history.location && !!props.history.location.state) ? props.history.location.state.reasons : null
  const imageDesktop = (!!props.history && !!props.history.location && !!props.history.location.state) ? props.history.location.state.imageDesktop : null
  const imageMobile = (!!props.history && !!props.history.location && !!props.history.location.state) ? props.history.location.state.imageMobile : null

  const theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        }
      }
    }
  )

  return (
    <div>

      <MuiThemeProvider theme={theme}>

        <Grid container spacing={0} >
          <Grid item xs={12} sm={6} className="error__dynamic-img__container">
            <img className="error__dynamic-img__desktop" alt="Error" src={imageDesktop}/>
            <img className="error__dynamic-img__mobile" alt="Error" src={imageMobile}/>
          </Grid>
          <Grid item xs={12} sm={6} >

            <div className="dynamic_error__header">
              Uh oh!
            </div>
            <div className="dynamic_error__text--primary">
              { !mainMessage && <>We ran into an error and are working on it.</>}
              { !!mainMessage && <>{mainMessage}</>}
            </div>

            { !!reasons &&
              <div className="dynamic_error__center-section">
                      {reasons.map(function(msg){
                        return <div className="dynamic_error__text--secondary">{msg}</div>;
                       })
                      }
              </div>
            }

            <hr className="dynamic_error__line"></hr>

            <div className="dynamic_error__text--primary">
              If you feel you have received this message in error, please contact customer service.
            </div>
            <Grid container className="dynamic_error__text--secondary">
              <Grid item xs={12}>
                <CallIcon className="error__contact-icon"/>833-637-4848
              </Grid>
              <Grid item xs={12}>
                <CommentIcon className="error__contact-icon"/>help@meritize.com
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </MuiThemeProvider>
    </div>
  );
}

export default logCurrentPage(withHeader(backButtonRedirect(DynamicErrorPage)), 'error');

import axios from 'axios';
import Environment from "util/Environment";

export default class ServerRequests {
  static fetch(relativePath, options) {
    const url = Environment.apiUrl() + '/v1/api' + relativePath;

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    options = {withCredentials: true,...options}

    return axios({
      url,
      headers,
      ...options,
    });
  }

  static submit(url, options) {
    return this.fetch(url, {method: 'post', ...options});
  }

  static fetchStates() {
    return ServerRequests.fetch('/states');
  }

  static fetchDetailedStates() {
    return ServerRequests.fetch('/detailed-states');
  }

  static fetchSchools() {
    return ServerRequests.fetch('/schools');
  }

  static fetchCampusForSchoolId(schoolId) {
    return ServerRequests.fetch('/campuses', {params: {schoolId: schoolId}})
  }

  static fetchProgramsForCampusId(campusId) {
    return ServerRequests.fetch('/programs', {params: {campusId: campusId}})
  }

  static submitHseScores({applicationId, mathematics, socialStudies, science, languageArts, reading, writing, state, testType}) {
    return ServerRequests.submit('/submit-hse-scores',
      {
        data: {applicationId, mathematics, socialStudies, science, languageArts, reading, writing, state, testType}
      }
    );
  }

  static submitMilitaryDetails({applicationId, hasRelevantExperience, numberOfDecorations, highestPayGrade, characterOfService}) {
    return ServerRequests.submit('/submit-military-details',
      {
        data: {applicationId, hasRelevantExperience, numberOfDecorations, highestPayGrade, characterOfService}
      }
    );
  }

  static submitAcademicDetails({applicationId, cumulativeGpa, lowestGrade, totalCreditsEarned, numberOfYears}) {
    return ServerRequests.submit('/submit-academic-details',
      {
        data: {applicationId, cumulativeGpa, lowestGrade, totalCreditsEarned, numberOfYears}
      }
    );
  }

  static submitAcceptDisclosures(applicationId) {
    return ServerRequests.submit('/accept-disclosures',
      {data: {applicationId}}
    );
  }

  static submitTrackingData(data) {
    return ServerRequests.submit('/send-tracking-params', data);
  }

  static fetchPresignedUrl(fileExtension, applicationId) {
    return ServerRequests.fetch('/get-presigned-url',{params: {fileExtension, applicationId}}
    );
  }

  static sendInternalTranscriptNotifyEmail(applicationId) {
    return ServerRequests.fetch('/send-internal-transcript-notify-email',{params: {applicationId}}
    );
  }

  static showTranscriptUploader(applicationId) {
    return ServerRequests.fetch('/show-transcript-uploader',{params: {applicationId}}
    );
  }

  static fetchPrefilledProgramInfo(programCode) {
    return ServerRequests.fetch('/prefilled-program',{params: {programCode}}
    );
  }

  static fetchPrefilledSchoolInfo(schoolSlug) {
    return ServerRequests.fetch('/prefilled-school',{params: {schoolSlug}}
    );
  }

  static checkProgramAndStateLoanAmountInfo(state,programId) {
    return ServerRequests.fetch('/check-state-and-program',{data: {state,programId},method:'post'}
    );
  }

  static submitCurrentPage(applicationId, currentPage) {
    return ServerRequests.submit( '/send-current-page',
      {
          data: {applicationId, currentPage}
      }
    );
  }

  static verifyApplicationFromUUID(uuid) {
    return ServerRequests.fetch('/verify-application',{params: {uuid}}
    );
  }

  static fetchDisclosuresInfo(applicationId){
    return ServerRequests.fetch('/disclosures/info',{params: {applicationId}});
  }

}


import React from "react";
import './ErrorPage.scss';
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import ErrorImg from '../../assets/images/error-image.png';
import Grid from "@material-ui/core/Grid";
import CallIcon from "@material-ui/icons/Call";
import CommentIcon from "@material-ui/icons/Comment";
import withHeader from "components/header/WithHeader";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";

function ErrorPage() {
  return (
    <div>
      <div className="error__header">
        Uh Oh!
      </div>
      <div className="error__text--primary">
        We ran into an error and are working on it.
      </div>

      <div className="error__center-section">
        <div className="error__text--secondary">
          Please try again in a bit.
        </div>
        <img className="error__error-img" alt="Error" src={ErrorImg}/>
      </div>

      <div className="error__text--secondary">
        If the problem persists, please contact Customer Support.
      </div>
      <Grid container className="error__contact-info">
        <Grid item xs={12}>
          <CallIcon className="error__contact-icon"/>833-637-4848
        </Grid>
        <Grid item xs={12}>
          <CommentIcon className="error__contact-icon"/>apply@meritize.com
        </Grid>
      </Grid>

    </div>
  );
}

export default logCurrentPage(withHeader(backButtonRedirect(ErrorPage)), 'error');

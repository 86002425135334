import * as React from 'react';
import './DisclosuresContent.scss';
import Grid from "@material-ui/core/Grid";
import lineSvg from "assets/images/disclosures/disclosures-line.svg";
import {DisclosuresRatesAndFees} from './DisclosuresRatesAndFees'
import ServerRequests from "service/ServerRequests";
import {useEffect} from "react";
import {logSentryError} from "service/errors";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import ErrorMessage from "components/errorMessage/ErrorMessage";

const DisclosuresContent = (props) => {

  const { showError, setShowError,
          loading, setLoading,
          disclosuresInfo, setDisclosuresInfo,
          showDisclosures, isMobile} = props

  const validateResponse = (response) => {

    const fields = [
      "fixedRateMax",
      "fixedRateMin",
      "interestRateMarginMax",
      "interestRateMarginMin",
      "loanExampleFixedFullDeferment",
      "loanExampleFixedFullPrincipal",
      "loanExampleFixedInterestOnly",
      "loanExampleFixedInterestRate",
      "loanExampleVariableFullDeferment",
      "loanExampleVariableFullPrincipal",
      "loanExampleVariableInterestOnly",
      "loanExampleVariableInterestRate",
      "variableRateMax",
      "variableRateMin"
    ]

    let value = response.data
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i]
      if (!response?.data || value[field] == null
        || typeof (value[field]) !== 'number'
        || isNaN(value[field])
        || value[field] <= 0
      ) {
        return false
      }
    }

    if( value["interestRateMarginMax"] < value["interestRateMarginMin"] ){
      return false;
    }

    return true

  }


  useEffect(() => {
    if (disclosuresInfo || !showDisclosures)
      return setLoading(false)

    if (!props.applicationId)
      return setShowError(true);

    ServerRequests.fetchDisclosuresInfo(props.applicationId).then((response) => {
      if (response.status !== 200 || !validateResponse(response) ){
        setLoading(false);
        return setShowError(true)
      }
      setDisclosuresInfo(response.data)

      if (isMobile) {
        const tryAddClass = (elementClass, classToAdd) => {
          const matchingEls = document.getElementsByClassName(elementClass)
          if (matchingEls.length === 0)
            return

          matchingEls[0].classList.add(classToAdd)
        }

        tryAddClass('disclosures__scrollable-window', 'disclosures__scrollable-window-expanded');
        tryAddClass('disclosures-shapeshifter', 'disclosures-play');
      }

      setLoading(false);
    }).catch(error => {
      console.error({error})
      setLoading(false);
      setShowError(true)
      logSentryError(error);
    })
  }, [disclosuresInfo, setDisclosuresInfo, isMobile, props.applicationId, setLoading, setShowError, showDisclosures]);

  const toPercentage = (number) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 12,
    };
    const fixedPrecision = (number * 100).toLocaleString("en-US", options)
    return `${fixedPrecision}%`
  }

  const toMoney = (number) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    const fixedPrecision = (number).toLocaleString("en-US", options)
    return `$${fixedPrecision}`
  }

  const StudentAidLink = () => <>
    <span> </span>
    <a href="https://studentaid.gov/understand-aid/types/loans/interest-rates" rel="noopener noreferrer"
       target="_blank">studentaid.gov/understand-aid/types/loans/interest-rates</a>
  </>

  if (showError) {
    return <ErrorMessage messages={['UNABLE TO GENERATE DISCLOSURES']}/>
  }

  if (loading || !disclosuresInfo) {
    return <LoadingIndicator/>
  }

  return (<div>

    <div className="disclosure-main">

      <DisclosuresRatesAndFees disclosuresInfo={disclosuresInfo}/>

      <div className="disclosures-shapeshifter" style={{backgroundImage: 'url(' + lineSvg + ')'}}></div>
      <hr className="disclosures_desktop_line"></hr>
      {showDisclosures &&
        <>
          <div className="disclosure-main">

            <div className="block variable-loan-interest">
              <div>
                <div>
                  <h3>Your Starting Interest Rate <br/> (upon approval)</h3>
                  <p>The starting interest rate you pay will be determined after you apply. It will be based upon
                    your credit history and other factors (cosigner credit, school type, etc). If approved, we
                    will
                    notify you of the rate you qualify for within the stated range.</p>
                  <h3>Your Interest Rate during the life of the loan</h3>
                  <p className="no-margin"><strong>Your rate is variable.</strong> This means that your rate could
                    move
                    lower or higher than the rates on this form. The variable rate is based upon the 30-day SOFR
                    Rate
                    (as
                    calculated by the
                    New York Federal Reserve). For more information on this rate, see the reference notes. </p>
                  <div id="neverExceedsText" className="block-gray">Although the rate will vary after you are
                    approved,
                    it will <strong className="text-underline">never exceed 29.99%</strong> (the maximum allowable
                    for
                    this loan).
                  </div>
                </div>
              </div>
              <h3>Loan Fees </h3>
              <p><strong>Application Fee:</strong> $0.00 <strong>Origination Fee:</strong> The fees that we charge
                to make this loan range from 4% to 4.5% of the total loan amount. <strong>Loan Guarantee
                  Fee:</strong> 0% of total loan amount. <strong>Repayment Fee:</strong> The fees we charge when
                you
                begin repayment are 0%. </p>
            </div>

            <div data-testid="variable_loan_cost_examples" className="block">
              <h2>Loan Cost Examples</h2>
              <p>The total amount you will pay for this loan will vary depending upon when you start to repay it.
                This example provides estimates based upon three (3) different repayment options available to you
                while enrolled in school.</p>

              <strong>Repayment Options while enrolled in school</strong><br/><br/>
              <strong>1. DEFER PAYMENTS</strong><br/>
              <span>Make no payments while enrolled in school. Interest will be charged and added to your loan</span><br/>

              <table>
                <tbody>
                <tr>
                  <td><strong>Amount Provided</strong> (amount provided directly to you or your school)</td>
                  <td className="disclosures__second-table-column">$10,000</td>
                </tr>
                <tr>
                  <td><strong>Interest Rate</strong> (highest possible starting rate)</td>
                  <td className="disclosures__second-table-column">{toPercentage(disclosuresInfo?.loanExampleVariableInterestRate)}</td>
                </tr>
                <tr>
                  <td><strong>Loan Term</strong> (how long you have to pay off the loan)</td>
                  <td className="disclosures__second-table-column">120 months starting <span
                    className="text-underline">after</span> the deferment period
                  </td>
                </tr>
                <tr className="block-gray">
                  <td><strong>Total Paid over 120 months</strong> (includes associated fees)</td>
                  <td className="disclosures__second-table-column">{toMoney(disclosuresInfo?.loanExampleVariableFullDeferment)}</td>
                </tr>
                </tbody>
              </table>

              <br/><br/>
              <strong>2. PAY ONLY THE INTEREST</strong><br/>
              <span>Make interest payments but defer payments on the principal amount while enrolled in school.</span><br/>

              <table>
                <tbody>
                <tr>
                  <td><strong>Amount Provided</strong> (amount provided directly to you or your school)</td>
                  <td className="disclosures__second-table-column">$10,000</td>
                </tr>
                <tr>
                  <td><strong>Interest Rate</strong> (highest possible starting rate)</td>
                  <td className="disclosures__second-table-column">{toPercentage(disclosuresInfo?.loanExampleVariableInterestRate)}</td>
                </tr>
                <tr>
                  <td><strong>Loan Term</strong> (how long you have to pay off the loan)</td>
                  <td className="disclosures__second-table-column">120 months starting <span
                    className="text-underline">after</span> the deferment period
                  </td>
                </tr>
                <tr className="block-gray">
                  <td><strong>Total Paid over 120 months</strong> (includes associated fees)</td>
                  <td className="disclosures__second-table-column">{toMoney(disclosuresInfo?.loanExampleVariableInterestOnly)}</td>
                </tr>
                </tbody>
              </table>

              <br/><br/>
              <strong>3. MAKE FULL PAYMENTS</strong><br/>
              <span>Pay both the principal and interest amounts while enrolled in school.</span><br/>

              <table>
                <tbody>
                <tr>
                  <td><strong>Amount Provided</strong> (amount provided directly to you or your school)</td>
                  <td className="disclosures__second-table-column">$10,000</td>
                </tr>
                <tr>
                  <td><strong>Interest Rate</strong> (highest possible starting rate)</td>
                  <td className="disclosures__second-table-column">{toPercentage(disclosuresInfo?.loanExampleVariableInterestRate)}</td>
                </tr>
                <tr>
                  <td><strong>Loan Term</strong> (how long you have to pay off the loan)</td>
                  <td className="disclosures__second-table-column">120 months starting after your first payment</td>
                </tr>
                <tr className="block-gray">
                  <td><strong>Total Paid over 120 months</strong> (includes associated fees)</td>
                  <td className="disclosures__second-table-column">{toMoney(disclosuresInfo?.loanExampleVariableFullPrincipal)}</td>
                </tr>
                </tbody>
              </table>

              <h3>About this example</h3>
              <p className="no-margin">The repayment example assumes that you remain in school for 12 months and
                have
                a
                3-month grace period before beginning repayment. It is based on the highest possible starting rate
                currently charged and associated
                fees.
              </p>
            </div>

            <div className="block block-federal-loan">
              <h2>Federal Loan Alternatives</h2>
              <div>
                <div>

                  <table className="table">
                    <thead>
                    <tr>
                      <th><strong>Loan program</strong></th>
                      <th colSpan="2"><strong>Current Interest Rates by Program Type</strong></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                      <td>Perkins Loans</td>
                      <td colSpan="2">5% fixed</td>
                    </tr>
                    <tr>
                      <td>Direct Subsidized Loans</td>
                      <td>6.53% fixed</td>
                      <td>Undergraduate</td>
                    </tr>
                    <tr>
                      <td>Direct Unsubsidized Loans</td>
                      <td>6.53% fixed</td>
                      <td>Undergraduate</td>
                    </tr>
                    <tr>
                      <td>Direct Unsubsidized Loans</td>
                      <td>8.08% fixed</td>
                      <td>Graduate or Professional</td>
                    </tr>
                    <tr>
                      <td>Direct PLUS Loans</td>
                      <td>9.08% fixed</td>
                      <td>Parents and Graduate or Professional Students</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
                <div className="col-sm-4">
                  <div className="federal-loan-text">
                    <strong>You may qualify for Federal education loans.</strong>
                    <p>For additional information, <strong>contact your school’s financial aid office or the
                      Department of Education at:</strong></p>
                    <StudentAidLink/>
                  </div>
                </div>
              </div>
            </div>

            <div className="block block-steps">
              <h2>Next Steps</h2>
              <ul className="steps-list">
                <li>
                  <strong>Find Out About Other Loan Options.</strong>
                  <p>Some schools have school-specific student loan benefits and terms not detailed on this form.
                    Contact your school’s financial aid office or visit the Department of Education’s web site at:
                    <StudentAidLink/> for more information about other loans.</p>
                </li>
                <li>
                  <strong>To Apply for this Loan, Complete the Application and the Self-Certification
                    Form.</strong>
                  <p>You may get the certification form from your school’s financial aid office. If you are
                    approved
                    for this loan, the loan terms will be available for 30 days (terms will not change during this
                    period, except as permitted by law, and the variable interest rate may change based on the
                    market). </p>
                </li>
              </ul>
            </div>

            <div className="disclosure-footer">
              <h3>REFERENCE NOTES</h3>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Variable Interest Rate </strong>
                  <ul className="footer-list">
                    <li data-testid="variable-interest-rate-footer-1">This loan has a variable interest rate that is
                      based on a publicly available index, the
                      Secured Overnight Financing Rate (SOFR). Your rate will be calculated each quarter by adding a
                      margin
                      between {toPercentage(disclosuresInfo?.interestRateMarginMin)} - {toPercentage(disclosuresInfo?.interestRateMarginMax)} to
                      the 30-day SOFR,
                      rounded up to the nearest 1/8th (0.125%) of a
                      percent.

                    </li>
                    <li>The rate will not increase more than once a month, but there is no limit on the amount
                      that
                      the rate could increase at one time.
                    </li>
                  </ul>
                  <strong>Prepayments </strong>
                  <ul className="footer-list">
                    <li>If you pay the loan off early you will not have to pay a penalty. You will not be entitled
                      to a refund of part of the finance charge.
                    </li>
                  </ul>
                  <strong>Eligibility Criteria </strong>
                  <strong>Borrower </strong>
                  <ul className="footer-list">
                    <li>Must be enrolled at an eligible school at least half-time.</li>
                    <li>Must be age of consent at the time you apply.</li>
                    <li>Must be a U.S. citizen or permanent resident.</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <strong>Cosigners</strong>
                  <ul className="footer-list">
                    <li>Rates are typically higher without a cosigner.</li>
                    <li>Must be age of consent at the time of loan application.</li>
                    <li>Must be a U.S. citizen or permanent resident.</li>
                  </ul>
                  <strong>Bankruptcy Limitations</strong>
                  <ul className="footer-list">
                    <li>If you file for bankruptcy you may still be required to pay back this loan.</li>
                  </ul>
                  <p><strong>See your loan agreement or application for more information about eligibility,
                    prepayment, nonpayment, default, and any required payment in full before the scheduled
                    date. </strong></p>
                </div>
              </div>
            </div>
          </div>
          <hr/>

          <div className="disclosure-main">
            <div className="disclosure-address">
              <strong>Meritize Lending, LLC*,</strong>
              <div>5805 Main Street, Suite 220<br/>Frisco, Texas 75034</div>
            </div>

            <br/>
            <div className="block fixed-loan-interest">
              <h2 className="rate-type-title">Loan Fixed Interest Rate &amp; Fees </h2>
              <div>
                <div className="interest-box ">
                  <p>Your <strong>starting interest rate</strong> will be between</p>
                  <Grid container spacing={0} id="fixedInterestRates" data-testid="fixed-interest-rate-range"
                        className="interest-mid">
                    <Grid item xs={5}><span>{toPercentage(disclosuresInfo?.fixedRateMin)}</span></Grid>
                    <Grid item xs={2}><p>AND</p></Grid>
                    <Grid item xs={5}><span>{toPercentage(disclosuresInfo?.fixedRateMax)}</span></Grid>
                  </Grid>
                </div>

                <div>
                  <h3>Your Starting Interest Rate (upon approval)</h3>
                  <p>The starting interest rate you pay will be determined after you apply. It will be based
                    upon
                    your credit history and other factors (cosigner credit, school type, etc). If approved, we
                    will
                    notify you of the rate you qualify for within the stated range.</p>
                  <h3>Your Interest Rate during the life of the loan</h3>
                  <p className="no-margin"><strong>Your rate is fixed.</strong> This means that your rate will
                    never
                    change during the life of your loan. For more information on this rate, see the reference
                    notes.
                  </p>
                </div>

              </div>
              <h3>Loan Fees </h3>
              <p><strong>Application Fee:</strong> $0.00 <strong>Origination Fee:</strong> The fees that we
                charge
                to make this loan range from 4% to 4.5% of the total loan amount. <strong>Loan Guarantee
                  Fee:</strong> 0% of total loan amount. <strong>Repayment Fee:</strong> The fees we charge when
                you
                begin repayment are 0%. </p>
            </div>

            <div data-testid="fixed_loan_cost_examples" className="block">
              <h2>Loan Cost Examples</h2>
              <p>The total amount you will pay for this loan will vary depending upon when you start to repay
                it.
                This example provides estimates based upon three (3) different repayment options available to
                you
                while enrolled in school.</p>

              <strong>Repayment Options while enrolled in school</strong><br/><br/>
              <strong>1. DEFER PAYMENTS</strong><br/>
              <span>Make no payments while enrolled in school. Interest will be charged and added to your loan</span><br/>

              <table>
                <tbody>
                <tr>
                  <td><strong>Amount Provided</strong> (amount provided directly to you or your school)</td>
                  <td className="disclosures__second-table-column">$10,000</td>
                </tr>
                <tr>
                  <td><strong>Interest Rate</strong> (highest possible starting rate)</td>
                  <td className="disclosures__second-table-column">{toPercentage(disclosuresInfo?.loanExampleFixedInterestRate)}</td>
                </tr>
                <tr>
                  <td><strong>Loan Term</strong> (how long you have to pay off the loan)</td>
                  <td className="disclosures__second-table-column">120 months starting <span
                    className="text-underline">after</span> the deferment period
                  </td>
                </tr>
                <tr className="block-gray">
                  <td><strong>Total Paid over 120 months</strong> (includes associated fees)</td>
                  <td className="disclosures__second-table-column">{toMoney(disclosuresInfo?.loanExampleFixedFullDeferment)}</td>
                </tr>
                </tbody>
              </table>

              <br/><br/>
              <strong>2. PAY ONLY THE INTEREST</strong><br/>
              <span>Make interest payments but defer payments on the principal amount while enrolled in school.</span><br/>

              <table>
                <tbody>
                <tr>
                  <td><strong>Amount Provided</strong> (amount provided directly to you or your school)</td>
                  <td className="disclosures__second-table-column">$10,000</td>
                </tr>
                <tr>
                  <td><strong>Interest Rate</strong> (highest possible starting rate)</td>
                  <td className="disclosures__second-table-column">{toPercentage(disclosuresInfo?.loanExampleFixedInterestRate)}</td>
                </tr>
                <tr>
                  <td><strong>Loan Term</strong> (how long you have to pay off the loan)</td>
                  <td className="disclosures__second-table-column">120 months starting <span
                    className="text-underline">after</span> the deferment period
                  </td>
                </tr>
                <tr className="block-gray">
                  <td><strong>Total Paid over 120 months</strong> (includes associated fees)</td>
                  <td className="disclosures__second-table-column">{toMoney(disclosuresInfo?.loanExampleFixedInterestOnly)}</td>
                </tr>
                </tbody>
              </table>

              <br/><br/>
              <strong>3. MAKE FULL PAYMENTS</strong><br/>
              <span>Pay both the principal and interest amounts while enrolled in school.</span><br/>

              <table>
                <tbody>
                <tr>
                  <td><strong>Amount Provided</strong> (amount provided directly to you or your school)</td>
                  <td className="disclosures__second-table-column">$10,000</td>
                </tr>
                <tr>
                  <td><strong>Interest Rate</strong> (highest possible starting rate)</td>
                  <td className="disclosures__second-table-column">{toPercentage(disclosuresInfo?.loanExampleFixedInterestRate)}</td>
                </tr>
                <tr>
                  <td><strong>Loan Term</strong> (how long you have to pay off the loan)</td>
                  <td className="disclosures__second-table-column">120 months starting after your first payment</td>
                </tr>
                <tr className="block-gray">
                  <td><strong>Total Paid over 120 months</strong> (includes associated fees)</td>
                  <td className="disclosures__second-table-column">{toMoney(disclosuresInfo?.loanExampleFixedFullPrincipal)}</td>
                </tr>
                </tbody>
              </table>

              <h3>About this example</h3>
              <p className="no-margin">The repayment example assumes that you remain in school for 12 months and
                have a
                3-month grace period before beginning repayment. It is based on the highest possible starting
                rate
                currently charged and associated
                fees.
              </p>
            </div>

            <div className="page_break"/>

            <div className="block block-federal-loan">
              <h2>Federal Loan Alternatives</h2>
              <div className="row">
                <div className="col-sm-8">

                  <div className="table-responsive">
                    <table className="table">

                      <thead>
                      <tr>
                        <th><strong>Loan program</strong></th>
                        <th colSpan="2"><strong>Current Interest Rates by Program Type</strong></th>
                      </tr>
                      </thead>


                      <tbody>
                      <tr>
                        <td>
                          Perkins Loans
                        </td>
                        <td colSpan="2">
                          5% fixed
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Direct Subsidized Loans
                        </td>
                        <td>
                          6.53% fixed
                        </td>
                        <td>
                          Undergraduate
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Direct Unsubsidized Loans
                        </td>
                        <td>
                          6.53% fixed
                        </td>
                        <td>
                          Undergraduate
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Direct Unsubsidized Loans
                        </td>
                        <td>
                          8.08% fixed
                        </td>
                        <td>
                          Graduate or Professional
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Direct PLUS Loans
                        </td>
                        <td>
                          9.08% fixed
                        </td>
                        <td>
                          Parents and Graduate or Professional Students
                        </td>
                      </tr>
                      </tbody>

                    </table>
                  </div>

                </div>
                <div className="col-sm-4">
                  <div className="federal-loan-text">
                    <strong>You may qualify for Federal education loans.</strong>
                    <p>For additional information, <strong>contact your school’s financial aid office or the
                      Department of Education at:</strong></p>
                    <StudentAidLink/>
                  </div>
                </div>
              </div>
            </div>


            <div className="block block-steps">
              <h2>Next Steps</h2>
              <ul className="steps-list">
                <li>
                  <strong>Find Out About Other Loan Options.</strong>
                  <p>Some schools have school-specific student loan benefits and terms not detailed on this
                    form.
                    Contact your school’s financial aid office or visit the Department of Education’s web site
                    at:
                    <StudentAidLink/> for more information about other loans.</p>
                </li>
                <li>
                  <strong>To Apply for this Loan, Complete the Application and the Self-Certification
                    Form.</strong>
                  <p>You may get the certification form from your school’s financial aid office. If you are
                    approved
                    for this loan, the loan terms will be available for 30 days (terms will not change during
                    this
                    period, except as permitted by law). </p>
                </li>
              </ul>
            </div>


            <div className="disclosure-footer">
              <h3>REFERENCE NOTES</h3>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Fixed Interest Rate </strong>
                  <ul className="footer-list">
                    <li>This loan has a fixed interest rate. This means your interest rate will be set upon
                      approval
                      and will not increase during the life of your loan.
                    </li>
                  </ul>
                  <strong>Prepayments </strong>
                  <ul className="footer-list">
                    <li>If you pay the loan off early you will not have to pay a penalty. You will not be
                      entitled
                      to a refund of part of the finance charge.
                    </li>
                  </ul>
                  <strong>Eligibility Criteria </strong>
                  <strong>Borrower </strong>
                  <ul className="footer-list">
                    <li>Must be enrolled at an eligible school at least half-time.</li>
                    <li>Must be age of consent at the time you apply.</li>
                    <li>Must be a U.S. citizen or permanent resident.</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <strong>Cosigners</strong>
                  <ul className="footer-list">
                    <li>Rates are typically higher without a cosigner.</li>
                    <li>Must be age of consent at the time of loan application.</li>
                    <li>Must be a U.S. citizen or permanent resident.</li>
                  </ul>
                  <strong>Bankruptcy Limitations</strong>
                  <ul className="footer-list">
                    <li>If you file for bankruptcy you may still be required to pay back this loan.</li>
                  </ul>
                  <p><strong>See your loan agreement or application for more information about eligibility,
                    prepayment, nonpayment, default, and any required payment in full before the scheduled
                    date. </strong></p>
                  <p>
                    *For residents of SC, loan is made by Meritize Financial, Inc.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="disclosure-help-contact">
            If you need help, contact us at<br/>833-637-4848
          </div>

          <div className="disclosures-content__hard-pull-text">
            When selecting “I AGREE” AND CLICKING SUBMIT, we will request your credit report
            and your co-applicant’s credit report, if applicable, from a credit reporting agency.
            Please note that this will create a hard credit inquiry on your credit report, and on
            the report of your co-applicant, if applicable. Disclosures contain rate, fee, and other
            cost information, as applicable.
          </div>
          <hr className="disclosures_mobile_line"></hr>
        </>
      }
    </div>
  </div>
  );
};

export default DisclosuresContent;

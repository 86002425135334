import React, {useState} from 'react';
import './military.scss';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {logSentryError, parseErrors} from "service/errors";
import ServerRequests from "service/ServerRequests";
import CalTextField from "components/CalTextField/CalTextField";
import CalSelect from "components/CalSelect/CalSelect";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import logCurrentPage from "components/hoc/LogCurrentPage/LogCurrentPage";
import CalRadioSelection from "components/CalRadioGroup/CalRadioSelection";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withStyles} from "@material-ui/core";

const DisclosuresCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#3C89E8',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

let MilitaryForm = props => {
  let {handleCompletion, setLoading, loading, applicationId} = props;

  const militaryPayGrades = [
    'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7', 'E8', 'E9',
    'W1', 'W2', 'W3', 'W4', 'W5',
    'O1', 'O2', 'O3', 'O4', 'O5', 'O6', 'O7', 'O8', 'O9', 'O10'
  ];

  const charactersOfService = [
    'Honorable', 'General', 'Other', 'Bad Conduct Discharge', 'Dishonorable Discharge', 'Entry Level Discharge'
  ];

  const errorStrings = {
    'has-relevant-experience-required': 'Select an answer for relevant experience',
    'highest-pay-grade-required': 'Select a pay grade from the dropdown',
    'character-of-service-required': 'Select a character of service from the dropdown'
  };
  const [error, handleError] = useState(null);
  const [disclosuresIsChecked, handleDisclosuresChanged] = useState(false);
  const [formInputs, handleFormInputs] = useState({
    applicationId: applicationId,
    hasRelevantExperience: null,
    numberOfDecorations: '',
    highestPayGrade: '',
    characterOfService: ''
  });

  const handleSubmit = (event) => {
    const {hasRelevantExperience, highestPayGrade, numberOfDecorations, characterOfService} = formInputs;
    setLoading(true);
    ServerRequests.submitMilitaryDetails({
      applicationId,
      hasRelevantExperience: hasRelevantExperience != null ? (hasRelevantExperience.toLowerCase() === 'yes') : null,
      highestPayGrade: highestPayGrade === '' ? null : highestPayGrade,
      numberOfDecorations: Number.parseInt(numberOfDecorations || 0, 10),
      characterOfService: characterOfService === '' ? null : characterOfService,
    })
      .then((response) => {
        handleCompletion(event);
      }).catch(error => {
      setLoading(false)
      if (error.response === undefined || error.response.status !== 422) {
        logSentryError(error)
        handleError({type: "server"});
      } else {
        handleError({type: "user", messages: parseErrors(error.response.data.errors, errorStrings)})
      }
    });
    event.preventDefault();
  };

  const formChangeHandler = (fieldName) => {
    return (e) => {
      handleFormInputs({
        ...formInputs,
        [fieldName]: sanitizeValue(e.target.value, fieldName),
      })
    }
  };

  const radioChangeHandler = (fieldName) => {
    return (e) => {
      handleFormInputs({
        ...formInputs,
        [fieldName]: sanitizeValue(e, fieldName),
      })
    }
  };

  const sanitizeValue = (value, fieldName) => {
    if (fieldName === 'numberOfDecorations') {
      let isnum = /^\d*$/.test(value);
      return isnum ? value : formInputs.numberOfDecorations;
    }
    return value;
  };

  return (
    <>
      <div className="academic__form-container">
        <form onSubmit={handleSubmit}>

          <div className="merit-details__form-input-section">
            <CalSelect
              labelClassName="merit-details__form-input-label"
              textboxClassName="merit-details__form-input-textbox"
              helpertextClassName="merit-details__form-input-helpertext"
              id="character-of-service-selector"
              bigLabel="Character of Service"
              label="Select Character"
              inputProps={{'data-testid': 'character-of-service', className: 'academic__input-text'}}
              FormHelperTextProps={{className: 'academic__input-text'}}
              // shortInput={true}
              onChange={formChangeHandler('characterOfService')}
              value={formInputs.characterOfService}
              selectOptions={charactersOfService}
            />
          </div>

          <div className="merit-details__form-input-section">
            <CalTextField
              labelClassName="merit-details__form-input-label"
              textboxClassName="merit-details__form-input-textbox"
              helpertextClassName="merit-details__form-input-helpertext"
              id="total-decorations-field"
              bigLabel="Total Decorations"
              label="Enter Number"
              inputProps={{'data-testid': 'number-of-decorations', className: 'academic__input-text'}}
              FormHelperTextProps={{className: 'academic__input-text'}}
              // shortInput={true}
              onChange={formChangeHandler('numberOfDecorations')}
              value={formInputs.numberOfDecorations}
              helperText='Leave blank if 0'
            />
          </div>


          <div className="merit-details__form-input-section">
            <CalSelect
              labelClassName="merit-details__form-input-label"
              textboxClassName="merit-details__form-input-textbox"
              helpertextClassName="merit-details__form-input-helpertext"
              id="highest-pay-grade-selector"
              bigLabel="Highest Pay Grade"
              label="Select Grade"
              inputProps={{'data-testid': 'number-of-decorations', className: 'academic__input-text'}}
              FormHelperTextProps={{className: 'academic__input-text'}}
              // shortInput={true}
              onChange={formChangeHandler('highestPayGrade')}
              value={formInputs.highestPayGrade}
              selectOptions={militaryPayGrades}
            />
          </div>

          <div className="merit-details__form-input-section">
            <CalRadioSelection
              labelClassName="merit-details__form-input-label"
              textboxClassName="merit-details__form-input-textbox"
              data-testid="lowest-grade-field"
              id='lowest-grade-field'
              bigLabel="Is your specialty relevant to the education you are seeking?"
              onNewValueSelected={radioChangeHandler('hasRelevantExperience')}
              value={formInputs.hasRelevantExperience}
              values={['Yes', 'No']}
            />
          </div>

          <div className="merit-details__form-footer">

            <div className="merit-details__transcript-msg">
              {
                props.children
              }
            </div>

            <Grid item xs={12}>
              {(error) &&
                <ErrorMessage type={error.type} messages={error.messages}/>
              }

              <FormControlLabel
                className='academic__disclosures-container'
                control={
                  <DisclosuresCheckbox
                    className='academic__disclosures-checkbox'
                    color="primary"
                    name="checkedB"
                    onChange={(event) => handleDisclosuresChanged(event.target.checked)}
                    checked={disclosuresIsChecked}
                    style={{
                      transform: "scale(1.5) translateY(7px)",
                      paddingInline: "1rem 1.5rem",
                    }}
                  />
                }
                label={
                  <div className='academic__disclosures-label'>
                    *By checking this box, I authorize Meritize to use my merit documentation records in my loan
                    decision.
                  </div>
                }
              />

              <div className="merit-details__submit-button-container">
                <Button
                  className="merit-details__submit-button"
                  color="primary"
                  type="submit"
                  variant="contained"
                  data-testid={"continue-button"}
                  disabled={!disclosuresIsChecked || loading}>
                  Continue
                </Button>
              </div>
            </Grid>
          </div>
        </form>
      </div>
    </>
  );
};

export default logCurrentPage(MilitaryForm, 'merit_details_military');

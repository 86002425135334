import TranscriptUploader from "components/transcriptUploader/TranscriptUploader";
import React from "react";

const MeritDetailsTranscriptUploader = props => {
  const applicationId = props.applicationId;
  const setLoading = props.setLoading;

  return (
    <div className="merit-details__form-input-section">
        <label className="merit-details__form-input-label"
               style={{display: 'inline-flex'}} >
          {props.text}
        </label>
        <TranscriptUploader applicationId={applicationId} setLoading={setLoading}/>
        <div className="merit-details__upload-sub-text">
          {props.subText}
        </div>
    </div>
  )
}

export default MeritDetailsTranscriptUploader;

import React, {useEffect, useState} from 'react';

import './MeritDetails.scss';
import {bool, string} from "prop-types";
import HseForm from "./hse/hse";
import MilitaryForm from "./military/military";
import ServerRequests from "../../service/ServerRequests";
import AcademicForm from "components/meritDetails/academic/academic";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import {logSentryError, parseErrors, hasErrorCode} from "service/errors";
import MeritDetailsTranscriptUploader from "components/transcriptUploader/MeritDetailsTranscriptUploader";
import withHeader from "components/header/WithHeader";
import Infographic from "components/header/infographic/Infographic";
import ErrorPopupReapply from "components/errorMessage/ErrorPopupReapply";

const MeritDetails = props => {
  const [loading, setLoading] = useState(false);
  const [showTranscriptUpload, handleShowTranscriptUpload] = useState(false);
  const [error, setError] = useState(null);
  let {applicationPath} = props.location.state;
  const applicationPathTitle = applicationPath === "Military"
    ? "Military Experience"
    : "Academic Details"

  const routeToCoapplicantPage = (useConditionalCoapplicant = false) => {
    props.history.push('/coapplicant', {
      applicationId: props.location.state.applicationId,
      conditionalCoapplicant: useConditionalCoapplicant,
      hasOffers: false,
    });
  }

  const errorStrings = {
    'end-of-road-status-withdrawn': 'Your application has been withdrawn.',
    'end-of-road-status-error': 'Your application has encountered an error.',
    'end-of-road-status-expired': 'Your application has expired.'
  };

  let handleCompletion = () => {
    if (props.location.state.addCoapplicant) {
      routeToCoapplicantPage();
    } else {
      let data = {
        applicationId: props.location.state.applicationId,
      };
      ServerRequests.submit('/send-merit-details', {data})
        .then(response => {
          let {status, needsManualEvaluation, applicationId, has18MonthNoInterest} = response.data;
          if (status === 'Pending') {
            routeToCoapplicantPage(true);
          } else if (status === 'Declined') {
            props.history.push('/declined', {applicationId: applicationId, isTempDeclined: true})
          } else if (status === 'Offered') {
            if (!needsManualEvaluation) {
              props.history.push('/offers', {
                applicationId: applicationId,
                offers: response.data.offers,
                applicationCreatedDate: response.data.applicationCreatedDate,
                has18MonthNoInterest: has18MonthNoInterest,
                hasMultipleDisbursements: response.data.hasMultipleDisbursements,
                hasCoapplicant: false,
                isCpl: response.data.isCpl,
              });
            } else {
              props.history.push('/full-app', {applicationId: applicationId});
            }
          } else {
            logSentryError(new Error(`Unknown status: ${status}`));
            setLoading(false); //todo check what to do here
          }
        })
        .catch(error => {
          if (error.response === undefined) {
            logSentryError(error);
            setLoading(false);
          } else if (error.response.status === 403) {
            setLoading(false);
            if (hasErrorCode(error.response.data.errors, 'end-of-road-status-declined')) {
              props.history.push('/declined', {applicationId: error.response.data.applicationId, isTempDeclined: true})
            } else if (hasErrorCode(error.response.data.errors, 'end-of-road-status-ineligible')) {
              props.history.push('/ineligible', {applicationId: error.response.data.applicationId});
            } else {
              setError({type: 'user', messages: parseErrors(error.response.data.errors, errorStrings)});
            }
          } else if (error.response.status === 424) {
            logSentryError(error)
            props.history.push('/errorPage', {applicationId: props.location.state.applicationId})
          } else {
            logSentryError(error);
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    ServerRequests.showTranscriptUploader(props.location.state.applicationId).then((response) => {
      handleShowTranscriptUpload(response.data);
    }).catch(error => {
      logSentryError(error);
    });
  }, [props.location.state.applicationId]);

  const getMeritDetailsForm = props => {
    switch (applicationPath) {
      case "Academic":
        return <AcademicForm handleCompletion={handleCompletion}
                             setLoading={setLoading} loading={loading}
                             applicationId={props.location.state.applicationId}
                             isHighSchool={props.location.state.isHighSchool}
                             showTranscriptUpload={showTranscriptUpload}>
          {showTranscriptUpload &&
            <MeritDetailsTranscriptUploader applicationId={props.location.state.applicationId}
                                            setLoading={setLoading}
                                            text={"Upload Transcript"}
                                            subText={"You will also have an opportunity to upload a transcript after completing your application"}
            />
          }
        </AcademicForm>;
      case "HSE":
        return <HseForm handleCompletion={handleCompletion}
                        setLoading={setLoading} loading={loading}
                        applicationId={props.location.state.applicationId}
                        graduationYear={props.location.state.graduationYear}>
          {showTranscriptUpload &&
            <MeritDetailsTranscriptUploader applicationId={props.location.state.applicationId}
                                            setLoading={setLoading}
                                            text={"Upload Transcript"}
                                            subText={"You will also have an opportunity to upload a transcript after completing your application"}
            />
          }
        </HseForm>;
      case "Military":
        return <MilitaryForm handleCompletion={handleCompletion}
                             setLoading={setLoading} loading={loading}
                             applicationId={props.location.state.applicationId}>
          {showTranscriptUpload &&
            <MeritDetailsTranscriptUploader applicationId={props.location.state.applicationId}
                                            setLoading={setLoading}
                                            text={"Upload DD214"}
                                            subText={"You will also have an opportunity to upload a DD214 after completing your application"}
            />
          }
        </MilitaryForm>;
      case "None":
      default:
        return <AcademicForm handleCompletion={handleCompletion} loading={loading}
                             applicationId={props.location.state.applicationId}
                             isHighSchool/>; //TODO: 9/4/2020 this never happens, but need to be explicit about it.
    }
  }

  return (
    <div className="merit-details-ie11fix">
      <Infographic stage="2" title={applicationPathTitle}/>

      {loading && <LoadingIndicator/>}
      {getMeritDetailsForm(props)}
      {(error) && <ErrorPopupReapply error={error} setError={setError} {...props} />}
    </div>
  )
};

MeritDetails.propTypes = {
  applicationId: string,
  applicationPath: string,
  addCoapplicant: bool,
};

export default withHeader(backButtonRedirect(MeritDetails));

import React from "react";
import "components/errorMessage/ErrorMessage.scss";

const FormError = (props) => {
  const {messages} = props;

  return (
    <div className={`error-message error-message--user`} data-testid="error-msg-container">
      {messages.map((obj, index) => {
        return <div data-testid="error-msg" key={index}>{obj}</div>
      })
      }
    </div>
  );
};

export default FormError;

import React, {useEffect, useState} from 'react';
import './sendTranscript.scss';
import withHeader from "components/header/WithHeader";
import {backButtonRedirect} from "components/hoc/BackButtonRedirect/BackButtonRedirect";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import ServerRequests from "service/ServerRequests";
import {logSentryError} from "service/errors";
import {useHistory, useLocation} from "react-router-dom";
import brokenLinkDesktopSvg from "assets/images/errors/link-broken.svg";
import brokenLinkMobileSvg from "assets/images/errors/Link-broken-mobile.svg";
import TranscriptStandaloneUploader from "components/SendTranscript/TranscriptStandaloneUploader";

const SendTranscript = (props) => {

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [applicationId, setApplicationId] = useState(null);

  useEffect(() => {
    let query = new URLSearchParams(location.search);
    let uuid = query.get("uuid");
    if (!!uuid) {
      ServerRequests.verifyApplicationFromUUID(uuid)
        .then((res) => {
          setApplicationId(res.data.applicationId);
          setLoading(false)
        })
        .catch(error => {
          if(!!error.response.data.errors && error.response.data.errors.length > 0) {
            history.push('/dynamicErrorPage', {
              applicationId: null,
              mainMessage: 'You are seeing this page because you are unable to submit a transcript for one of the following reasons:',
              reasons: ['A. Incorrect Link', 'B. Your application no longer requires a transcript.'],
              imageDesktop: brokenLinkDesktopSvg,
              imageMobile: brokenLinkMobileSvg
            });
          } else {
            logSentryError(error);
          }
        });
    } else {
      logSentryError(new Error("No UUID provided for /SendTranscript"))
      history.push('/errorPage',{applicationId: null} );
    }
  }, [history, location]);

  return (
    <>
      {loading && <LoadingIndicator/>}
      {!!applicationId &&
        <TranscriptStandaloneUploader applicationId={applicationId} setLoading={setLoading} />
      }
    </>
  );

};

export default withHeader(backButtonRedirect(SendTranscript), { marginlessHeader: "all", marginlessPageWrapper: "mobile" });



import JSZip from 'jszip';
import {DropzoneArea} from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import * as React from "react";
import {useState} from "react";
import ServerRequests from "service/ServerRequests";
import axios from "axios";
import './TranscriptUploader.scss';
import TranscriptUploaderInstructions from "components/transcriptUploader/TranscriptUploaderInstructions";
import {logSentryError} from "service/errors";

const TranscriptUploader = props => {
  let setLoading = props.setLoading;
  let applicationId = props.applicationId;
  let loading = props.loading;
  const [files, setFiles] = useState([]);
  const [transcriptUploadedStatus, setTranscriptUploadedStatus] = useState("pending");

  function zipFiles(files){
    const zip = new JSZip();
    for(let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i])
    }
    return zip.generateAsync({ type: 'blob' })
  }

  async function uploadFile(event) {
    event.preventDefault();
    setLoading(true);

    let fileExtension = '';
    let oneFile = files.length === 1;
    const fileProm = oneFile
      ? Promise.resolve(files[0])
      : zipFiles(files);

    if (oneFile) {
      let splitFileName = files[0].name.split(".");
      fileExtension = splitFileName[splitFileName.length-1]
    } else {
      fileExtension = 'zip';
    }

    const presignedUrlProm = ServerRequests.fetchPresignedUrl(fileExtension, applicationId)

    const presignedUrlRes = await presignedUrlProm
    const file = await fileProm

    let preSignedUrl = presignedUrlRes.data.url;
    return axios.put(preSignedUrl,
        file,
        {
          headers: {
            'Content-Type': file.type,
          }
        }
      )
      .then((response) => {
        setTranscriptUploadedStatus("success");
        ServerRequests.sendInternalTranscriptNotifyEmail(applicationId);
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setTranscriptUploadedStatus("error");
        logSentryError(error);
      });
  }

  const onFilesDropped = (selectedFiles) => {
    setFiles([...files, ...selectedFiles]);
  }

  const onFileDeleted = (deletedFile) => {
    setFiles(
      files.filter(function(file, index, arr){
        return file.name !== deletedFile.name;
      })
    );
  }

  const onDropRejectMessage = (rejectedFiles, acceptedFiles, maxFileSize) =>
  {
    let message='';
    if (!acceptedFiles.includes(rejectedFiles.type)) {
      message = 'File type not supported. Supported files types: PNG, PDF, JPEG.\n';
    }
    if (maxFileSize < rejectedFiles.size){
      message += 'File too large. File size cannot exceed 40 MB.';
    }
    return message;
  }

  return (
    <div className="transcript-uploader__upload-section">
      {
        transcriptUploadedStatus === "pending" ?
          <>
            <div className="transcript-uploader__dropzone">
              <DropzoneArea
                acceptedFiles={['application/pdf', 'image/png', 'image/jpeg']}
                inputProps={{
                  'data-testid': "transcript-uploader__dropzone-input"
                }}
                Icon={TranscriptUploaderInstructions}
                classes={{root: 'transcript-uploader__root',textContainer: 'transcript-uploader__textContainer'}}
                showPreviews={true}
                showAlerts={['error']}
                showPreviewsInDropzone={false}
                getDropRejectMessage={onDropRejectMessage}
                useChipsForPreview
                previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                previewText=""
                filesLimit={77}
                maxFileSize={40 * 1024 * 1024}
                onDrop={onFilesDropped}
                onDelete={onFileDeleted}
                dropzoneText=""
              >
              </DropzoneArea>
            </div>
          </> :
          transcriptUploadedStatus === "success" ?
            "Document uploaded successfully!"
              :
            "Error uploading transcript. Please try again later."
      }
      {
        (files && files.length > 0 && transcriptUploadedStatus === "pending") &&
        <div className='transcript-uploader__upload-button-container'>
          <Button
            className='transcript-uploader__upload-button'
            color="primary"
            data-testid={"transcript-uploader-upload-button"}
            disabled={loading}
            type="submit"
            onClick={uploadFile}
            variant="contained">
            Upload Files
          </Button>
        </div>
      }
    </div>
  );
}

export default TranscriptUploader;
